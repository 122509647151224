<template>
  <Transition name="fade">
    <div class="modal" v-if="visibility">
      <div class="modal__blackout" @click="setVisibility(false)"></div>
      <div class="modal__wrapper" v-bind="$attrs">
        <div class="modal__header" v-if="true">
          <h1 class="modal__heading">
            {{ heading }}
          </h1>
          <button class="modal__close" @click="setVisibility(false)" v-show="closable">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#plus-translucent'}" />
            </svg>
          </button>
        </div>
        <slot />
        <div class="modal__actions">
          <RedButtonVue class="modal__action" @click="actionHandler" v-show="actionText" :disabled="loading" >
            {{actionText}}
            <svg v-show="loading">
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
            </svg>
          </RedButtonVue>
          <GrayButtonVue class="modal__goBack" @click="goBackHandler" v-show="goBackText">{{goBackText}}</GrayButtonVue>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import GrayButtonVue from './GrayButton.vue';
import RedButtonVue from './RedButton.vue';

export default {
  emits: ['switch'],
  inheritAttrs: false,
  components: {
    RedButtonVue,
    GrayButtonVue,
  },
  props: {
    visibility: Boolean,
    heading: String,
    loading: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    actionText: String,
    goBackText: String,
  },
  methods: {
    setVisibility (value) {
      if (this.closable) {
        this.$emit('switch', value);
      }
    },

    actionHandler () {
      this.$emit('actionClick');
    },

    goBackHandler () {
      this.setVisibility(false);
      this.$emit('goBackClick');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.fade-enter-active{
  transition: all .1s ease-in-out;
  .modal__wrapper {
    transition: all .1s ease-in-out;
  }
}
.fade-leave-active{
  transition: all .3s ease-in-out;

  .modal__wrapper {
    transition: all .3s ease-in-out;
  }
}

.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  .modal__wrapper {
    transition: all .3s ease-in-out;
    transform: scale(.6);
  }
}

.modal {
  z-index: 100;
  padding: 16px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__blackout {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($color: black, $alpha: .3);
    backdrop-filter: blur(2px);
  }

  &__wrapper {
    z-index: 101;
    cursor: initial;
    @include section;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__close {
    cursor: pointer;
    background: none;
    border: none;
    width: 18px;
    height: 18px;

    svg {
      fill: $red;
      width: inherit;
      height: inherit;
      transform: rotate(45deg);
    }
  }

  &__actions{
    margin-top: 10px;
    display: flex;

    button {
      font-size: 15px;
      display: flex;
      align-items: center;
    }
  }

  &__action{
    margin-right: 10px;

    svg{
      width: 13px;
      height: 13px;
      margin-left: 10px;
      fill: $dark-gray;
      animation: rotateAnimation .6s linear infinite;
    }
  }
}

@media (max-width: 768px) {
  .modal__wrapper {
    position: fixed;
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg); /* Начальное положение */
  }
  to {
    transform: rotate(360deg); /* Конечное положение (поворот на 360 градусов) */
  }
}
</style>
