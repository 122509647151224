<template>
  <div class="form">
    <div class="form__main">
      <div class="form__avatar avatar">
        <div class="avatar__preview-wrapper">
          <div class="avatar__preview">
            <img v-if="avatar.src"
              class="avatar__image"
              :src="avatar.src"
              @click="isCropperModal = true"
            >
            <img src="/default-user.png" class="avatar__image" v-else>
          </div>
        </div>
        <div class="avatar__main">
          <h2 class="avatar__heading">
            {{ $t('avatar') }}
          </h2>
          <div class="avatar__actions">
            <FileInput
              class="avatar__action avatar__action--red"
              :accepts="['image/jpeg', 'image/png']"
              @upload="loadAvatar"
            >
              <svg>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#download'}" />
              </svg>
              <span>
                {{ $t('upload') }}
              </span>
            </FileInput>
            <button class="avatar__action avatar__action--gray" v-if="avatar.src" @click="removeAvatar">
              <svg>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#minus'}" />
              </svg>
              <span>
                {{ $t('remove') }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="form__input">
        <InputWithLabel
          :label="$t('firstname')"
          :placeholder="$t('type_the', { field: $t('firstname').toLowerCase() })"
          v-model="artistData.name"
          :validations="validations.artistData.name"
        />
      </div>
      <div class="form__input">
        <InputWithLabel
          :label="'Spotify ID'"
          :placeholder="$t('type_the', {field: 'Spotify ID'})"
          v-model="artistData.spotify_id"
          :validations="validations.artistData.spotify_id"
        />
      </div>
      <div class="form__input">
        <InputWithLabel
          :label="'Apple ID'"
          :placeholder="$t('type_the', {field: 'Apple ID'})"
          v-model="artistData.apple_id"
          :validations="validations.artistData.apple_id"
        />
      </div>
    </div>
    <div class="form__actions">
      <button class="form__action form__action--cancel" @click="$emit('submit')">
        {{ $t('cancel') }}
      </button>
      <RedButton
        class="form__action form__action--submit"
        @click="saveArtistRequest"
        :disabled="v$.$silentErrors.length > 0"
      >
        {{ $t('save') }}
      </RedButton>
    </div>
    <Modal
      :visibility="isCropperModal"
      @switch="(value) => isCropperModal = value"
      :heading="'Artist\'s avatar'"
    >
      <AvatarCropper :src="avatar.src" @save="avatarCropHandler" />
    </Modal>
  </div>
</template>

<script>
import { required, minLength, helpers } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { getSpotifyID } from '@/utilities/getSpotifyID';
import { getAppleID } from '@/utilities/getAppleID';
import InputWithLabel from '../components/InputWithLabel.vue';
import RedButton from '../components/RedButton.vue';
import FileInput from '../components/FileInput.vue';
import Modal from '../components/Modal.vue';
import AvatarCropper from '../components/AvatarCropper.vue';
import useValidate from '@vuelidate/core';

export default {
  components: {
    InputWithLabel,
    RedButton,
    FileInput,
    Modal,
    AvatarCropper,
  },
  props: {
    artist: Object,
  },
  emits: ['submit'],
  data () {
    return {
      v$: useValidate(),

      isCropperModal: false,

      artistData: {
        avatar_uuid: '',
        name: '',
        spotify_id: '',
        apple_id: '',
      },
      avatar: {
        file: null,
        src: '',
      },
    };
  },
  methods: {
    ...mapActions([
      'fetchFile',

      'patchArtist',
      'uploadArtistAvatar',
      'removeArtistAvatar',
      'createArtist',

      'createNotification',
    ]),
    getSpotifyID,
    getAppleID,

    fetchAvatar () {
      const fileUuid = this.artist.avatar_uuid;
      if (!fileUuid) return;

      const avatarFile = this.file(this.artist.avatar_uuid);
      if (avatarFile) {
        this.avatar.src = avatarFile?.base64;
        return;
      }

      this.fetchFile(this.artist.avatar_uuid);
    },

    async removeAvatar () {
      const { uuid } = this.artist;
      if (uuid) {
        await this.removeArtistAvatar(uuid);
      }
      
      this.avatar = {
        src: null,
        file: null,
      };
    },

    avatarCropHandler ({ base64, binary }) {
      this.isCropperModal = false;
      this.avatar.src = base64;
      this.avatar.file = binary;
    },

    loadAvatar: async function (file) {
      this.avatar.file = file;
      this.avatar.src = await this.loadFileSRC(file);
      this.isCropperModal = true;
    },

    loadFileSRC (file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = () => {
          reject(fr.error);
        };
        fr.readAsDataURL(file);
      });
    },

    updateArtistAvatarRequest () {
      const formdata = new FormData();
      formdata.append('avatar', this.avatar.file);

      const payload = {
        artistUuid: this.artist.uuid,
        formdata,
      };

      this.uploadArtistAvatar(payload).then((response) => {
        this.fetchFile(response.data.avatar_uuid);
      }).catch((e) => {
        const notification = {
          id: new Date(),
          heading: 'Failed to upload avatar',
          message: e.message,
          style: 'failure',
        };

        this.createNotification(notification);
      });
    },

    saveArtistRequest () {
      const { name, spotify_id, apple_id } = this.artistData;
      const artistData = {
        name: name.trim(),
        spotify_id: spotify_id?.trim().length ? spotify_id.trim() : undefined,
        apple_id: apple_id?.trim().length ? apple_id.trim() : undefined,
      };

      if (!this.artist.uuid) {
        this.createArtist(artistData).then((response) => {
          if (this.avatar?.file) {
            const formdata = new FormData();
            formdata.append('avatar', this.avatar.file);

            const payload = {
              artistUuid: response.data.uuid,
              formdata,
            };

            this.uploadArtistAvatar(payload).then((response) => {
              this.fetchFile(response.data.avatar_uuid);
            }).catch((e) => {
              const notification = {
                id: new Date(),
                heading: 'Failed to upload avatar',
                message: e.message,
                style: 'failure',
              };

              this.createNotification(notification);
            });
          }

          const notification = {
            id: new Date(),
            heading: 'The artist was created',
            style: 'success',
            lifetime: 10000,
          };

          this.createNotification(notification);
        }).catch((e) => {
          const notification = {
            id: new Date(),
            heading: 'An error occurred while creating',
            message: e.message,
            style: 'failure',
            actionButtonText: 'Support',
            actionCallback: () => {
              this.$router.push({ name: 'Support' });
            },
          };

          this.createNotification(notification);
        }).finally(() => {
          this.$emit('submit');
        });
      } else {
        if (this.avatar.file) {
          this.updateArtistAvatarRequest();
        }
        const payload = {
          artistUuid: this.artist.uuid,
          artistData,
        };
        this.patchArtist(payload).then(() => {
          const notification = {
            id: new Date(),
            heading: 'The artist was updated',
            style: 'success',
            lifetime: 10000,
          };

          this.createNotification(notification);
        }).catch((e) => {
          const notification = {
            id: new Date(),
            heading: 'An error occurred while updating',
            message: e.message,
            style: 'failure',
            actionCallback: () => {
              this.$router.push({ name: 'Support' });
            },
          };

          this.createNotification(notification);
        }).finally(() => {
          this.$emit('submit');
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'file',
    ]),

    validations () {
      return {
        artistData: {
          name: {
            required,
            minLength: minLength(1),
          },
          spotify_id: {
            spotifyIdRegex: helpers.withMessage('Invalid Spotify ID', helpers.regex(/^[a-zA-Z0-9]{22}$/)),
          },
          apple_id: {
            appleIdRegex: helpers.withMessage('Invalid Apple ID', helpers.regex(/^[0-9]{5,10}$/)),
          },
        },
      };
    },
  },
  watch: {
    'artistData.spotify_id' (value) {
      const idFromLink = this.getSpotifyID(value);
      if (idFromLink) {
        this.artistData.spotify_id = idFromLink;
      }
    },
    'artistData.apple_id' (value) {
      const idFromLink = this.getAppleID(value);
      if (idFromLink) {
        this.artistData.apple_id = idFromLink;
      }
    },
  },
  mounted () {
    this.artistData = this.artist;
    this.fetchAvatar();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__main {
    display: grid;
    grid-template-columns: 240px 240px;
    column-gap: 16px;
    row-gap: 22px;
    margin-bottom: 32px;
  }

  // &__avatar {}

  // &__input {}

  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  &__action {
    height: 36px;

    &--submit {
      min-width: 150px;
      font-weight: 500;
    }

    &--cancel {
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;
      transition: .2s;
      color: $red;
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        transform: scale(1.04);
        transition: .2s;
      }
    }
  }
}
.avatar {
  display: flex;
  align-items: center;
  gap: 16px;

  &__preview-wrapper {
    filter: drop-shadow(0px 0px 0.5px gray);
  }

  &__preview {
    width: 85px;
    height: 85px;
    background: lightgray;
    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 400;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__action {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    width: min-content;
    white-space: nowrap;
    transition: .2s;

    svg {
      display: block;
      max-width: 12px;
      max-height: 12px;
    }

    &:hover {
      transform: scale(1.04);
      transition: .2s;
    }

    &--red {
      color: $red;
      fill: $red;
    }

    &--gray {
      color: #828282;
      fill: #828282;
    }
  }
}

@media (max-width: 768px) {
  .form {
    align-items: stretch;

    &__main {
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
  }
}
</style>
