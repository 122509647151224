import axios from 'axios';

const initialState = () => {
  return {
    files: [],
  };
};

const files = {
  state: () => initialState(),
  actions: {
    uploadFile: function ({ commit }, payload) {
      console.log(payload);

      return new Promise((resolve, reject) => {
        axios.post('/file', payload.formdata, {
          signal: payload.abortController?.signal,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => { payload.loadProgress(parseInt(progressEvent.loaded / progressEvent.total * 100)); },
        }).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchFile: function ({ commit }, fileUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/file/${fileUuid}/download`, {
          responseType: 'blob',
        }).then(response => {
          const base64 = window.URL.createObjectURL(response.data);
          const file = {
            base64,
            uuid: fileUuid,
            binary: response.data,
          };
          commit('addFile', file);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    deleteFile: function ({ commit }, fileUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/file/${fileUuid}`).then(response => {
          commit('removeFile', response.data.uuid);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },

    addFile: function (state, file) {
      state.files.push(file);
    },

    removeFile: function (state, uuid) {
      state.files = state.files.filter((file) => file.uuid !== uuid);
    },
  },
  getters: {
    files: state => {
      return state.files;
    },

    file: state => fileUuid => {
      return state.files.find((file) => file.uuid == fileUuid);
    },
  },
};

export default files;
