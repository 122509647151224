<template>
  <div
    class="glass"
    :style="{ backgroundImage: `url(${require(`@/assets/landing/crystals/${name}.png`)})`}"
  >
    <div class="glass__contents">
      <h1 class="glass__header">
        {{ header }}
      </h1>
      <div class="glass__content">
        <slot />
      </div>
    </div>
    <div class="glass__info">
      <div class="glass__icon">
        <img :src="require(`@/assets/landing/icons/${name}.png`)">
      </div>
      <div class="glass__name">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    header: String,
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/_shared.scss';
    @import '@/assets/_nullstyle.scss';

    .glass {
        position: relative;
        border-radius: 20px;
        color: $white;
        min-height: 380px;
        padding: 30px 30px 10px 30px;
        box-shadow: 0 0 21px rgba($color: black, $alpha: .3) ;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-size: cover;
        background-position: center;

    &__content {

    }

    &__header {
            text-transform: uppercase;
            font-size: 1.625rem;
            font-weight: 600;
            margin-bottom: 25px;
    }

    &__content {
            font-size: 1.188rem;
    }

    &__info {
            margin-top: 20px;
            display: flex;
            align-items: center;
    }

    &__icon {
            img{
                height: 80px;
            }
    }

    &__name {
            margin-left: 5px;
            font-size: 1.188rem;
    }
    }
</style>
