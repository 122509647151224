<template>
  <input
    class="date-pick"
    type="date"
    name=""
    id=""
  >
</template>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.date-pick{
  box-sizing: border-box;
  padding: 10px;

  border: 1px solid $dark-gray;
  border-radius: 11px;

  appearance: textfield;
  -webkit-appearance: textfield;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}
</style>
