<template>
  <div class="payments">
    <div
      class="loader"
      v-if="isDataLoading"
    >
      <LoadingLogo />
    </div>
    <section
      class="payments__section"
      v-else
    >
      <div class="payments__menu menu">
        <button
          :class="['menu__button', (purchaseTypeToShow == PurchaseType.SUBSCRIPTION) && 'menu__button--selected']"
          @click="purchaseTypeToShow = PurchaseType.SUBSCRIPTION"
        >
          <svg class="menu__icon">
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#calendar'}" />
          </svg>
          <span>
            {{ $t('subscriptions') }}
          </span>
        </button>
        <button
          :class="['menu__button', (purchaseTypeToShow == PurchaseType.PRODUCT) && 'menu__button--selected']"
          @click="purchaseTypeToShow = PurchaseType.PRODUCT"
        >
          <svg class="menu__icon">
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#once'}" />
          </svg>
          <span>
            {{ $t('products') }}
          </span>
        </button>
      </div>
      <div class="payments__block" v-if="purchaseTypeToShow == PurchaseType.SUBSCRIPTION">
        <div class="payments__heading">
          {{ $t('subscriptions') }}
        </div>
        <div class="payments__subscriptions">
          <div class="payments__subscription" v-for="plan in subscriptionPlans" :key="plan.name">
            <SubscriptionPlan :data="plan" />
          </div>
        </div>
      </div>
      <div class="payments__block" v-else-if="purchaseTypeToShow == PurchaseType.PRODUCT">
        <div class="payments__heading">
          {{ $t('products') }}
        </div>
        <div class="payments__offers">
          <div class="payments__offer" v-for="offer in productsInfo" :key="offer" @click="offeringProduct = offer">
            <ProductCard :product="offer" />
          </div>
        </div>
      </div>
      <div class="payments__block">
        <Table
          class="payments__table"
          v-if="purchases.length"
          :headers="tableHeaders"
          :name="$t('purchases')"
          :items="purchasesList"
          :numbering="false"
          @row-click="tableRowClickHandler"
        />
        <DataPlaceholder v-else
          :title="$t('no_purchases_placeholder_title')"
          :description="$t('no_purchases_placeholder_description')"
        />
      </div>
    </section>
    <ProductModal
      :visibility="!!offeringProduct"
      @switch="(v) => {offeringProduct = null}"
      :product="offeringProduct"
      :type="purchaseTypeToShow"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import LoadingLogo from '@/components/LoadingLogo.vue';
import Table from '@/components/Table.vue';
import ProductCard from '@/components/ProductCard.vue';
import ProductModal from '@/components/ProductModal.vue';
import DataPlaceholder from '../components/DataPlaceholder.vue';

import { Products } from '@/utilities/Products.js';
import { Subscriptions } from '@/utilities/Subscriptions.js';
import { PurchaseType } from '@/utilities/purchases.js';
import { name as tableName, headers as tableHeaders } from '@/utilities/tableStructures/payments.js';
import changeRouteSilently from '@/utilities/changeRouteSilently.js';
import SubscriptionPlan from '../components/SubscriptionPlan.vue';

export default {
  components: {
    Table,
    LoadingLogo,
    DataPlaceholder,
    ProductCard,
    ProductModal,
    SubscriptionPlan,
  },
  data: function () {
    return {
      Products,
      Subscriptions,
      PurchaseType,
      tableName,
      tableHeaders,

      purchaseTypeToShow: PurchaseType.SUBSCRIPTION,
      offeringProduct: null,

      isDataLoading: false,

      subscriptionPlans: [
        {
          isHorizontal: true,
          iconSrc: require('@/assets/landing/icons/SUPERHERO.png'),
          promo: 'Самый популярный',
          name: 'Superhero',
          duration: {
            number: 1,
            period: 'y',
          },
          price: 60,
          subtitle: 'Экономия 28%',
          details: [
            {
              text: 'Все возможности Semihero',
            },
            {
              text: '28% экономии на подписке',
            },
          ],
          buttonText: 'Выбрать план',
          colors: {
            background: '#235d85, #1c2948',
            name: '#30c5ff',
            duration: 'rgba(48, 197, 255, .2)',
            subtitle: '#30c5ff',
            hr: '#3e5687',
            activeIcon: '#30c5ff',
            info: '#30c5ff',
            button: '#30c5ff',
          },
        },
        {
          iconSrc: require('@/assets/landing/icons/BASIC.png'),
          name: 'Basic',
          duration: {
            number: 0,
          },
          price: 0,
          subtitle: 'Базовые функции сервиса',
          details: [
            {
              text: '80% заработка с прослушек',
            },
            {
              text: 'Публикация релиза до 14 дней',
            },
            {
              text: 'Техподдержка до 72 часов',
            },
            {
              text: 'Публикация на площадках от лица MyLabel',
            },
            {
              text: 'Общие финансовые отчеты',
            },
            {
              text: 'До 5 артистов',
            },
            {
              text: 'Доступ к MuzLink',
            },
            {
              text: 'Вывод средств только на карту',
            },
            {
              text: 'Ежедневная статистика',
              inactive: true,
            },
            {
              text: 'Промо',
              inactive: true,
            },

          ],
          buttonText: 'Начать бесплатно',
          colors: {
            background: '#d00a09, #970807',
            name: '#ffffff',
            duration: 'rgba(255, 111, 110, .2)',
            subtitle: '#ff6161',
            hr: '#e70100',
            activeIcon: '#ff403f',
            inactive: '#5b0000',
            button: '#f21818',
          },
        },
        {
          iconSrc: require('@/assets/landing/icons/SEMIHERO.png'),
          name: 'Semihero',
          duration: {
            number: 1,
            period: 'm',
          },
          price: 7,
          subtitle: 'Все функции сервиса',
          details: [
            {
              text: 'Все возможности плана <span>Basic</span>',
            },
            {
              text: '100% заработка с прослушек',
            },
            {
              text: 'Публикация релиза от 4 дней',
            },
            {
              text: 'Техподдержка в течении 24 часов',
            },
            {
              text: 'Публикация от лица своего лейбла',
            },
            {
              text: 'Промо (Яндекс Музыка, Spotify, VK Music)',
            },
            {
              text: 'Подробные финансовые отчеты',
            },
            {
              text: 'Ежедневная статистика',
            },
            {
              text: 'Доступ к генератору обложек RandomCover',
            },
            {
              text: 'Индивидуальный вариант вывода средств',
            },
            {
              text: '20% экономии на подписке',
              inactive: true,
            },
          ],
          buttonText: 'Выбрать план',
          colors: {
            background: '#fe9724, #fb6a00',
            name: '#ffe970',
            duration: 'rgba(255, 207, 157, .3)',
            subtitle: '#ffe970',
            hr: '#ffa65e',
            activeIcon: '#ffe970',
            infoIcon: '#ffe970',
            inactive: '#b74d00',
            button: '#ffc600',
          },
        },
      ],
    };
  },
  mounted () {
    this.fetchPurchasesRequest(() => {
      this.openUrlPayment();
    });
    this.fetchProducts();
    this.fetchSubscriptions();
  },
  computed: {
    ...mapGetters([
      'purchases',
      'purchase',

      'subscriptions',
      'products',
    ]),

    subscriptionsInfo () {
      return this.subscriptions.map((subscription) => {
        const info = Subscriptions[subscription.name];
        info.amount = subscription.amount;
        return info;
      });
    },

    productsInfo () {
      return this.products.map((product) => {
        const info = Products[product.name];
        info.amount = product.amount;
        return info;
      });
    },

    purchasesList: function () {
      const purchasesList = this.purchases.map((userPurchase) => {
        let purchase, purchaseInfo, icon;
        switch (userPurchase.type) {
        case PurchaseType.SUBSCRIPTION:
          purchase = userPurchase.subscription;
          purchaseInfo = this.Subscriptions[purchase.name];
          icon = 'calendar';
          break;
        case PurchaseType.PRODUCT:
          purchase = userPurchase.product;
          purchaseInfo = this.Products[purchase.name];
          icon = 'once';
          break;
        }

        const { type, uuid, status } = userPurchase;

        return {
          icon,
          type,
          uuid,
          status,
          name: purchaseInfo?.displayName,
          amount: `$${purchase.amount}`,
          createdAt: new Date(userPurchase.createdAt).toLocaleDateString('ru-RU'),
        };
      });

      return purchasesList;
    },
  },
  methods: {
    ...mapActions([
      'fetchPurchases',
      'showPaymentModal',
      'fetchProducts',
      'fetchSubscriptions',
    ]),
    ...mapMutations([
      'setCartPurchases',
    ]),
    changeRouteSilently,

    openPayment (paymentUuid) {
      const purchase = this.purchase(paymentUuid);
      if (purchase.subscription) {
        purchase.type = PurchaseType.SUBSCRIPTION;
      } else if (purchase.product) {
        purchase.type = PurchaseType.PRODUCT;
      }

      this.setCartPurchases([purchase]);
      this.showPaymentModal();
    },

    openUrlPayment () {
      const paymentUuid = this.$route.params.uuid;
      if (!paymentUuid) return;

      this.openPayment(paymentUuid);
      this.changeRouteSilently('/payments');
    },

    navigateToOrder (uuid, orderType) {
      const params = { uuid };
      const query = { orderType };

      this.$router.push({ name: 'Order', params, query });
    },

    tableRowClickHandler (item) {
      if (item?.status == 'PENDING') {
        this.openPayment(item.uuid);
      } else {
        console.log(item);
        const { uuid, type } = item;
        this.navigateToOrder(uuid, type);
      }
    },

    fetchPurchasesRequest () {
      this.isDataLoading = true;
      return this.fetchPurchases().finally(() => {
        this.isDataLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.payments {
  width: 100%;

  &__section {
    width: 100%;

    height: min-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__block {
    @include section;
    padding: 24px 16px;
    overflow-x: auto;
  }

  &__heading {
    font-size: 1.188rem;
    line-height: 100%;
    margin-bottom: 16px;
    margin-left: 8px;
  }

  &__menu {
  }

  &__subscriptions {
    padding-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    align-items: stretch;
    gap: 24px 12px;

    > :first-child {
      grid-column: 1 / -1;
    }
  }

  &__subscription {
    height: 100%;
  }

  &__offers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  &__offer {
    flex: 1;
    padding: 12px 16px 12px 12px;
    border-radius: 24px;
    background: #F6F6F6;
    cursor: pointer;
    transition: .2s;

    &:hover {
      transition: .2s;
      background: #eeeeee;
    }
  }
}

.menu {
  display: flex;
  gap: 16px;

  &__button {
    @include section(false);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: 48px;
    min-height: 48px;
    border: none;
    flex: 1;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: .2s;

    &:hover, &--selected {
      transition: .2s;

      color: $red;
      .menu__icon {
        transition: .2s;
        fill: $red;
      }
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    fill: $dark-gray;
    transition: .2s;
  }
}

.loader{
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  width: 100%;
}

@media (max-width: 660px) {
  .payments {
    &__offers {
      grid-template-columns: 1fr;
    }
  }
}
</style>
