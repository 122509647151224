import i18n from '../i18n';

const ReleaseStatus = {
  ALL: {
    key: 'ALL',
    title: i18n.global.t('release_status_all'),
    color: 'gray',
  },
  DRAFT: {
    key: 'DRAFT',
    title: i18n.global.t('release_status_draft'),
    color: 'gray',
  },
  DENIED: {
    key: 'DENIED',
    title: i18n.global.t('release_status_denied'),
    color: 'red',
  },
  PENDING: {
    key: 'PENDING',
    title: i18n.global.t('release_status_pending'),
    color: 'orange',
  },
  MODERATED: {
    key: 'MODERATED',
    title: i18n.global.t('release_status_moderated'),
    color: 'yellow',
  },
  PUBLISHED: {
    key: 'PUBLISHED',
    title: i18n.global.t('release_status_published'),
    color: 'green',
  },
};

export default ReleaseStatus;
