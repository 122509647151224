import axios from 'axios';
import io from 'socket.io-client';

const initialState = () => {
  return {
    socket: null,
    releases: [],
    multilinks: [],
  };
};

const transfering = {
  state: () => initialState(),

  actions: {
    startTransfering (context, { transferingToken }) {
      return axios.get(`/start-transfering/${transferingToken}`);
    },

    clearSocketState ({ state }) {
      state = initialState();
    },

    removeSocket ({ state, dispatch }) {
      console.log('отключение сокетов');
      if (state.socket) {
        state.socket.off('transfering-status');
        state.socket.disconnect();
        state.socket = null;
        dispatch('clearSocketState');
      }
    },

    createSocket ({ state, commit }) {
      console.log('подключение сокетов', state.socket);
      if (!state.socket) {
        console.log('попытка подключения');
        // TODO: env host
        const socket = io(process.env.VUE_APP_API_URL, {
          transports: ['websocket'],
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 5000,
          query: {
            access_token: localStorage.getItem('access_token'),
          },
        });

        state.socket = socket;
      }

      state.socket.connect();

      state.socket.on('connect', () => {
        console.log('Connected to server');

        state.socket.emit('transfering-status', '');

        state.socket.on('process', (data) => {
          commit('setSocketRelease', data.releases ? data.releases : []);
          commit('setSocketMultilinks', data.multilinks ? data.multilinks : []);
        });
      });

      state.socket.on('disconnect', () => { console.log('disconnect'); });

      state.socket.on('reconnect_attempt', () => {
        console.log('Attempting to reconnect');
      });

      state.socket.on('reconnect', (attemptNumber) => {
        console.log(`Reconnected after ${attemptNumber} attempts`);
        state.socket.connect();
      });

      return state.socket;
    },
  },

  mutations: {
    setSocketRelease: function (state, releases) {
      state.releases = releases;
    },

    setSocketMultilinks: function (state, multilinks) {
      state.multilinks = multilinks;
    },
  },

  getters: {
    socket: state => state.socket,
    socketReleases: state => state.releases,
    socketMultilinks: state => state.multilinks,
    transferingIsCompleted: state => {
      return (
        !state.releases.filter(({ status }) => status == 'waiting' || status == 'active').length &&
      !state.multilinks.filter(({ status }) => status == 'waiting' || status == 'active').length
      );
    },
  },
};

export default transfering;
