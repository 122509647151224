<template>
  <div class="dropdown">
    <div class="dropdown__selected" @click="dropdownOpened = true">
      <span>{{ selectedOption }}</span>
      <svg>
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
      </svg>
    </div>
    <div class="dropdown__options" v-if="dropdownOpened">
      <div class="dropdown__option dropdown__option--selected" @click="dropdownOpened = false">
        {{ selectedOption }}
      </div>
      <div :class="['dropdown__option', option == selectedOption ? 'dropdown__option--selected' : '']"
        v-for="option in options.filter((o) => o !== selectedOption)"
        :key="option"
        @click="selectOption(option)"
      >
        {{ option }}
      </div>
    </div>
    <div class="dropdown__closer" v-if="dropdownOpened" @click="dropdownOpened = false" />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
    initialValue: {
      type: String,
    },
  },
  data () {
    return {
      dropdownOpened: false,
      selectedOption: null,
    };
  },
  mounted () {
    this.selectedOption = this.initialValue ?? this.modelValue ?? this.options[0];
  },
  methods: {
    selectOption (option) {
      this.selectedOption = option;
      this.$emit('update:modelValue', option);
      this.$emit('select', option);
      this.dropdownOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.dropdown {
  z-index: 1;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;

  &__selected {
    display: flex;
    align-items: center;

    cursor: pointer;

    svg{
      width: 7px;
      height: 11px;
      margin-left: 8px;

      transform: rotate(90deg);
    }
  }

  &__options {
    position: absolute;
    z-index: 99;
    top: -1px;

    display: flex;
    flex-direction: column;

    padding: 12px 16px;

    border-radius: 16px;
    background: white;
    box-shadow: 0px 0px 15px 0px #0000001A;

    gap: 8px;
  }

  &__option {
    cursor: pointer;
    transition: .2s;

    color: #BFBFBF;

    &:hover {
      transition: .3s;

      color: black;
    }

    &--selected {
      color: $red;

      &:hover {
        color: $red;
      }
    }
  }

  &__closer {
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
  }
}
</style>
