<template>
  <section class="section">
    <div class="section__content">
      <div class="section__announcement announcement">
        <img src="@/assets/Logo.svg" alt="logo" class="announcement__logo">
        <p class="announcement__message">
          Скоро на вашем<br>смартфоне!
        </p>
      </div>
      <div class="section__instagram instagram">
        <p class="instagram__message">
          Следите за нами<br>в Instagram
        </p>
        <a
          href="https://www.instagram.com/mylabel.cc/"
          target="_blank"
          class="instagram__logo-wrapper"
        >
          <img src="@/assets/icons/instagram.svg" alt="instagram" class="instagram__logo">
        </a>
      </div>
    </div>
    <div id="background"></div>
  </section>
</template>

<script>
export default {
  mounted () {
  },
};
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 100%;
  height: 100vh;
  overflow: hidden;

  background-position: center;
  background-size: cover;

  &__content {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    width: max-content;
    width: 100%;
    max-width: 400px;
    height: 100%;
    padding: 22px;

    gap: 16px;
  }

  &__announcement {
    width: 100%;
  }

  // &__instagram { }
}
.announcement {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  height: 80px;
  padding: 0 16px;

  border-radius: 22px;
  background: white;

  gap: 16px;

  &__logo {
    height: 32px;
  }

  &__message {
    font-size: 15px;
    font-weight: 300;
  }
}
.instagram {
  display: flex;
  align-items: center;

  gap: 12px;

  &__message {
    text-align: end;

    color: white;
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;

    border-radius: 22px;
    background: white;
  }

  &__logo {
    width: 35px;
    height: 35px;
  }
}

  #background{
    position: absolute;
    top: 0;
    left: 0;
    width: 200vw;
    height: 100vh;
    background: url('~@/assets/WelcomeBackground.png');
    background-size: 100vw auto ;
    animation: background-move 120s infinite linear;
    background-color: black;
    z-index: -1;
  }

  @keyframes background-move {
    0%{
      transform: translateX(0px);
    }

    0%{
      transform: translateX(-100vw);
    }
  }
</style>
