<template>
  <section class="layout">
    <Header />
    <div class="container container-content">
      <div class="layout-content__wrapper">
        <router-view />
      </div>
    </div>
    <div class="layout__footer-wrapper">
      <Footer class="layout__footer" />
    </div>
    <Modal
      :visibility="isPaymentModal"
      @switch="paymentModalSwitch"
      :heading="'Payment details'"
    >
      <Payment />
    </Modal>
    <WarningModal />
    <transition name="modal-fade">
      <MusicLabelOfferModal v-show="isOpenMusicLabelOffer" @close="updateMusicLabelOffer(false)"/>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Header from '../sections/Header.vue';
import Footer from '../sections/Footer.vue';
import Modal from '../components/Modal.vue';
import WarningModal from '../components/WarningModal.vue';
import Payment from '../components/Payment.vue';
import Notification from '../components/Notification.vue';
import MusicLabelOfferModal from '../components/MusicLabelOfferModal.vue'

export default {
  components: {
    Header,
    Footer,
    Modal,
    WarningModal,
    Payment,
    Notification,
    MusicLabelOfferModal
  },
  mounted () {
    window.testNotification = this.testNotification;

    this.fetchSurveyBySlug('MLO1').then(response => {
      console.log(response)
    }).catch(()=>{
      this.updateMusicLabelOffer(false)
    })
  },
  methods: {

    ...mapActions([
      'setIsPaymentModal',
      'createNotification',
      'fetchSurveyBySlug'
    ]),
    ...mapMutations([
      'updateMusicLabelOffer'
    ]),

    testNotification (heading = 'Test notification', message = 'Test message', style = 'failure') {
      const notification = {
        id: new Date(),
        heading,
        message,
        style,
      };

      this.createNotification(notification);
    },

    paymentModalSwitch (value) {
      this.setIsPaymentModal(value);
    },
  },
  computed: {
    ...mapGetters([
      'isPaymentModal', 
      'notifications', 
      'isOpenMusicLabelOffer',
    ]),
  },
};
</script>

<style lang="scss" scoped>

@keyframes modal-fade-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modal-fade-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: all 0.5s ease;
}

.modal-fade-enter-from, .modal-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.modal-fade-enter, .modal-fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.layout{
  display: flex;
  flex-direction: column;
  height: 100%;

  &__footer-wrapper {
    padding: 16px;
  }
}

.container-content{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.layout-content__wrapper{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 560px) {
  .layout__footer-wrapper {
    padding: 8px;
  }
}
</style>
