<template>
  <section class="unavailable">
    <div class="blackout" />
    <main class="content">
      <h1 class="content__error-code">
        503
      </h1>
      <h3 class="content__error-text">
        Service is being updated
      </h3>
    </main>
  </section>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  @import '@/assets/_shared.scss';

    .unavailable{
        margin: 0;
        padding: 0;
        background-image: url('../assets/WelcomeBackground.png');
        background-color: black;
    }
    .blackout{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.2;
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        color: white;
    }
    .content__error-code{
        opacity: 0.12;
        font-size: 500px;
        font-weight: 900;
        margin: 86px 0 0 0;
        line-height: 87%;
    }
    .content__error-text{
        opacity: 0.25;
        font-size: 2.5rem;
        font-weight: 700;
        margin-top: 17px;
    }
</style>
