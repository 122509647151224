const validateCoverImage = async (src) => {
  const img = new Image();
  img.src = src;
  await img.decode();

  const size = img.height <= img.width ? img.height : img.width;

  if (size < 1440) {
    return false;
  }
  
  return true;
}

export default validateCoverImage;
