export const Products = {
  MYLABEL_CUSTOM_PC_LINE: {
    name: 'MYLABEL_CUSTOM_PC_LINE',
    displayName: 'Custom Publisher line',
    description: 'You can set your own PC',
    amount: 10,
  },
  MYLABEL_EARLY_DATE: {
    name: 'MYLABEL_EARLY_DATE',
    displayName: 'Release earlier',
    description: 'You can release earlier',
    amount: 10,
  },
};
