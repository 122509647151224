<template>
  <section>
    <Modal
      style="max-width: 400px"
      :heading="'Внимание'"
      :visibility="alreadyExistError"
      :action-text="'Войти'"
      @actionClick="$router.push({name: 'Sign In'})"
      @switch="(value) => alreadyExistError = value"
      :closable="false"
    >
Ваш профиль уже успешно создан в новой системе.<br><br>

      Пожалуйста, обратите внимание, что <strong> временный пароль был отправлен на ваш адрес электронной почты. </strong> При необходимости вы также можете восстановить пароль.<br><br>

      С уважением команда MyLabel.
    </Modal>
    <!-- START NEW PASSWORD TRANSFERING MODAL -->
    <Modal
      style="max-width: 400px"
      :heading="'Новый пароль на почте.'"
      :visibility="isTransferingMailPosted"
      :action-text="'Хорошо'"
      @actionClick="$router.push({name: 'Sign In'})"
      @switch="(value) => isTransferingMailPosted = value"
      :closable="false"
    >
      Ваш профиль успешно перенесён в новую систему, спасибо за доверие к MyLabel !
      <div class="img-wrapper">
        <img src="@/assets/password.svg" alt="">
      </div>
      Для безопаности ваш пароль был сброшен и отправлен на вашу почту. Вы всегда сможете его изменить внутри нового кабинета.
    </Modal>
    <!-- END NEW PASSWORD TRANSFERING MODAL -->
    <!-- START  TRANSFERING MODAL -->
    <Modal
      style="max-width: 400px"
      :heading="'Требуется перенос данных.'"
      :visibility="isNeedTransfering && !alreadyExistError"
      :action-text="loading?'Переносим...':'Да перенести.'"
      :loading="loading"
      :closable="false"
      @switch="(value) => isNeedTransfering = value"
      @actionClick="startTransferingRequest"
    >
      Мы обнаружили, что у вас уже есть выпущенные релизы. Мы можем перенести их в новый кабинет автоматически!
      <div class="img-wrapper">
        <img src="@/assets/transfering.svg" alt="">
      </div>
      После переноса старый кабинет будет заморожен, доступ к нему можно будет вернуть через техническую поддержку.<br><br>
      Согласны ли вы с переносом данных?
    </Modal>
    <!-- END    TRANSFERING MODAL -->
  </section>
</template>

<script>
import Modal from '../components/Modal.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Modal,
  },
  data () {
    return {
      errors: '',
      isNeedTransfering: true,
      loading: false,
      isTransferingMailPosted: false,
    };
  },
  mounted () {
    this.logout();
  },
  methods: {
    ...mapActions(['startTransfering', 'logout', 'createNotification']),

    startTransferingRequest: function () {
      this.loading = true;
      this.startTransfering({ transferingToken: this.$route.params.transfering_token }).then(() => {
        this.isTransferingMailPosted = true;
        this.isNeedTransfering = false;
        this.errors = '';
      })
        .catch(e => {
          if (e.response && e.response.status == 409) {
            this.alreadyExistError = true;
          } else {
            this.errors = 'Произошла ошибка';
            const notification = {
              id: new Date(),
              heading: 'Failed to start transfering',
              style: 'failure',
              lifetime: 5000,
            };
            this.createNotification(notification);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/_shared.scss';

  .img-wrapper{
    padding: 30px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
</style>
