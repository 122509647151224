<template>
  <SquareButton class="upload-button" @click="navigateToUpload">
    <svg>
      <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#plus-translucent'}" />
    </svg>
    {{ $t('new_release') }}
  </SquareButton>
</template>

<script>
import SquareButton from './SquareButton.vue';

export default {
  components: {
    SquareButton,
  },
  methods: {
    navigateToUpload () {
      this.$router.push('/upload');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.upload-button {
  background: $red;
  svg{
    width: 14px;
    height: 14px;
    fill: white;
  }
}
</style>
