const app = {
  state: () => ({
    isAppLoading: false,
    isAppDidRouterNavigations: false,
  }),

  actions: {
    startAppLoading: function ({ commit }) {
      commit('setIsAppLoading', true);
      return true;
    },
    finishAppLoading: function ({ commit }) {
      commit('setIsAppLoading', false);
      return true;
    },
    commitRouterNavigation: function ({ commit }) {
      commit('setIsAppDidRouterNavigations', true);
      return true;
    },
  },

  mutations: {
    setIsAppLoading: function (state, status) {
      state.isAppLoading = status;
    },
    setIsAppDidRouterNavigations: function (state, status) {
      state.isAppDidRouterNavigations = status;
    },
  },

  getters: {
    isAppLoading: state => state.isAppLoading,
    isAppDidRouterNavigations: state => state.isAppDidRouterNavigations,
  },
};

export default app;
