<template>
  <section class="layout">
    <TransferingProcessModal
      style="width: 600px"
      :heading="'Процесс переноса данных'"
      :visibility="isTransferingProcessShow"
      @switch="(value) => isTransferingProcessShow = value"
    />
    <div class="layout__main">
      <div class="layout__dashboard section dashboard">
        <div class="dashboard__header">
          <div class="section__title">
            {{ $t('dashboard') }}
          </div>
          <LogoutButton class="dashboard__logout-btn" />
          <!-- <button class="dashboard__settings-btn">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#options'}" />
            </svg>
          </button> -->
        </div>
        <DashboardProfile :user="user" class="dashboard__profile" />
        <div class="dashboard__statistics">
          <div class="dashboard__statistic statistic statistic--streams">
            <p class="statistic__title">
              {{ $t('streams') }}
            </p>
            <p class="statistic__value">
              {{ totalStreamsText }}
            </p>
            <div class="statistic__link-wrapper">
              <router-link class="statistic__link" :to="{ name: 'Statistics' }">
                {{ $t('statistics') }}
              </router-link>
            </div>
          </div>
          <div class="dashboard__statistic statistic statistic--revenue">
            <p class="statistic__title">
              {{ $t('revenue') }}
            </p>
            <p class="statistic__value">
              €0
            </p>
            <button class="statistic__withdraw-btn">
              {{ $t('withdraw') }}
            </button>
          </div>
        </div>
        <!-- <MusicLabelOffer @click="updateMusicLabelOffer(true)" class="block-wrapper"/> -->

      </div>
      <div class="layout__content">
        <router-view v-slot="{ Component }">
          <transition
            name="slide"
            mode="out-in"
          >
            <component
              :is="Component"
              :key="$route.path"
            />
          </transition>
        </router-view>
      </div>
      <div class="layout__upload">
        <div class="upload-wrapper section actions">
          <div class="section__title">
            {{ $t('upload_music') }}
          </div>
          <UploadButton />
        </div>
        <TranferingProcess @click="transferingProcessClickHandler" :completed="transferingIsCompleted" v-show="showTransferingStatus" />

      </div>
    </div>
  </section>
</template>

<script>
import WhiteButton from '../components/WhiteButton.vue';
import SquareButton from '../components/SquareButton.vue';
import DropdownNew from '../components/DropdownNew.vue';
import RedButton from '../components/RedButton.vue';
import Notification from '../components/Notification.vue';
import openUrlInNewTab from '../utilities/openUrlInNewTab';
import RandomCoverButton from '../components/RandomCoverButton.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getDashFormattedDate } from '../utilities/getDashFormattedDate';
import DashboardProfile from '../components/DashboardProfile.vue';
import UploadButton from '../components/UploadButton.vue';
import LogoutButton from '../components/LogoutButton.vue';
import TranferingProcess from '../components/TransferingProcess.vue';
import TransferingProcessModal from '../components/TransferingProcessModal.vue';
import MusicLabelOffer from '../components/MusicLabelOffer.vue'


export default {
  components: {
    WhiteButton,
    RedButton,
    SquareButton,
    DropdownNew,
    Notification,
    RandomCoverButton,
    DashboardProfile,
    UploadButton,
    LogoutButton,
    TranferingProcess,
    TransferingProcessModal,
    MusicLabelOffer,
  },
  data: function () {
    return {
      showTransferingStatus: false,
      statisticsSettings: {
        periodOptions: ['Day', 'Week', 'Month', 'Quarter', 'Year'],
        selectedPeriod: 'Month',
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

      // muslinkUrl: 'https://mus.link',
      avatarSrc: '',
      isTransferingProcessShow: false,
    };
  },
  mounted: function () {
    const unixTimeNow = Math.floor(Date.now() / 1000);
    const transferedStatusWasViewed = parseInt(localStorage.getItem('transferedStatusWasViewed'));

    console.log(transferedStatusWasViewed, unixTimeNow);

    if ((transferedStatusWasViewed + 48 * 60 * 60) > unixTimeNow) {
      this.showTransferingStatus = true;
    }

    if (this.transferingIsCompleted && !transferedStatusWasViewed) {
      localStorage.setItem('transferedStatusWasViewed', unixTimeNow);
    }

    this.fetchUserData().then((response) => {
      const avatarFile = this.file(response.data.avatar_url);
      if (avatarFile) {
        this.fetchFile(this.user.avatar_url);
      }

      if (this.showTransferingStatus) {
        this.createSocket();
      } else {
        this.removeSocket();
      }
    });

    const options = {
      ...this.period,
      page: 1,
      take: 50,
    };

    this.fetchCommonStreams(options);
  },
  beforeUnmount () {
    this.removeSocket();
  },
  methods: {
    ...mapActions([
      'fetchUserData',
      'fetchCommonStreams',
      'fetchFile',
      'createSocket',
      'removeSocket',
    ]),
    ...mapMutations([
      'updateMusicLabelOffer'
    ]),

    openUrlInNewTab,
    getDashFormattedDate,

    getQuarterPeriod (date = new Date()) {
      const year = date.getFullYear();
      const quarter = Math.floor(date.getMonth() / 3);
      const startMonth = quarter * 3;
      const endMonth = startMonth + 3;
      const start_date = new Date(year, startMonth, 1);
      const end_date = new Date(year, endMonth, 0);

      return { start_date, end_date };
    },

    navigateToUpload () {
      this.$router.push({ name: 'Upload' });
    },

    selectPeriodHandler (option) {
      this.statisticsSettings.selectedPeriod = option;
    },

    transferingProcessClickHandler () {
      this.isTransferingProcessShow = true;
    },
  },
  computed: {
    ...mapGetters([
      'file',
      'user',
      'notifications',
      'commonTotalStreams',
      'surveyBySlug'
    ]),

    transferingIsCompleted () {
      return (
        !this.$store.getters.socketReleases.filter(({ status }) => status == 'waiting' || status == 'active').length &&
      !this.$store.getters.socketMultilinks.filter(({ status }) => status == 'waiting' || status == 'active').length
      );
    },

    totalStreamsText () {
      return this.commonTotalStreams > 1000 ? `${Math.floor(this.commonTotalStreams / 1000)}k` : this.commonTotalStreams;
    },

    userBirthDate: function () {
      return new Date(this.user.date_of_birth);
    },

    period () {
      let start_date = new Date();
      let end_date = new Date();

      const date = start_date.getDate();
      const month = start_date.getMonth();
      const year = start_date.getFullYear();
      const quarter = this.getQuarterPeriod(start_date);

      switch (this.statisticsSettings.selectedPeriod) {
      case 'Week':
        start_date.setDate(date - 7);
        break;
      case 'Month':
        start_date = new Date(year, month - 1, date);
        break;
      case 'Quarter':
        start_date = quarter.start_date;
        end_date = quarter.end_date;
        break;
      case 'Year':
        start_date = new Date(year - 1, month, date);
        break;
      }

      const periodStrings = {
        start_date: getDashFormattedDate(start_date),
        end_date: getDashFormattedDate(end_date),
      };

      return periodStrings;
    },
  },
  watch: {
    user: {
      deep: true,
      handler (newUser, prevUser) {
        console.log(prevUser, newUser);
        if (prevUser.avatar_url == newUser.avatar_url) return;

        this.fetchFile(newUser.avatar_url);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.block-wrapper{
  margin-top: 30px;

  &:last-child{
    margin-bottom: 0;
  }
}

.squeeze-slide-enter-active,
.squeeze-slide-leave-active{
  transition: all .32s ease-in-out;
  overflow: hidden;
}

.squeeze-slide-enter-from,
.squeeze-slide-leave-to{
  transform: translateY(-20px) scale(.8);

  opacity: 0;
  max-height: 0px;
}
.squeeze-slide-enter-to,
.squeeze-slide-leave-from{
  max-height: 300px;
}

.slide-enter-active,
.slide-leave-active{
  transition: opacity 0.32s, transform .32s ease-in-out;
}

.slide-enter-from,
.slide-leave-to{
  transform: translateY(-20px) perspective(800px);

  opacity: 0;
}

.layout {
  &__main {
    display: grid;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    padding: 20px 16px;
    column-gap: 24px;
    grid-template-columns: 370px 1fr 370px;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "dashboard content upload"
      "notifications content upload"
  }

  &__dashboard {
    grid-area: dashboard;
  }

  &__upload {
    grid-area: upload;
  }

  &__content {
    grid-area: content;
    flex: 1;
  }

  &__notifications {
    grid-area: notifications;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.section{
  box-sizing: border-box;
  width: 100%;
  height: min-content;
  margin-bottom: 22px;
  padding: 25px;

  border-radius: $sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;

  &__title{
    font-size: 1.188rem;
    font-weight: 400;
    line-height: 100%;
  }
}

.actions{
  display: flex;
  flex-direction: column;

  .section__title{
    margin-bottom: 22px;
  }
}

.dashboard{
  position: relative;

  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;
  }

  &__profile{
    padding-bottom: 16px;
  }

  &__logout-btn{
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__statistics{
    position: relative;

    display: flex;

    padding-top: 32px;

    &::before{
      position: absolute;
      top: 0;

      width: 100%;
      height: 1px;

      content: '';

      background: #DDDDDD;
    }
  }

  &__statistic{
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
  }
}

.statistic{
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title{
    margin-bottom: 19px;

    font-size: 1.188rem;
    font-weight: 500;
  }

  &__value{
    margin-bottom: 12px;

    font-size: 2.188rem;
    font-weight: 600;
  }

  &__withdraw-btn{
    width: 100%;
    height: 40px;

    cursor: pointer;
    transition: .3s;

    color: white;
    border: none;
    border-radius: 16px;
    background: $red;

    font-size: 1rem;
    font-weight: 500;

    &:hover {
      transition: .3s;
      transform: scale(1.03);
    }
  }

  &--streams{
    margin-right: 13px;
  }

  &__link-wrapper {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    color: $red;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    transition: .2s;

    &:hover {
      transition: .2s;
      transform: scale(1.06);
    }
  }
}

@media (max-width: 660px) {
  .layout__main {
    grid-template-columns: 100%;
    grid-template-areas:
      "content"
  }
  .layout__dashboard, .layout__upload, .layout__notifications {
    display: none;
  }
}

@media (min-width: 661px) and (max-width: 940px) {
  .layout__main {
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "dashboard upload"
      "dashboard notifications"
      "content content"
  }
}

@media (min-width: 941px) and (max-width: 1240px) {
  .layout__main {
    column-gap: 16px;
    grid-template-columns: 305px 1fr;
    grid-template-areas:
      "dashboard content"
      "upload content"
      "notifications content"
  }
}

@media (min-width: 1241px) and (max-width: 1366px) {
  .layout__main {
    column-gap: 16px;
    grid-template-columns: 305px 1fr 260px;
    grid-template-areas:
      "dashboard content upload"
      "notifications content ."
  }
}

@media (max-width: 1366px) {
  .dashboard__logout-btn {
    width: min-content;
    padding: 0;
    position: initial;
    background: none;
    height: min-content;
  }
}

</style>
