<template>
  <button class="gray-button">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
    @import '@/assets/_shared.scss';

    .gray-button{
      display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 20px;
        border: none;
        border-radius: $button_border-radius;
        cursor: pointer;
        line-height: normal;
        text-align: center;
        transition: .1s;
        background: $gray;
        color: #82868a;

        &:enabled:hover{
          transform: translateY(-2px);
          transition: .2s;
          background: rgb(193, 193, 193);
        }

        &:enabled:active{
          transform: translateY(1px);
        }

        &:disabled{
          background: $gray;
          color:$dark-gray;
          cursor: not-allowed;
        }
    }
</style>
