<template>
  <button class="transfering">
    <div class="title">
      <div class="header">
        {{getHeader}}
      </div>
      <div class="subheader">
        нажмите для просмотра
      </div>
    </div>
    <div :class="['icon', {'icon--active': !completed}]">
      <svg v-show="completed">
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#success'}" />
      </svg>
      <svg v-show="!completed">
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  props:{
    completed: Boolean,
  },
  computed:{
    getHeader(){
      return this.completed?'Перенос завершён' : 'Перенос данных'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/_shared.scss';

  .transfering{
    display: flex;
    margin-top: 15px;
    height: 60px;
    width: 100%;
    position: relative;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1019607843);
    transition: .2s;
  }

  .transfering:active{
    transform: translateY(4px);
  }

  .title{
    box-sizing: border-box;
    background: rgba($color: white, $alpha: 1.0);
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    width: calc(100% - 60px);
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 15px 0px 0px 15px;

    .header{
      font-weight: 600;
    }

    .subheader{
      font-size: 12px;
      font-weight: 600;
      color: $dark-gray;
    }
  }
  .icon{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;
    border-radius: 0px 15px 15px 0px;
  }
  .icon svg{
    width: 25px;
    height: 25px;
    fill: white;
  }

  .icon--active{
    background: $red;

    svg{
      animation: rotateAnimation .6s linear infinite;
    }
  }

  @keyframes scroll {
    100% {
      background-position: 0px -400%;
    }
  }

  @media (prefers-reduced-motion) {
    .wrapper {
      animation: scroll 800s linear infinite;
    }
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg); /* Начальное положение */
    }
    to {
      transform: rotate(360deg); /* Конечное положение (поворот на 360 градусов) */
    }
  }

</style>
