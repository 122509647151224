<template>
  <label class="password-field">
    <Input
      v-bind="{...$attrs}"
      class="password-field__input"
      :type="inputType"
    />
    <button
      @click="toggleVisibility"
      class="password-field__button"
      type="button"
    >
      <SvgIcon type="mdi" :path="eyeIcon" />
    </button>
  </label>
</template>

<script>
import Input from './Input';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiEyeClosed, mdiEyeOutline } from '@mdi/js';

export default {
  components: {
    Input,
    SvgIcon,
  },
  data () {
    return {
      isHidden: true,
    };
  },
  methods: {
    toggleVisibility () {
      this.isHidden = !(this.isHidden);
    },
  },
  computed: {
    eyeIcon () {
      return this.isHidden ? mdiEyeOutline : mdiEyeClosed;
    },
    inputType () {
      return this.isHidden ? 'password' : 'text';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.password-field {
  position: relative;
  flex: 1;

  &__input {

  }

  &__button {
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 8px;
    height: 100%;
    color: $dark-gray;
    border: none;
    background: none;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
