<template>
  <footer class="footer">
    <div class="footer__section">
      <p class="footer__info">
        © {{ getCurrentYear() }} MyLabel.cc
      </p>
      <p class="footer__info">
        {{ $t('all_rights_reserved') }}.
      </p>
    </div>
    <div class="footer__section">
      <p class="footer__info footer__info--contact">
        {{ $t('contact_us') }}:
      </p>
      <div class="footer__contact">
        <a :href="links.instagram" target="_blank">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#instagram'}" />
          </svg>
          <span>@mylabel.cc</span>
        </a>
      </div>
      <div class="footer__contact">
        <a :href="links.vk" target="_blank">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#vk'}" />
          </svg>
          <span>@mylabelcc</span>
        </a>
      </div>
      <div class="footer__contact">
        <a :href="`mailto:${links.email}`" target="_blank">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#mail'}" />
          </svg>
        </a>
        <span>commercial@mylabel.cc</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      links: {
        instagram: 'https://instagram.com/mylabel.cc',
        vk: 'https://vk.com/mylabelcc',
        email: 'commercial@mylabel.cc',
      },
    };
  },
  methods: {
    copyEmail () {
      navigator.clipboard.writeText(this.links.email);
    },
    getCurrentYear () {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  padding: 0 16px;

  border-radius: 16px;
  background: #EDEDED;

  &__section {
    display: flex;
    align-items: center;

    gap: 16px;
  }

  &__info {
    color: #9C9C9C;

    font-size: 15px;

    &--contact {
      margin-right: 8px;

      text-align: right;
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      span {
        max-width: 170px;
        opacity: 1;
        transition: .5s;
        transform: scale(1);
        margin-left: 8px;
      }

      svg {
        transition: .2s;

        fill: #C4C4C4;
      }
    }

    span {
      opacity: 0;
      max-width: 0px;
      overflow: hidden;
      transform: scale(.5);
      cursor: copy;

      transition: .4s;

      color: #9C9C9C;

      font-size: 15px;
      font-weight: 300;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      span {
        cursor: pointer;
      }
    }

    svg {
      width: 22px;
      height: 22px;

      transition: .2s;

      fill: #D9D9D9;
    }
  }
}

@media (max-width: 560px) {
  .footer__info--contact {
    display: none;
  }
}

</style>
