<template>
  <SquareButton class="button" @click="openUrlInNewTab(randomCoverUrl)">
    <svg>
      <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#randomcover-translucent'}" />
    </svg>
    {{ $t('generate_cover') }}
  </SquareButton>
</template>

<script>
import SquareButton from './SquareButton.vue';
import openUrlInNewTab from '../utilities/openUrlInNewTab';

export default {
  components: {
    SquareButton,
  },
  data () {
    return {
      randomCoverUrl: 'https://randomcover.cc',
    };
  },
  methods: {
    openUrlInNewTab,
  },
};
</script>

<style lang="scss" scoped>
.button {
  background: white;
  border: 2px solid #FF236A;
  color: #FF236A;

  svg{
    fill: #FF236A;
    width: 18px;
    height: 12px;
  }
}
</style>
