<template>
  <label
    @click="handleLabelClick"
    :class="['fileinput', { 'shake': disabled && fileIsDropingError }]"
    @dragenter.prevent="fileIsDroping=true"
    @dragleave="fileIsDroping=false"
    @dragover.prevent
    @drop.prevent="loadDropFile"
  >
    <slot />
    <input
      ref="input"
      @change="loadInputFile"
      type="file"
      name="track"
      :multiple="multiple"
      :accept="accepts ? accepts.join(', ') : null"
      :disabled="disabled"
    >
  </label>
</template>


<script>
import pica from 'pica';
import { mapActions } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    accepts: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      fileIsDroping: false,
      fileIsDropingError: false,
    };
  },
  methods: {
    ...mapActions(['createNotification']),

    handleLabelClick() {
      if (this.disabled) {
        this.fileIsDropingError = true;
        setTimeout(() => {
          this.fileIsDropingError = false;
        }, 500); // Длительность анимации тряски
      }
    },

    async loadDropFile(event) {
      this.fileIsDroping = false;
      this.fileIsDropingError = !this.validateDropFile(event, this.accepts);
      if (!this.fileIsDropingError) {
        const file = event.dataTransfer.files[0];
        if (this.isImage(file)) {
          const processedFile = await this.processImage(file);
          this.emitFile(processedFile);
        } else {
          this.emitFile(file);
        }
      }
    },

    async loadInputFile(event) {
      this.fileIsDropingError = false;
      const file = event.target.files[0];
      if (this.isImage(file)) {
        const processedFile = await this.processImage(file);
        this.emitFile(processedFile);
      } else {
        this.emitFile(file);
      }
    },

    async processImage(file) {
      const resizedFile = await this.resizeImage(file);
      if (!this.isJpeg(resizedFile)) {
        return this.convertToJpeg(resizedFile);
      }
      return resizedFile;
    },

    emitFile(file) {
      this.$emit('upload', file);
      this.$refs.input.value = '';
    },

    validateDropFile(event, accepts) {
      const file = event.dataTransfer.items[0].getAsFile();
      if (file) {
        return Boolean(accepts.find(accept => accept === file.type));
      } else {
        return false;
      }
    },

    isImage(file) {
      return file && file.type.startsWith('image/');
    },

    isJpeg(file) {
      const jpegExtension = /\.jpeg$/i;
      return jpegExtension.test(file.name);
    },

    async resizeImage(file) {
      return this.loadImage(file, (img, canvas, context) => {
        let { width, height } = img;
        const maxSize = 3000;


        if (width > maxSize || height > maxSize) {
          if (width > height) {
            height = Math.round((height * maxSize) / width);
            width = maxSize;
          } else {
            width = Math.round((width * maxSize) / height);
            height = maxSize;
          }

          const notification = {
            id: new Date(),
            heading:  this.$t('image_compressed'),
            message: this.$t('image_compressed_to_size', { size: `${width}x${height}` }),
            style: 'success',
          };
          this.createNotification(notification);
        }

        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);
      });
    },

    async convertToJpeg(file) {
      return this.loadImage(file, async (img, canvas, context) => {
        const picaInstance = pica();
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        const blob = await picaInstance.toBlob(canvas, 'image/jpeg', 0.90);
        return new File([blob], file.name.replace(/\.[^.]+$/, '.jpeg'), { type: 'image/jpeg' });
      });
    },

    loadImage(file, processImageCallback) {
      const img = new Image();
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = (e) => {
          img.onload = async () => {
            const result = await processImageCallback(img, canvas, context);
            if (result) {
              resolve(result);
            } else {
              canvas.toBlob(blob => {
                resolve(new File([blob], file.name, { type: file.type }));
              }, file.type);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.fileinput {
  cursor: pointer;

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }

  &.shake {
    animation: shake 0.5s; /* Продолжительность анимации */
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}
</style>

