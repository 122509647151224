const headers = [
  {
    field: 'cover_uuid',
    isImage: true,
  },
  {
    field: 'title',
    itemCellsAlign: 'left',
  },
];

export { headers };
