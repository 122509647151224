<template>
  <div class="dashboard__profile">
    <div class="dashboard__avatar">
      <img :src="userAvatar" alt="Avatar">
    </div>
    <div class="dashboard__details">
      <div class="dashboard__username">
        {{ userName }}
      </div>
      <button class="dashboard__settings-btn">
        <router-link :to="{ name: 'Settings' }">
          {{ $t('settings') }}
        </router-link>
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['file']),

    userName: function () {
      let userName = this.$t('unknown_user');
      if (!this.user) return userName;

      const lastname = this.user.lastname;
      const firstname = this.user.first_name;
      const fullname = [firstname, lastname].join(' ');

      if (fullname.length > 1) {
        userName = fullname;
      }

      return userName;
    },

    userAvatar: function () {
      return this.file(this.user.avatar_url)?.base64 ?? '/default-user.png';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.dashboard {
  &__avatar{
    width: 78px;
    min-width: 78px;
    height: 78px;
    min-height: 78px;

    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;

    img{
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__username{
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 26px;
  }

  &__settings-btn{
    display: flex;
    align-items: center;

    padding: 0;

    cursor: pointer;

    border: none;
    background: none;

    gap: 5px;
    transition: .2s;

    &:hover {
      transition: .2s;
      gap: 7px;
    }

    span {
      color: $red;

      font-size: 1rem;
      font-weight: 500;
    }

    svg {
      width: 7px;
      height: 11px;
    }

    a {
      text-decoration: none;

      color: $red;

      font-weight: 500;
    }
  }

  &__profile{
    position: relative;

    display: flex;
    align-items: center;

    gap: 16px;
  }
}

</style>
