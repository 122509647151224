export const PaymentMethods = {
  cryptocloud: {
    isActive: true,
    key: 'cryptocloud',
    iconFile: 'cryptocloud.svg',
    name: 'CryptoCloud',
    paymentType: 'CRYPTO_CLOUD',
  },
  card: {
    isActive: false,
    key: 'card',
    iconFile: 'card.svg',
    name: 'Card',
    paymentType: 'CARD',
  },
  paypal: {
    isActive: true,
    key: 'paypal',
    iconFile: 'paypal.png',
    name: 'PayPal',
    paymentType: 'PAY_PAL',
  },
  stripe: {
    isActive: false,
    key: 'stripe',
    iconFile: 'stripe.png',
    name: 'Stripe',
    paymentType: 'STRIPE',
  },
};
