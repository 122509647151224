<template>
  <button class="red-button">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.red-button{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  background: $red;
  color: $white;
  border: none;
  border-radius: $button_border-radius;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  transition: .1s;


  &:enabled:hover{
    transform: translateY(-2px);
    transition: .2s;
    background: rgb(212, 23, 23);
  }

  &:enabled:active{
    transform: translateY(1px);
  }

  &:disabled{
    background: $gray;
    color:$dark-gray;
    cursor: not-allowed;
  }
}
</style>
