<template>
  <div
    class="drafts-loading"
    v-if="isDataLoading"
  >
    <LoadingLogo />
  </div>
  <section
    class="drafts"
    v-else
  >
    <div class="drafts__main">
      <Table
        v-if="draftsReleasesList.length"
        :name="$t('drafts')"
        :headers="tableHeaders"
        :items="draftsReleasesList"
      />
      <DataPlaceholder v-else
        :title="$t('no_drafts_placeholder_title')"
        :description="$t('no_drafts_placeholder_description')"
        :button-text="$t('no_drafts_placeholder_action')"
        :button-redirect-url="'/upload'"
      />
    </div>
    <Pagination
      v-if="draftsReleasesList?.length"
      :options="pagination.options"
      :meta="pagination.meta"
      @options-change="(newOptions) => pagination.options = newOptions"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Pagination from '@/components/Pagination.vue';
import LoadingLogo from '@/components/LoadingLogo.vue';
import Table from '@/components/Table.vue';
import DataPlaceholder from '../components/DataPlaceholder.vue';

import { name as tableName, headers as tableHeaders } from '@/utilities/tableStructures/drafts.js';

export default {
  components: {
    Table,
    Pagination,
    LoadingLogo,
    DataPlaceholder,
  },
  data: function () {
    return {
      isDataLoading: true,
      tableName,
      tableHeaders,

      pagination: {
        options: {
          page: 1,
          take: 10,
          status: 'DRAFT',
        },
        meta: {},
      },
    };
  },
  mounted () {
    this.fetchUserArtists();
  },
  computed: {
    ...mapGetters(['draftReleases', 'artistByUuid']),

    draftsReleasesList: function () {
      const draftsReleasesList = this.draftReleases.map(release => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
          artist: {
            name: this.artistByUuid(release.artist_uuid) ? this.artistByUuid(release.artist_uuid).name : 'Artist',
          },
          redirectUrl: `/drafts/${release.uuid}`,
        };
      });

      const sortedList = [...draftsReleasesList].sort((a, b) =>
        a.updated_at.localeCompare(b.updated_at)
      ).reverse();

      return sortedList;
    },
  },
  methods: {
    ...mapActions(['fetchAllReleases', 'fetchUserArtists']),

    fetchReleasesRequest () {
      this.fetchAllReleases(this.pagination.options).then((response) => {
        this.pagination.meta = response.data.meta;
      }).finally(() => {
        this.isDataLoading = false;
      });
    },
  },
  watch: {
    'pagination.options': {
      deep: true,
      immediate: true,
      handler () {
        this.fetchReleasesRequest();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.drafts{
  width: 100%;

  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__main {
    @include section;
  }
}

.drafts-loading{
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  width: 100%;
}
</style>
