<template>
  <Modal
    :heading="'Product'"
    :visibility="visibility"
    @switch="modalSwitchHandler"
  >
    <div class="offer">
      <ProductCard
        class="offer__product"
        :product="product"
      />
      <div class="offer__actions">
        <button
          class="offer__cancel"
          @click="hideModal"
        >
          Cancel
        </button>
        <RedButton
          class="offer__submit"
          @click="onSubmit"
        >
          Buy
        </RedButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { PurchaseType } from '@/utilities/purchases.js';

import Modal from './Modal.vue';
import ProductCard from './ProductCard.vue';
import RedButton from './RedButton.vue';

export default {
  props: {
    visibility: Boolean,
    product: Object,
    type: {
      type: String,
      default: PurchaseType.PRODUCT,
    },
  },
  components: {
    Modal,
    ProductCard,
    RedButton,
  },
  data () {
    return {
      PurchaseType,

      isPaymentVisible: false,
    };
  },
  methods: {
    ...mapActions([
      'createUserSubscription',
      'createUserProduct',
      'showPaymentModal',
    ]),

    modalSwitchHandler (value) {
      if (!value) this.isPaymentVisible = false;
      this.$emit('switch', value);
    },

    createUserPurchase () {
      const productName = this.product.name;
      if (this.type == PurchaseType.SUBSCRIPTION) {
        return this.createUserSubscription(productName);
      } else if (this.type == PurchaseType.PRODUCT) {
        return this.createUserProduct(productName);
      }
    },

    onSubmit () {
      this.createUserPurchase().then(() => {
        this.hideModal();
        this.showPaymentModal();
      });
    },

    hideModal () {
      this.$emit('switch', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 360px;
  margin: auto;

  &__heading {
    font-size: 1.5rem;
    margin-left: 8px;
  }

  &__product {
    width: 100%;
    padding: 8px 16px 8px 8px;
    border: 1px solid $gray;
    border-radius: 16px;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__cancel {
    background: none;
    border: none;
    color: $red;
    cursor: pointer;
    padding: 8px 16px;
  }

  &__submit {
    width: 120px;
  }
}
</style>
