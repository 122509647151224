<template>
  <section
    class="releases"
  >
    <div class="releases__statuses">
      <StatusChip
        class="releases__status"
        v-for="status in statusFilters"
        :key="status.title"
        @click="selectStatusFilter(status.key)"
        :status="status.key"
      />
    </div>
    <div class="releases__main">
      <LoadingLogo v-if="isDataLoading" />
      <Table
        v-else-if="allReleasesList?.length"
        :name="$t('all_releases')"
        :headers="tableHeaders"
        :items="allReleasesList"
      />
      <DataPlaceholder v-else
        :title="$t('no_releases_placeholder_title')"
        :description="$t('no_releases_placeholder_description')"
        :button-text="$t('no_releases_placeholder_action')"
        :button-redirect-url="'/upload'"
      />
    </div>
    <Pagination
      v-if="allReleasesList?.length"
      :options="pagination.options"
      :meta="pagination.meta"
      @options-change="(newOptions) => { pagination.options = newOptions }"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ReleaseStatus from '@/utilities/ReleaseStatus';
import StatusChip from '@/components/StatusChip';
import Table from '@/components/Table';
import Pagination from '@/components/Pagination';
import DataPlaceholder from '@/components/DataPlaceholder';
import LoadingLogo from '@/components/LoadingLogo';

import { name as tableName, headers as tableHeaders } from '@/utilities/tableStructures/allReleases.js';

export default {
  components: {
    StatusChip,
    Table,
    Pagination,
    LoadingLogo,
    DataPlaceholder,
  },
  data: function () {
    return {
      isDataLoading: true,
      tableName,
      tableHeaders,

      pagination: {
        options: {
          page: 1,
          take: 10,
          status: undefined,
        },
        meta: {},
      },
    };
  },
  computed: {
    ...mapGetters(['releases', 'artistByUuid']),

    allReleasesList: function () {
      let filteredReleases = this.releases;

      if (this.pagination.options.status) {
        filteredReleases = filteredReleases.filter((release) => (
          release.status == this.pagination.options.status
        ));
      }

      const allReleasesList = filteredReleases.map(release => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
          artist: {
            name: this.artistByUuid(release.artist_uuid) ? this.artistByUuid(release.artist_uuid).name : 'Artist',
          },
          redirectUrl: `/releases/${release.uuid}`,
          stats_button: {
            callback: () => this.$router.push(`/statistics/${release.uuid}`),
            icon: 'stats',
          },
        };
      });

      const sortedList = [...allReleasesList].sort((a, b) =>
        a.updated_at.localeCompare(b.updated_at)
      ).reverse();

      return sortedList;
    },

    statusFilters: function () {
      const statuses = Object.values(ReleaseStatus);
      return statuses.filter((status) => !(status.key === 'DRAFT'));
    },
  },
  methods: {
    ...mapActions(['fetchAllReleases', 'fetchUserArtists']),

    fetchReleasesRequest () {
      this.isDataLoading = true;
      this.fetchAllReleases(this.pagination.options).then((response) => {
        this.pagination.meta = response.data.meta;
      }).finally(() => {
        this.isDataLoading = false;
      });
    },

    selectStatusFilter (statusValue) {
      if (statusValue === 'ALL') {
        statusValue = undefined;
      }

      this.pagination.options = {
        ...this.pagination.options,
        page: 1,
        status: statusValue,
      };
    },
  },
  watch: {
    'pagination.options': {
      deep: true,
      immediate: true,
      handler () {
        this.fetchReleasesRequest();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.releases{
  width: 100%;

  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__main {
    @include section;
    display: flex;
    justify-content: center;
    overflow-x: auto;
  }

  &__statuses {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__status {
    cursor: pointer;
    transition: .15s;

    &:hover {
      transition: .15s;
      transform: scale(1.03);
      filter: brightness(0.98);
    }

    &:active {
      transition: .15s;
      transform: scale(1.03);
    }
  }
}

.releases-loading{
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  width: 100%;
}

@media (max-width: 660px) {
  .releases__statuses {
    overflow-x: auto;
  }

  .releases__status {
    margin: 2px 0;
    box-shadow: 0px 0px 2px 0px #0000001A;
  }
}
</style>
