<template>
  <div class="viewable">
    <img class="viewable__image" v-bind="$attrs" @click="isOpened = !isOpened">
    <Transition name="fade">
      <div class="viewable__viewer" v-if="isOpened" @click="closeImage">
        <img :src="$attrs.src">
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      isOpened: false,
    };
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && (() => this.isOpened)) {
        this.isOpened = false;
      }
    });
  },
  methods: {
    closeImage (e) {
      this.isOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.fade-enter-active{
  transition: all .1s ease-in-out;
  img {
    transition: all .1s ease-in-out;
  }
}
.fade-leave-active{
  transition: all .3s ease-in-out;

  img {
    transition: all .3s ease-in-out;
  }
}

.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  img {
    transition: all .3s ease-in-out;
    transform: scale(.6);
  }
}

.viewable {
  &__viewer {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($color: black, $alpha: .6);

    img {
      background: $light-gray;
      max-width: 90%;
      max-height: 90%;

      cursor: pointer;
      user-select: none;

      border-radius: 24px;
      box-shadow: 0 0 20px 0 rgba($color: black, $alpha: 0.3);
    }
  }
}

// TODO: move this style in supportdialog component and pass it through the attrs
.message__image {
  width: 160px;
  height: 90px;

  cursor: pointer;

  border-radius: 12px;

  object-fit: cover;
  outline: 2px solid transparent;
  outline-offset: 0px;
  transition: .2s;
  background: #ececec;

  &:hover {
    transition: .2s;
    outline-offset: -4px;
    outline-color: white;
  }
}
</style>
