import axios from 'axios';

const initialState = () => {
  return {
    releases: [],
    languages: [],
    musicStyles: [],

    artistRoles: {
      PRIMARY_ARTIST: 'PRIMARY_ARTIST',
      FEATURING: 'FEATURING',
      REMIXER: 'REMIXER',
      PRODUCER: 'PRODUCER',
    },
    composerRoles: {
      COMPOSER: 'COMPOSER',
      LYRICIST: 'LYRICIST',
      COMPOSER_AND_LYRICIST: 'COMPOSER_AND_LYRICIST',
    },
    
    allowedArtistRolesForInsrumental: [
      'PRIMARY_ARTIST',
      'FEATURING',
      'REMIXER'
    ],
    allowedComposerRolesForInsrumental: [
      'COMPOSER'
    ],

  };
};

const releases = {
  state: () => initialState(),
  actions: {
    // #region Releases
    createRelease: function ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('release', payload).then(response => {
          commit('addRelease', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    updateRelease: function ({ commit }, { releaseUuid, releaseData }) {
      return new Promise((resolve, reject) => {
        axios.patch(`release/${releaseUuid}`, releaseData).then(response => {
          commit('updateRelease', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    publishRelease: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.post(`release/${releaseUuid}/publish`).then(response => {
          commit('addRelease', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    unmoderateRelease: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.post(`release/${releaseUuid}/unmoderate`).then(response => {
          commit('addRelease', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchAllReleases: function ({ commit }, options) {
      return new Promise((resolve, reject) => {
        axios.get('/releases', {
          params: {
            page: options?.page ?? 1,
            take: options?.take ?? 50,
            status: options?.status ?? undefined,
          },
        }).then(response => {
          commit('setReleases', response.data.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchRelease: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/release/${releaseUuid}`).then(response => {
          commit('addRelease', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    deleteRelease: function ({ commit }, releaseUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/release/${releaseUuid}`).then(response => {
          commit('removeRelease', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // #endregion

    // #region Artists
    addArtistOnRelease: function ({ commit }, { releaseUuid, artistData }) {
      return new Promise((resolve, reject) => {
        axios.post(`/release/${releaseUuid}/add-artist`, artistData).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    removeArtistFromRelease: function ({ commit }, { releaseUuid, artistData }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/release/${releaseUuid}/remove-artist`, { data: artistData }).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    addArtistOnTrack: function ({ commit }, { trackUuid, artistData }) {
      return new Promise((resolve, reject) => {
        axios.post(`/track/${trackUuid}/add-artist`, artistData).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    removeArtistFromTrack: function ({ commit }, { trackUuid, artistData }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/track/${trackUuid}/remove-artist`, { data: artistData }).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    addComposerOnTrack: function ({ commit }, { trackUuid, composerData }) {
      return new Promise((resolve, reject) => {
        axios.post(`/track/${trackUuid}/add-composer`, composerData).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    removeComposerFromTrack: function ({ commit }, composerUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/composer/${composerUuid}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // #endregion

    // #region Tracks
    createTrack: function ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/release/${payload.releaseUuid}/add-track`, payload.trackData).then(response => {
          // commit('addTrack', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    updateTrack: function ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`track/${payload.trackUuid}`, payload.trackData).then(response => {
          // commit('updateTrack', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    deleteTrack: function ({ commit }, track) {
      return new Promise((resolve, reject) => {
        axios.delete(`/track/${track.uuid}`).then(response => {
          // commit('removeTrack', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // #endregion

    // #region Enums

    fetchLanguages: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/languages').then(response => {
          commit('setLanguages', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchMusicStyles: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/music-styles').then(response => {
          commit('setMusicStyles', response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // #endregion
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },

    // #region Releases
    setReleases: function (state, releases) {
      state.releases = releases;
    },
    addRelease: function (state, release) {
      state.releases.push(release);
    },
    updateRelease: function (state, updatedRelease) {
      const index = state.releases.findIndex((release) => release.uuid == updatedRelease.uuid);
      state.releases[index] = updatedRelease;
    },
    removeRelease: function (state, deletedRelease) {
      state.releases = state.releases.filter((release) => release.uuid !== deletedRelease.uuid);
    },
    // #endregion

    // #region Enums
    setLanguages: function (state, languages) {
      state.languages = languages;
    },
    setMusicStyles: function (state, styles) {
      state.musicStyles = styles;
    },
    // #endregion
  },
  getters: {
    // #region Releases
    releases: state => state.releases,

    release: state => releaseUuid => {
      return state.releases.find((release) => release.uuid == releaseUuid);
    },

    deniedReleases: state => {
      return state.releases.filter(release => release.status == 'DENIED');
    },

    publishedReleases: state => {
      return state.releases.filter(release => release.status == 'PUBLISHED');
    },

    moderatedReleases: state => {
      return state.releases.filter(release => release.status == 'MODERATED');
    },

    draftReleases: state => {
      return state.releases.filter(release => release.status == 'DRAFT');
    },

    notDraftReleases: state => {
      return state.releases.filter(release => release.status !== 'DRAFT');
    },
    // #endregion

    // #region Enums
    languages: state => state.languages,
    artistRoles: state => state.artistRoles,
    composerRoles: state => state.composerRoles,

    allowedArtistRolesForInsrumental: state => state.allowedArtistRolesForInsrumental,
    allowedComposerRolesForInsrumental: state => state.allowedComposerRolesForInsrumental,

    primaryStyles: state => {
      return state.musicStyles.filter((s) => !s.parentId);
    },

    primaryStyle: (state, getters) => id => {
      return getters.primaryStyles.find((s) => s.musicStyleId == id);
    },

    secondaryStyles: (state, getters) => primaryStyleId => {
      const secondaryStyles = getters.primaryStyle(primaryStyleId)?.childs;

      if (!secondaryStyles) return [];

      return secondaryStyles;
    },

    secondaryStyle: (state) => id => {
      const secondaryStyles = state.musicStyles.reduce((styles, s) => [...styles, ...s.childs], []);
      return secondaryStyles.find((s) => s.musicStyleId == id);
    },

    language: state => id => {
      return state.languages.find((s) => s.languageId == id);
    },
    musicStyle: state => id => {
      return state.primaryStyles.find((s) => s.musicStyleId == id);
    },
    // #endregion
  },
};

export default releases;
