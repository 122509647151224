<template>
  <div class="section">
    <div class="section__header">
      <h1 class="section__heading">
        {{ $t('artists') }}
      </h1>
      <button class="section__action" @click="editingArtist = {}" v-if="!isDataLoading">
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#plus-translucent'}" />
        </svg>
        <span>
          {{ $t('new_artist') }}
        </span>
      </button>
    </div>
    <LoadingLogo
      class="section__loader"
      v-if="isDataLoading"
    />
    <div class="section__list" v-else-if="artists.length > 0">
      <ArtistCard
        v-for="artist in sortedArtists"
        :key="artist.uuid"
        :artist="artist"
        @edit="editingArtist = artist"
      />
    </div>
    <DataPlaceholder v-else
      :title="$t('no_artists_placeholder_title')"
      :description="$t('no_artists_placeholder_description')"
      :button-text="$t('no_artists_placeholder_action')"
      @action="editingArtist = {}"
    />
    <Modal
      :visibility="editingArtist !== null"
      :heading="editingArtist?.uuid ? $t('artist_form_edit_artist') : $t('artist_form_create_new_artist')"
      @switch="editingArtist = null"
    >
      <ArtistForm :artist="editingArtist" @submit="editingArtist = null" />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ArtistCard from '../components/ArtistCard.vue';
import Modal from '../components/Modal.vue';
import ArtistForm from '../components/ArtistForm.vue';
import LoadingLogo from '../components/LoadingLogo.vue';
import DataPlaceholder from '../components/DataPlaceholder.vue';

export default {
  components: {
    ArtistCard,
    Modal,
    ArtistForm,
    LoadingLogo,
    DataPlaceholder,
  },
  data () {
    return {
      isDataLoading: true,
      editingArtist: null,
    };
  },
  methods: {
    ...mapActions([
      'fetchUserArtists',
      'createArtist',
      'patchArtist',
      'deleteArtist',
      'uploadArtistAvatar',
    ]),
  },
  computed: {
    ...mapGetters(['artists']),

    sortedArtists () {
      const sortedArtists = [...this.artists].sort((a, b) =>
        a.updated_at?.localeCompare(b.updated_at)
      ).reverse();
      return sortedArtists;
    },
  },
  mounted () {
    this.fetchUserArtists().finally(() => {
      this.isDataLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.section {
  @include section;
  width: 100%;
  height: min-content;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  &__heading {
    font-size: 1.188rem;
    font-weight: 400;
    line-height: 19px;
  }

  &__action {
    background: none;
    border: none;
    cursor: pointer;
    color: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%;
    transition: .2s;

    svg {
      width: 12px;
      height: 12px;
      fill: $red;
    }

    &:hover {
      transform: scale(1.04);
      transition: .2s;
    }
  }

  &__loader {
    width: 100%;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 8px;
  }
}

@media (max-width: 660px) {
  .section {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
</style>
