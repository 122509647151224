<template>
  <Transition :name="isAppDidRouterNavigations ? 'loader-fade-in-out' : 'loader-fade-out'">
    <div class="loader" v-if="isAppLoading">
      <LoadingLogo />
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingLogo from '@/components/LoadingLogo.vue';

export default {
  components: {
    LoadingLogo,
  },
  computed: {
    ...mapGetters(['isAppLoading', 'isAppDidRouterNavigations']),
  },
};
</script>

<style lang="scss" scoped>
.loader-fade-out-leave-active{
  transition: opacity .7s ease-in-out;
}

.loader-fade-in-out-enter-active{
  transition: opacity .5s ease-in-out;
}
.loader-fade-in-out-leave-active{
  transition: opacity .7s ease-in-out;
}

.loader-fade-out-enter-from,
.loader-fade-out-leave-to,
.loader-fade-in-out-enter-from,
.loader-fade-in-out-leave-to{
  opacity: 0;
}

.loader {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background: white;

  // background: rgba($color: black, $alpha: .91);
  // backdrop-filter: blur(2px);
}
</style>
