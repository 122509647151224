<template>
  <div class="pagination">
      <div class="pagination__spacer" />
      <div class="pagination__controls">
        <button
          class="pagination__arrow pagination__arrow--left"
          @click="setPage(options.page - 1)"
          :disabled="options.page <= 1"
        >
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
          </svg>
        </button>
        <button
          v-show="options?.page > 20 + maxPageOffset "
          @click="setPage(options?.page - 20)"
          :class="['pagination__number', 'pagination__number__peref', {'pagination__number_selected': options?.page === 1}]"
        >
          {{ options?.page - 20 }}
        </button>
        <div class="pagination__numbers">
          <button
            v-for="number in pageNumbers"
            :key="number"
            @click="setPage(number)"
            :class="['pagination__number', (options?.page === number && 'pagination__number_selected')]"
            :disabled="number == options?.page"
          >
            {{ number }}
          </button>
        </div>
        <button
          v-show="options?.page + 20 < meta?.pageCount"
          @click="setPage(options?.page + 20)"
          :class="['pagination__number', 'pagination__number__peref', {'pagination__number_selected': options?.page === 1}]"
        >
          {{ options?.page + 20 }}
        </button>
        <button
          class="pagination__arrow"
          @click="setPage(options.page + 1)"
          :disabled="options.page >= meta.pageCount"
        >
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
          </svg>
        </button>
      </div>
      <div class="pagination__take">
        <select
          :value="options?.take"
          @change="(event) => setTake(parseInt(event.target.value))"
        >
          <option
            v-for="option in takeOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>

    </div>
</template>

<script>
export default {
  props: {
    options: Object,
    meta: Object,
  },
  data () {
    return {
      maxPageOffset: 5,
      takeOptions: [
        5,
        10,
        20,
        30,
        40,
        50,
      ],
    };
  },
  methods: {
    updateOptions (newOptions) {
      this.$emit('options-change', newOptions);
    },
    setPage (page) {
      this.updateOptions({ ...this.options, page });
    },
    setTake (take) {
      this.updateOptions({ ...this.options, take });
    },
  },
  computed: {
    pageNumbers () {
      let offset = this.maxPageOffset - this.options.page <= 0 ? Math.abs(this.maxPageOffset - this.options.page) : 0
      const numbers = Array.from({ length: 10 }, (_, i) => i + 1 + offset);
      return numbers;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: white;
  border-radius: 16px;
  padding: 0 16px;
  box-shadow: $sections_shadow;

  & > * {
    flex: 1;
  }

  // &__spacer {}

  &__controls {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  &__arrow {
    border: none;
    padding: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;

    svg {
      height: 13px;
      width: 13px;
    }

    &--left {
      transform: rotate(180deg);
    }
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &number__peref{
    gap: 4px
  }

  &__number {
    cursor: pointer;
    padding: 0 8px;
    background: none;
    border: none;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;

    &_selected {
      color: $red;
    }
  }

  &__take {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    select {
      cursor: pointer;
      background: none;
      border: none;
      color: $red;
      font-size: 1.125rem;
      line-height: 1;
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
