<template>
  <div class="order-page">
    <Order :uuid="uuid" :type="orderType" />
</div>
</template>

<script>
import Order from '../components/Order.vue';

export default {
  components: { Order },
  computed: {
    uuid () {
      const { uuid } = this.$route.params;
      return uuid;
    },
    orderType () {
      const { orderType } = this.$route.query;
      return orderType;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.order-page {
  height: max-content;
  width: 100%;
  border-radius: $sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
}
</style>
