const name = 'Releases';

const headers = [
  {
    field: 'cover_uuid',
    isImage: true,
  },
  {
    field: 'title',
    itemCellsAlign: 'left',
  },
  {
    field: 'artist.name',
    title: 'Artist',
    localizer: 'artist',
    isSortable: true,
    itemCellsAlign: 'center',
  },
  {
    field: 'release_date',
    title: 'Date',
    localizer: 'date',
    itemCellsAlign: 'center',
    isSortable: true,
  },
  {
    field: 'streams',
    title: 'Streams',
    localizer: 'streams',
    itemCellsAlign: 'center',
    isSortable: true,
  },
  {
    field: 'stats_button',
    isButton: true,
  },
];

export { name, headers };
