<template>
  <header class="header">
    <div class="container">
      <div class="header__inner-wrapper row">
        <div class="header__logo">
          <router-link :to="{name:'Main'}">
            <img id="logo" src="@/assets/Logo.svg" alt="">
          </router-link>
        </div>
        <div class="header__navbar navbar">
          <ul class="navbar__items">
            <li
              v-for="page in pages"
              :key="page.routeName"
              :class="['navbar__item', { 'navbar__item-active':$route.name == page.routeName }]"
            >
              <router-link :to="{ name: page.routeName }">
                {{ page.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="header__actions">
          <DropdownNew
            class="header__language"
            :options="dropdownLanguages"
            :initial-value="currentLocale"
            @select="changeLocale"
          />
          <button
            :class="['header__menu-button', 'menu-button', isMenuOpen ? 'menu-button--active' : '']"
            @click="toggleMenu"
          >
            <div class="menu-button__line" v-for="n of 3" :key="n" />
          </button>
        </div>
      </div>
    </div>
    <Transition name="menu">
      <div class="header__menu menu" v-if="isMenuOpen">
        <div class="menu__user">
          <DashboardProfile :user="user" class="menu__profile" />
          <LogoutButton class="menu__logout" />
        </div>
        <div class="menu__navigation navigation">
          <div class="navigation__items">
            <button
              v-for="page in getPagesByGroup(NavigationGroup.SERVICE)"
              :key="page.routeName"
              :class="['navigation__item', { 'navigation__item--active': $route.name == page.routeName }]"
              @click="navigationItemClick(page)"
            >
              {{ page.title }}
            </button>
          </div>
          <div class="navigation__items navigation__items--right">
            <button
              v-for="page in getPagesByGroup(NavigationGroup.RELEASES)"
              :key="page.routeName"
              :class="['navigation__item', { 'navigation__item--active': $route.name == page.routeName }]"
              @click="navigationItemClick(page)"
            >
              {{ page.title }}
            </button>
          </div>
        </div>
        <UploadButton @click="uploadButtonClick" />
      </div>
    </Transition>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardProfile from '../components/DashboardProfile.vue';
import UploadButton from '../components/UploadButton.vue';
import LogoutButton from '../components/LogoutButton.vue';
import DropdownNew from '../components/DropdownNew.vue';

const NavigationGroup = {
  SERVICE: 'Service',
  RELEASES: 'Releases',
};

export default {
  components: {
    DashboardProfile,
    UploadButton,
    LogoutButton,
    DropdownNew,
  },
  data () {
    return {
      NavigationGroup,
      isMenuOpen: false,
    };
  },
  mounted () {
    this.fetchUserData().then((response) => {
      const avatarFile = this.file(response.data.avatar_url);
      if (avatarFile) {
        this.fetchFile(this.user.avatar_url);
      }
    });
  },
  methods: {
    ...mapActions(['fetchUserData', 'fetchFile']),

    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen;
    },

    getPagesByGroup (group) {
      return this.pages.filter((page) => page.group === group);
    },

    navigationItemClick (page) {
      this.$router.push({ name: page.routeName });
      this.isMenuOpen = false;
    },

    uploadButtonClick () {
      this.isMenuOpen = false;
    },

    changeLocale (locale) {
      const localeId = Object.keys(this.locales).find((key) => this.locales[key] === locale);
      this.$i18n.locale = localeId;
      localStorage.setItem('locale', localeId);
    },
  },
  computed: {
    ...mapGetters(['file', 'user']),

    currentLocale () {
      return this.locales[this.$i18n.locale];
    },

    dropdownLanguages () {
      return Object.values(this.locales);
    },

    locales () {
      return {
        en: this.$t('english'),
        ru: this.$t('russian'),
      };
    },

    pages () {
      return [
        {
          routeName: 'Main',
          title: this.$t('main_page'),
          group: NavigationGroup.SERVICE,
        },
        {
          routeName: 'Statistics',
          title: this.$t('statistics'),
          group: NavigationGroup.SERVICE,
        },
        {
          routeName: 'All Releases',
          title: this.$t('all_releases'),
          group: NavigationGroup.RELEASES,
        },
        {
          routeName: 'Drafts',
          title: this.$t('drafts'),
          group: NavigationGroup.RELEASES,
        },
        {
          routeName: 'Artists',
          title: this.$t('artists'),
          group: NavigationGroup.SERVICE,
        },
        // {
        //   routeName: 'Support',
        //   title: this.$t('support'),
        //   group: NavigationGroup.SERVICE,
        // },
        // {
        //   routeName: 'Purchases',
        //   title: this.$t('purchases'),
        //   group: NavigationGroup.SERVICE,
        // },
      ];
    },
  },
  watch: {
    $route () {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

/*--header action sizes--*/
$header-action-height: 23px;
$header-action-width: 90px;

@mixin menu-block {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #DADADA;
}

.menu-enter-active, .menu-leave-active {
  transition: all .3s ease;
  overflow: hidden;
}
.menu-enter-from, .menu-leave-to {
  max-height: 0px;
}

.menu-enter-to, .menu-leave-from {
  max-height: calc(100vh - var(--header-height));
}

.header {
  --logo-width: 130px;
  --header-height: 62px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  min-height: 62px;
  padding: 0 32px;

  border-bottom: 1px solid $dark-gray;
  background: $light-gray;

  &__inner-wrapper{
    flex-wrap: nowrap;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: var(--logo-width) 1fr calc(var(--logo-width) - 16px);

    width: 100%;
    height: 100%;
  }

  &__logo {
    padding-right: 16px;
  }

  &__language {
    max-width: var(--logo-width);
  }

  &__actions-item {
    margin-right: 13px;

    div {
      padding: 4px 10px;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: var(--logo-width);
    margin-right: 0 !important;
  }

  &__menu {
    z-index: 100;
    height: calc(100vh - var(--header-height));
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    margin-top: 1px;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $dark-gray;
  background: $light-gray;
  padding: 16px 32px;

  &__user {
    @include menu-block;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // &__profile {}

  &__navigation {
    @include menu-block;
  }
}

.navigation {
  display: flex;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;

    &--right {
      align-items: flex-end;
    }
  }

  &__item {
    width: max-content;
    cursor: pointer;
    font-size: 1.125rem;
    white-space: nowrap;
    color:$black;
    padding: 0;
    background: none;
    border: none;

    &--active{
      position: relative;

      font-weight: 500;

      &::after{
        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: 2px;

        content: '';

        background: $red;
      }
    }
  }
}

.navbar{
  display: flex;
  align-items: center;
  justify-content: center;

  &__items{
    display: flex;
    align-items: center;
    gap: 24px;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item{
    display: flex;
    align-items: center;
    justify-content: center;

    padding: auto;

    cursor: pointer;

    font-size: 1.125rem;
    line-height: normal;

    white-space: nowrap;

    a {
      user-select: none;
      text-decoration: none;

      color:$black;

      line-height: normal;

      &:hover{
        font-weight: 500;
      }
    }

    &-active{
      position: relative;

      font-weight: 500;

      &::after{
        position: absolute;
        top: -11px;
        left: calc(50% - 3px);

        width: 6px;
        height: 6px;

        content: '';

        border-radius: 3px;
        background: $red;
      }
    }
  }
}

.menu-button {
  display: flex;
  flex-direction: column;
  gap: 3px;
  background: none;
  border: none;
  transition: .2s;

  &__line {
    transition: .2s;

    width: 22px;
    height: 3px;

    border-radius: 100px;
    background: #B1B1B1;
  }

  &--active {
    transition: .2s;

    gap: 0px;

    .menu-button__line {
      transition: .2s;
      height: 1px;
    }
  }
}

#logo{
  max-width: var(--logo-width);
  min-width: var(--logo-width);
  width: 100%;
}

@media (max-width: 975px) {
  .header {
    --logo-width: 100px;

    &__navbar, &__spacer {
      display: none;
    }

    &__inner-wrapper{
      grid-template-columns: var(--logo-width) var(--logo-width);
      justify-content: space-between;
    }
  }
  .navbar__items {
    gap: 16px;
  }
  .navbar__item {
    font-size: 1rem;
  }
}

@media (min-width: 976px) {
  .header__menu-button, .header__menu {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1090px) {
  .navbar__items {
    gap: 24px;
  }
  .navbar__item {
    font-size: 1rem;
  }
}

@media (max-width: 1240px) {
  .header {
    &__inner-wrapper{
      display: flex;
      justify-content: space-between;
    }

    &__actions {
      width: initial;
    }
  }
}

@media (min-width: 1091px) and (max-width: 1366px) {
  .navbar__items {
    gap: 24px;
  }
}
</style>
