import { createStore } from 'vuex';

// vuex modules
import app from './modules/app';
import auth from './modules/auth';
import user from './modules/user';
import releases from './modules/releases';
import support from './modules/support';
import artists from './modules/artists';
import files from './modules/files';
import notifications from './modules/notifications';
import statistics from './modules/statistics';
import payments from './modules/payments';
import transfering from './modules/transfering';
import survey from './modules/survey'
import modals from './modules/modals'

const store = createStore({
  modules: {
    app,
    auth,
    user,
    releases,
    support,
    artists,
    files,
    notifications,
    statistics,
    payments,
    transfering,
    survey,
    modals,
  },
  state () {
    return {};
  },
  actions: {
    resetAllModuleStates ({ commit }) {
      commit('resetState');
    },
  },
  mutations: {},
  getters: {
    
  },
});

export default store;
