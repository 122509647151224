<template>
  <section class="welcome__login-methods welcome__section">
    <div class="welcome__login-methods-header__wrapper welcome__login-methods-item">
      <!-- <h3 class="welcome__login-methods-header">
        Verified
      </h3> -->
      <span
        class="welcome__login-methods-link"
        @click="$router.replace('/signin')"
      >
        <svg style="transform:rotateZ(180deg); margin-right:10px;">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow-right'}" />
        </svg>
        Back to Log in
      </span>
    </div>
    <!-- <div
      class="welcome__login-errors"
      v-if="errors"
    >
      {{ errors }}
    </div> -->
    <p class="welcome__verified" v-if="errors">
      Your account has <span>not</span> been verified due to a technical <span>error</span>. <span>Please, try again!</span>
    </p>
    <p class="welcome__verified" v-else-if="!verifyLoading">
      Your <span>account</span> has been successfully <span>verified</span> <img
        src="@/assets/confettiEmoji.png"
        alt="Confetti Emoji"
      >
    </p>
    <p class="welcome__verified welcome__verified--loading" v-else>
      <span>Verifying</span>
      <LoadingLogo class="welcome__loading" />
    </p>
  </section>
</template>

<script>
import LoadingLogo from '@/components/LoadingLogo.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    LoadingLogo,
  },
  data () {
    return {
      errors: '',
      verifyLoading: true,
      verifyEmailData: {
        token: '',
      },
    };
  },
  methods: {
    ...mapActions(['verifyEmail', 'refreshTokens']),

    verifyEmailRequest: function () {
      this.verifyLoading = true;
      this.verifyEmail(this.verifyEmailData).then((response) => {
        console.log(response);
        this.refreshTokens();
      }).catch(({ response }) => {
        console.log(response);
        this.errors = 'Произошла ошибка';
      }).finally(() => {
        this.verifyLoading = false;
      });
    },
  },
  mounted () {
    this.verifyEmailData.token = this.$route.params.token;
    this.verifyEmailRequest();
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/_shared.scss';

    .welcome__section{
        padding: 25px 35px;
        margin-bottom: 20px;
        box-sizing: border-box;
        width: 388px;
        background: $white;
        box-shadow: $sections_shadow;
        border-radius: $sections_border_radius;
        height: min-content;

        &:last-child{
            margin: 0;
        }
    }

    .welcome__login-methods-header__wrapper{
        display: flex;
        justify-content: space-between;
    }

    .welcome__login-methods-header{
        margin:0;
        font-size: 1.5rem;

        span{
            color:$text-color;
            font-weight: 400;
        }
    }

    .welcome__login-methods-link{
        display: flex;
        align-items: center;
        color:$red;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        svg{
            width: 10px;
            height: 10px;
            fill:$red;
        }

        &:hover{
            border-bottom: 1px solid $red;
        }
    }

    .welcome__login-form__row{
        display: flex;

        .welcome__login-form-input{
            margin-right: 10px;

            &:last-child{
                margin:0;
            }
        }
    }

    .welcome__login-form-input{
        margin-bottom: 10px;

        &:last-child{
            margin: 0;
        }
    }

    .welcome__switch{
        width: 1px;
        height: 1px;
        position:relative;
        margin-right: 40px;
        cursor: pointer;
        outline: none;

        &:focus::before{
            border: 1px solid $text-color;
        }

        &::before{
            content: '';
            position: absolute;
            width: 40px;
            height: 21px;
            border-radius: 18px;
            background: $dark-gray;
            top:0px;
            left:0px;
            transition: .2s;
            border:1px solid transparent;
        }

        &:after{
            content:'';
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 10px;
            background: $white;
            top:4px;
            left:4px;
            transition: .2s;
        }

        &:checked::before{
            background: $red;
        }

        &:checked::after{
            transform: translateX(calc(40px - 18px - 3px));
        }
    }

    .welcome__login-switch__btn{
        padding: 3px 0px;
        cursor:pointer;
        background: transparent;
        border:none;
        color:$black;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;

        svg{
            margin-left:5px;
            width: 12px;
            height: 12px;
        }

        &:hover{
            border-bottom: 1px solid $black;
        }
    }

    .welcome__login-next-btn{
        padding: 0;
        box-sizing: border-box;
        background: $red;
        border:none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        flex-basis: 40px;
        border-radius: 10px;
        cursor: pointer;

        svg{
            width: 15px;
            height: 15px;
            fill:white;
        }

        &:enabled:hover{
            background: #ff1216;
        }

        &:disabled{
            background: $gray;
            cursor: not-allowed;
            svg{
                fill:$dark-gray;
            }
        }
    }

    .welcome__login-errors{
        padding: 5px 10px;
        background: $red;
        color:white;
        border-radius: 5px;

        margin: 10px 0;
    }

    .welcome__verified{
      text-align: center;
      font-size: 1.25rem;
      margin: 20px 0 15px 0;

      &--loading{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 25px 0 15px 0;
      }

      .welcome__loading{
        margin-top: 5px;
        width: 40px;
        height: 40px;
        stroke-width: 5px;
      }

      img{
        width: 1.3ch;
        transform: translateY(-.2ch);
      }

      span{
        color: $red;
      }
    }
</style>
