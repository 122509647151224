<template>
  <section :class="['placeholder', (minimized && 'placeholder--minimized')]">
    <div class="placeholder__logo">
      <svg>
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#mylabel-symbol'}" />
      </svg>
    </div>
    <div class="placeholder__title">
      {{ title }}
    </div>
    <div class="placeholder__description">
      {{ description }}
    </div>
    <SquareButton class="placeholder__button" @click="actionButtonHandler">
      {{ buttonText || this.$t('back_to_main') }}
    </SquareButton>
  </section>
</template>

<script>
import SquareButton from './SquareButton.vue';

export default {
  name: 'DataPlaceholder',
  components: {
    SquareButton,
  },
  props: {
    title: {
      type: String,
      default: 'Oops...', // !TODO: replace with $t('oops')
    },
    description: {
      type: String,
      default: 'Something seems to be broken.', // !TODO: replace with $t('something_broke')
    },
    buttonText: {
      type: String,
    },
    buttonRedirectUrl: {
      type: String,
    },
    minimized: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    actionButtonHandler () {
      this.$emit('action');

      const url = this.buttonRedirectUrl;
      if (url) {
        this.navigateTo(url);
      }
    },
    navigateTo (url) {
      if (url.length) {
        this.$router.replace(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  width: 100%;
  padding: 30px 20px;

  &__logo {
    margin-bottom: 32px;

    svg {
      width: 43px;
      height: 45px;
    }
  }

  &__title {
    margin-bottom: 8px;

    text-align: center;

    font-size: 1.5rem;
    font-weight: 700;
  }

  &__description {
    max-width: 60%;
    margin-bottom: 24px;

    text-align: center;

    color: #CECECE;

    font-size: 1.125rem;
    font-weight: 500;
  }

  &__button {
    width: 100%;
    max-width: 278px;
    height: 52px;
  }

  &--minimized {
    .placeholder__logo {
      margin-bottom: 16px;
    }
    .placeholder__title {
      font-size: 1.25rem;
      margin-bottom: 8px;
    }
    .placeholder__description {
      font-size: 1.1rem;
    }
    .placeholder__button {
      font-size: 1.1rem;
      height: 44px;
    }
  }
}

</style>
