<template>
  <textarea
    class="textarea"
    ref="textarea"
    :value="modelValue"
    @input="onInput($event)"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    error: Boolean,
  },
  methods: {
    onInput (event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
  watch: {
    modelValue () {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = event.target.scrollHeight + 2 + 'px';
    },
  },
};
</script>
