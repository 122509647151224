<template>
  <button class="white-button">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
    @import '@/assets/_shared.scss';

    .white-button{
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        box-shadow: 0 0 20px 0 rgba($color: black, $alpha: .1);
        padding: 10px 20px;
        background: $white;
        color: $black;
        border: none;
        border-radius: $button_border-radius;
        text-transform: capitalize;
        cursor: pointer;
        line-height: normal;
        text-align: center;

        &:enabled:hover{
            font-weight: 600;
        }

        &:disabled{
            background: $gray;
            color:$dark-gray;
            cursor: not-allowed;
        }
    }
</style>
