<template>
  <div class="page">
    <section class="section">
      <LoadingLogo v-if="isDataLoading" class="section__loader" />
      <div class="section__wrapper" v-else>
        <div class="section__header header">
          <div class="header__left">
            <h1 class="section__heading">
              {{ $t('general_statistics') }}
            </h1>
          </div>
          <div class="header__right">
            <DropdownNew
              class="section__dropdown"
              :options="settings.periodOptions"
              :initial-value="'Month'"
              @select="periodSelectHandler"
            />
          </div>
        </div>
        <div class="section__statistics">
          <div class="section__block" v-if="statistics.length">
            <div class="section__linechart">
              <LineChart
                v-if="statistics.length"
                class="chart"
                :chart-data="lineConfig.data"
                :options="lineConfig.options"
              />
            </div>
          </div>
          <div class="section__block">
            <h2 class="section__heading block__heading" v-if="statistics.length">
              Streams and stores
            </h2>
            <div class="section__stores stores">
              <div class="stores__piechart">
                <div class="stores__total">
                  <p>
                    {{ totalStreams }}<br>
                    <span>streams</span>
                  </p>
                </div>
                <DoughnutChart
                  class="chart"
                  :chart-data="pieConfig.data"
                  :options="pieConfig.options"
                />
              </div>
              <div class="section__list list list--stores">
                <div class="list__item" :style="`--color: ${store.color}`" v-for="store in storesSortedByStreams" :key="store.key">
                  <p>
                    {{ store.name }}
                    <span v-if="storeStreams(store.key) > 0">- {{ storeStreamsPercentage(store.key) }}% ({{ storeStreams(store.key) }})</span>
                    <span v-else>- 0</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        :visibility="isCustomPeriodModal"
        :heading="'Choose a custom date'"
        @switch="(value) => isCustomPeriodModal = value"
        class="period-modal"
      >
        <div class="period-modal__main">
          <div class="period-modal__inputs">
            <div class="period-modal__row">
              <p class="period-modal__label">
                From
              </p>
              <DatePick
                class="period-modal__input"
                @input="e => settings.customPeriod.start_date = new Date(e.target.value)"
              />
            </div>
            <div class="period-modal__row">
              <p class="period-modal__label">
                To
              </p>
              <DatePick
                class="period-modal__input"
                @input="e => settings.customPeriod.end_date = new Date(e.target.value)"
              />
            </div>
          </div>
          <RedButton class="period-modal__submit" @click="saveCustomPeriod">
            Show statistics
          </RedButton>
        </div>
      </Modal>
    </section>
    <section class="releases" v-if="!isReleasesLoading">
      <Table
        v-if="allReleasesList?.length"
        :name="$t('statistics')"
        :headers="tableHeaders"
        :items="allReleasesList"
      />
      <DataPlaceholder v-else
        :title="$t('no_releases_placeholder_title')"
        :description="$t('no_releases_placeholder_description')"
        :button-text="$t('no_releases_placeholder_action')"
        :button-redirect-url="'/upload'"
      />
    </section>
    <Pagination
      v-if="allReleasesList?.length"
      :options="pagination.options"
      :meta="pagination.meta"
      @options-change="(newOptions) => pagination.options = newOptions"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Table from '@/components/Table.vue';
import DataPlaceholder from '@/components/DataPlaceholder.vue';
import LoadingLogo from '@/components/LoadingLogo.vue';
import DropdownNew from '../components/DropdownNew.vue';
import Modal from '../components/Modal.vue';
import { Chart, registerables } from 'chart.js';
import { LineChart, DoughnutChart } from 'vue-chart-3';
import DatePick from '../components/DatePick.vue';
import RedButton from '../components/RedButton.vue';
import Pagination from '../components/Pagination.vue';

import { name as tableName, headers as tableHeaders } from '@/utilities/tableStructures/statReleases.js';

Chart.register(...registerables);

export default {
  components: {
    Table,
    LoadingLogo,
    DataPlaceholder,
    Modal,
    LineChart,
    DoughnutChart,
    DropdownNew,
    DatePick,
    RedButton,
    Pagination,
  },
  data: function () {
    return {
      isReleasesLoading: true,
      tableName,
      tableHeaders,

      isDataLoading: false,
      isCustomPeriodModal: false,

      settings: {
        periodOptions: ['Week', 'Month', 'Quarter', 'Year', 'Custom'],
        selectedPeriod: 'Month',
        customPeriod: {
          start_date: null,
          end_date: null,
        },
      },

      pagination: {
        options: {
          page: 1,
          take: 10,
        },
        meta: {},
      },

      stores: [
        {
          key: 'spotify',
          name: 'Spotify',
          color: '#64D561',
        },
        {
          key: 'apple_music',
          name: 'Apple',
          color: '#FB223C',
        },
        {
          key: 'youtube',
          name: 'YouTube',
          color: '#FF0000',
        },
        {
          key: 'vkontakte',
          name: 'VKontakte',
          color: '#0078FF',
        },
        {
          key: 'boom',
          name: 'BOOM',
          color: '#FE2958',
        },
        {
          key: 'deezer',
          name: 'Deezer',
          color: '#EF9F1A',
        },
        {
          key: 'itunes',
          name: 'iTunes',
          color: '#E049D4',
        },
        {
          key: 'saavn',
          name: 'Saavn',
          color: '#30B55C',
        },
      ],
    };
  },
  mounted () {
    this.fetchUserArtists();
    this.fetchStatistics().then(() => {
      this.isReleasesLoading = false;
    });
  },
  computed: {
    ...mapGetters([
      'notDraftReleases',
      'artistByUuid',
      'releaseStatistics',
      'releaseTotalStreams',
      'streamsByStores',
      'storeNames',
      'storeColors',
      'totalStreams',
      'streamsByDays',
      'statistics',
      'storeStreams',
      'storeStreamsPercentage',
    ]),

    allReleasesList: function () {
      const allReleasesList = this.statistics.map(release => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
          artist: {
            name: this.artistByUuid(release.artist_uuid) ? this.artistByUuid(release.artist_uuid).name : 'Artist',
          },
          streams: this.releaseTotalStreams(release.uuid) ?? 0, // TODO: implement when api will be done
          stats_button: {
            callback: () => this.$router.push(`/statistics/${release.uuid}`),
            icon: 'stats',
          },
        };
      });

      const sortedList = [...allReleasesList].sort((a, b) =>
        b.streams - a.streams
      );

      return sortedList;
    },

    period () {
      let start_date = new Date();
      let end_date = new Date();

      const day = start_date.getDay();
      const date = start_date.getDate();
      const month = start_date.getMonth();
      const year = start_date.getFullYear();
      const quarter = this.getQuarterPeriod(start_date);

      switch (this.settings.selectedPeriod) {
      case 'Week':
        start_date.setDate(date - 7);
        break;
      case 'Month':
        start_date = new Date(year, month - 1, date);
        break;
      case 'Quarter':
        start_date = quarter.start_date;
        end_date = quarter.end_date;
        break;
      case 'Year':
        start_date = new Date(year - 1, month, date);
        break;
      case 'Custom':
        start_date = this.settings.customPeriod.start_date;
        end_date = this.settings.customPeriod.end_date;
        break;
      }

      return { start_date, end_date };
    },

    storesSortedByStreams () {
      const sortedStores = [...this.stores].sort((a, b) => {
        const aStreams = this.storeStreams(a.key);
        const bStreams = this.storeStreams(b.key);

        return bStreams - aStreams;
      });

      return sortedStores;
    },

    lineConfig () {
      const streams = this.streamsByDays;
      const labels = streams.map((day) => this.getFormattedDate(day.date));
      const datapoints = streams.map((day) => day.streams);

      const lineData = {
        labels,
        datasets: [
          {
            data: datapoints,
            responsive: true,
            borderColor: '#f32424',
            fill: true,
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            // backgroundColor: (context) => {
            //   const { ctx } = context.chart;
            //   const gradient = ctx.createLinearGradient(0, 0, 0, 170);
            //   gradient.addColorStop(0, 'rgb(243, 36, 36, .4)');
            //   gradient.addColorStop(0.8, 'rgb(243, 36, 36, .1)');
            //   gradient.addColorStop(1, 'rgb(255, 255, 255, .1)');
            //   return gradient;
            // },
          },
        ],
      };

      const lineConfig = {
        type: 'line',
        data: lineData,
        options: {
          tension: 0.5,
          responsive: true,
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return `${value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value}`;
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              backgroundColor: 'white',
              titleColor: '#f32424',
              bodyColor: '#f32424',
              bodyFont: {
                weight: '500',
                size: 14,
              },
              callbacks: {
                title: (tooltipItems) => {
                  return tooltipItems.yLabel;
                },
              },
            },
          },
        },
      };

      return lineConfig;
    },

    pieConfig () {
      const datapoints = this.streamsByStores;
      const labels = this.storeNames;
      const colors = this.storeColors;

      const pieData = {
        labels,
        datasets: [{
          data: datapoints,
          backgroundColor: colors,
          hoverOffset: 4,
        }],
      };

      const pieConfig = {
        type: 'doughnut',
        data: pieData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          borderRadius: 4,
          borderWidth: 2,
          hoverBorderWidth: 0,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      };

      return pieConfig;
    },
  },
  methods: {
    ...mapActions([
      'fetchAllReleases',
      'fetchUserArtists',
      'fetchCommonStatistics',
    ]),

    saveCustomPeriod () {
      if (this.settings.customPeriod.start_date && this.settings.customPeriod.end_date) {
        this.settings.selectedPeriod = 'Custom';
      }

      this.isCustomPeriodModal = false;
    },

    periodSelectHandler (option) {
      if (option == 'Custom') {
        this.isCustomPeriodModal = true;
      } else {
        this.settings.selectedPeriod = option;
      }
    },

    getRandomColor () {
      const colors = [
        '#FF1E8A',
        '#64D561',
        '#64BCED',
        '#F21818',
      ];
      const index = parseInt(Math.random() * colors.length);
      return colors[index];
    },

    getFormattedDate (dateString) {
      const date = new Date(dateString);
      const options = { month: 'short', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    },

    fetchStatistics () {
      const start_date = this.getDashFormattedDate(this.period.start_date);
      const end_date = this.getDashFormattedDate(this.period.end_date);

      const payload = {
        ...this.pagination.options,
        start_date,
        end_date,
      };
      this.isDataLoading = true;
      return this.fetchCommonStatistics(payload).then((response) => {
        this.pagination.meta = response.data.meta;
      }).finally(() => {
        this.isDataLoading = false;
      });
    },

    getQuarterPeriod (date = new Date()) {
      const year = date.getFullYear();
      const quarter = Math.floor(date.getMonth() / 3);
      const startMonth = quarter * 3;
      const endMonth = startMonth + 3;
      const start_date = new Date(year, startMonth, 1);
      const end_date = new Date(year, endMonth, 0);

      return { start_date, end_date };
    },

    getDashFormattedDate (date) {
      return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2);
    },
  },
  watch: {
    period () {
      this.fetchStatistics();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/_nullstyle.scss";
@import "@/assets/_shared.scss";
.page {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.releases{
  @include section;
  height: min-content;
  margin-bottom: 30px;
  margin-bottom: 22px;
}

.releases-loading{
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  width: 100%;
}

.section {
  @include section(false);
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
  padding: 24px;

  justify-content: center;
  align-items: center;

  &__loader {

  }

  &__wrapper {
    width: 100%;
  }

  &__header {
    margin-bottom: 22px;
  }

  &__back-button {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }

    span {
      text-align: left;
      color: $red;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &__heading {
    font-size: 1.188rem;
    font-weight: 400;

    span {
      color: #828282;
    }
  }

  &__dropdown {
    z-index: 99;
  }

  &__block {
    &:not(:last-child){
      margin-bottom: 48px;
    }
  }

  &__linechart {
    flex: 1;
    height: 212px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .chart {
      width: 100%;
      height: 100%;
    }
  }

  &__stores {
    padding-left: 35px;
  }

  &__countries {
    padding: 0 20px;
  }

  &__list {
    flex: 1;
  }
}

.list {
  display: grid;
  row-gap: 8px;

  &--stores {
    grid-template-columns: 1fr 1fr;
  }

  &--countries {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    position: relative;
    font-size: 1rem;

    p {
      margin-left: 16px;
    }

    span {
      color: #828282;
    }

    &::before {
      content: '';
      position: absolute;
      background: var(--color);
      left: 0;
      top: calc(50% - 4.5px);
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 100%;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
  &__center {
    display: flex;
    flex: 2;
    justify-content: center;
  }
  &__right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.block {
  &__heading {
    margin-bottom: 32px;
  }
}

.stores {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;

  &__piechart {
    width: 130px;
    height: 130px;
  }

  &__total {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 1;

    span {
      font-size: 12px;
      color: #828282;
    }
  }
}

.countries {
  &__barchart {
    flex: 1;
    height: 130px;
    margin-bottom: 40px;

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}

.period-modal {
  padding: 8px;
  width: 400px;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  &__label {
    font-size: 1.25rem;
    font-weight: 600;
    color: #828282;
  }

  &__input {
    width: 280px;
  }

  &__submit {
    font-size: 1.188rem;
    font-weight: 600;
    width: 200px;
    height: 44px;
  }
}

</style>
