<template>
  <input
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :class="['input', styleSearch ? 'input--search' : 'input--default', {'input--error':error} ]"
  >
</template>
<style lang="scss" scoped>

@import '@/assets/_shared.scss';

.input{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 13px;

    border: 1px solid $dark-gray;
    border-radius: 11px;

    &::placeholder{
        color:$dark-gray;
    }

    &:disabled{
        cursor: not-allowed;

        border: 1px solid $gray;
    }

    &:disabled::placeholder{
        color:$gray;
    }

    &--default{
        border: 1px solid $dark-gray;
        border-radius: 11px;
    }

    &--search{
        border: none;
        border-bottom: 2px solid $gray;
    }

    &:focus{
        border-color: $dark-gray;
        outline: none;
    }
}

.input--error{
    color:$red;
    border: 1px solid $red;
    &::placeholder{
        color: rgba($color: $red, $alpha: .5);
    }
}
</style>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    error: Boolean,
    styleSearch: Boolean,
  },
};
</script>
