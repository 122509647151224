import store from '../store';

export default function copyToClipboard (data, notificationName) {
  navigator.clipboard.writeText(data);

  if (!notificationName) return;

  const notification = {
    id: new Date(),
    heading: `${notificationName} saved to clipboard`,
    style: 'success',
    lifetime: 5000,
  };

  store.dispatch('createNotification', notification);
}
