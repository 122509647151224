<template>
  <section class="welcome__login-methods welcome__section">
    <div class="welcome__login-methods-header__wrapper welcome__login-methods-item">
      <h3 class="welcome__login-methods-header">
        {{ $t('sign_up') }}
      </h3>
      <span
        class="welcome__login-methods-link"
        @click="$router.replace('/signin')"
      >
        <svg style="transform:rotateZ(180deg); margin-right:10px;">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow-right'}" />
        </svg>
        {{ $t('sign_in') }}
      </span>
    </div>
    <div
      class="welcome__login-errors"
      v-if="errors"
    >
      {{ errors }}
    </div>
    <form
      @submit.prevent="registerRequest"
      class="welcome__login-form welcome__login-methods-item"
    >
      <Input
        autofocus
        v-model.trim="loginData.login.text"
        class="welcome__login-form-input"
        :error="loginData.login.error"
        @change="validateInput(loginData.login,['login'])"
        @input="clearInput(loginData.login)"
        placeholder="example@mail.ru"
      />

      <div class="welcome__login-form__row">
        <PasswordInput
          v-model="loginData.password.text"
          class="welcome__login-form-input"
          type="password"
          :error="loginData.password.error"
          @change="validateInput(loginData.password,['min:8'])"
          @input="clearInput(loginData.password)"
          placeholder="new password"
        />
        <button
          class="welcome__login-next-btn"
          :disabled="!SignUpFormValidated || authLoading"
        >
          <svg
            v-if="authLoading"
            style="animation: rotate .6s linear infinite;"
          >
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
          </svg>
          <svg v-else>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow-right'}" />
          </svg>
        </button>
      </div>
    </form>
    <div class="welcome__login-remember welcome__login-methods-item">
      <span>
        {{ $t('remember_me') }}
      </span>
      <input
        class="welcome__switch"
        type="checkbox"
        checked
      >
    </div>
    <!-- <button
      class="welcome__login-switch__btn"
      @click="$router.replace('/welcome')"
    >
      <span> Use social networks</span>
      <svg>
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow-right'}" />
      </svg>
    </button> -->
  </section>
</template>

<script>
import Input from '../components/Input.vue';
import PasswordInput from '../components/PasswordInput.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Input,
    PasswordInput,
  },
  data () {
    return {
      authLoading: false,
      errors: '',
      loginData: {
        login: {
          text: '',
          error: false,
        },
        password: {
          text: '',
          error: false,
        },
      },
    };
  },
  mounted () {
    this.fetchUserData().then(() => {
      this.$router.push({ name: 'Main' });
    });
  },
  methods: {
    ...mapActions(['login', 'register', 'fetchUserData']),

    loginRequest: function () {
      this.authLoading = true;
      this.login({
        username: this.loginData.login.text,
        password: this.loginData.password.text,
      }).then(() => {
        this.$router.push({ name: 'Main' });
      }).catch(({ response }) => {
        if (response && response.status == 401) {
          this.errors = 'Не правильный логин или пароль';
        } else {
          this.errors = 'Произошла ошибка';
        }
      }).finally(() => {
        this.authLoading = false;
      });
    },

    registerRequest: function () {
      this.authLoading = true;
      const registerData = {
        username: this.loginData.login.text,
        password: this.loginData.password.text,
      };
      this.register(registerData).then(() => {
        this.login(registerData).then(() => {
          this.$router.push({ name: 'Main' });
        });
      }).catch((error) => {
        console.log(error.response);
        if (error.response) {
          if (error.response.status == 409) {
            this.errors = 'Пользователь уже существует!';
          }
        }
      }).finally(() => {
        this.authLoading = false;
      });
    },

    clearInput: function (inputObject) {
      this.errors = '';
      inputObject.error = false;
    },

    validateInput: function (inputObject, rules) {
      const filters = new Map([
        ['max', (string, parametr) => {
          return string.length <= parametr;
        }],
        ['min', (string, parametr) => {
          return string.length >= parametr;
        }],
        ['login', string => {
          const mailRegex = new RegExp(/.+@.+\..+/i);
          const numberRegex = new RegExp(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/);
          return numberRegex.test(string) || mailRegex.test(string);
        }],
      ]);

      const messages = new Map([
        ['max', parametr => {
          return `max ${parametr} characters.`;
        }],
        ['min', parametr => {
          return `min ${parametr} characters.`;
        }],
        ['login', () => {
          return 'Enter phone number or email.';
        }],
      ]);

      rules.some(rule => {
        const nameRule = rule.split(':')[0];
        const parametr = rule.split(':')[1];

        if (!filters.get(nameRule)(inputObject.text, parametr)) {
          inputObject.error = true;
          this.errors = messages.get(nameRule)(parametr);
        }
      });
    },
  },

  computed: {
    SignUpFormValidated () {
      return !this.loginData.password.error && !this.loginData.login.error && this.loginData.password.text && this.loginData.login.text;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/_shared.scss';

    .welcome__section{
        padding: 25px 35px;
        margin-bottom: 20px;
        box-sizing: border-box;
        width: 388px;
        background: $white;
        box-shadow: $sections_shadow;
        border-radius: $sections_border_radius;
        height: min-content;

        &:last-child{
            margin: 0;
        }
    }

    .welcome__login-methods-header__wrapper{
        display: flex;
        justify-content: space-between;
    }

    .welcome__login-methods-header{
        margin:0;
        font-size: 1.5rem;

        span{
            color:$text-color;
            font-weight: 400;
        }
    }

    .welcome__login-methods-link{
        display: flex;
        align-items: center;
        color:$red;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        svg{
            width: 10px;
            height: 10px;
            fill:$red;
        }

        &:hover{
            border-bottom: 1px solid $red;
        }
    }

    .welcome__login-form__row{
        display: flex;

        .welcome__login-form-input{
            margin-right: 10px;

            &:last-child{
                margin:0;
            }
        }
    }

    .welcome__login-form-input{
        margin-bottom: 10px;

        &:last-child{
            margin: 0;
        }
    }

    .welcome__switch{
        width: 1px;
        height: 1px;
        position:relative;
        margin-right: 40px;
        cursor: pointer;
        outline: none;

        &:focus::before{
            border: 1px solid $text-color;
        }

        &::before{
            content: '';
            position: absolute;
            width: 40px;
            height: 21px;
            border-radius: 18px;
            background: $dark-gray;
            top:0px;
            left:0px;
            transition: .2s;
            border:1px solid transparent;
        }

        &:after{
            content:'';
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 10px;
            background: $white;
            top:4px;
            left:4px;
            transition: .2s;
        }

        &:checked::before{
            background: $red;
        }

        &:checked::after{
            transform: translateX(calc(40px - 18px - 3px));
        }
    }

    .welcome__login-switch__btn{
        padding: 3px 0px;
        cursor:pointer;
        background: transparent;
        border:none;
        color:$black;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;

        svg{
            margin-left:5px;
            width: 12px;
            height: 12px;
        }

        &:hover{
            border-bottom: 1px solid $black;
        }
    }

    .welcome__login-next-btn{
        padding: 0;
        box-sizing: border-box;
        background: $red;
        border:none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        flex-basis: 40px;
        border-radius: 10px;
        cursor: pointer;

        svg{
            width: 15px;
            height: 15px;
            fill:white;
        }

        &:enabled:hover{
            background: #ff1216;
        }

        &:disabled{
            background: $gray;
            cursor: not-allowed;
            svg{
                fill:$dark-gray;
            }
        }
    }

    .welcome__login-errors{
        padding: 5px 10px;
        background: $red;
        color:white;
        border-radius: 5px;

        margin-bottom: 10px;
    }

    .welcome__login-methods-item{
      &:not(:last-child){
        margin-bottom: 25px;
      }
    }

    .welcome__login-remember{
        display:flex;

        span{
            margin-right:10px;
        }
    }
</style>
