<template>
  <label class="switch">
    <input
      class="switch-input"
      type="checkbox"
      :checked="modelValue"
      @change="(e) => inputHandler(e.target.checked)"
    >
    <div class="switch-slider" />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
  },
  methods: {
    inputHandler (value) {
      this.$emit('update:modelValue', value);
      this.$emit('switch', value);
    },
  },
};
</script>

<style scoped>
.switch {
  width: 40px;
  height: 21px;
  position: relative;
  margin-right: 40px;
  cursor: pointer;
  outline: none;
}

.switch-input {
  display: none;
}

.switch-slider {
  content: '';
  position: absolute;
  width: 40px;
  height: 21px;
  border-radius: 18px;
  background: #999999;
  top: 0;
  left: 0;
  transition: 0.2s;
  border: 1px solid transparent;
}

.switch-input:checked + .switch-slider {
  background: #ff0000;
}

.switch-input:checked + .switch-slider:before {
  transform: translateX(calc(40px - 23px));
}

.switch-slider:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background: #ffffff;
  top: 3px;
  left: 4px;
  transition: 0.2s;
}
</style>
