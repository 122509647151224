<template>
  <div :class="['artist', (selectable && selected) && 'artist--selected']">
    <div class="artist__main">
      <div class="artist__avatar avatar">
        <div class="avatar__wrapper">
          <img :src="avatarSrc" class="avatar__image">
        </div>
      </div>
      <div class="artist__info">
        <div class="artist__row">
          <p class="artist__name">
            {{ artist.name }}
          </p>
          <div class="artist__stores">
            <div
              v-for="store in stores"
              :key="store.name"
              :class="['artist__store', artist[store.field]?.length && 'artist__store--active']"
              :style="{'--color': store.color}"
              :title="artist[store.field] ? `Copy ${store.name}` : `${store.name} isn't set`"
              @click="copyStore(store)"
            >
              <svg>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#' + store.icon}" />
              </svg>
            </div>
          </div>
        </div>
        <p class="artist__subtitle" v-if="subtitle">
          {{ $t(`artist_roles.${subtitle}`) }}
        </p>
      </div>
    </div>
    <div class="artist__actions">
      <button class="artist__action" @click="$emit('edit')">
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#edit'}" />
        </svg>
      </button>
      <button class="artist__action artist__action--select" v-if="selectable" @click="toggleSelection">
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#checkmark'}" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import copyToClipboard from '../utilities/copyToClipboard';

export default {
  props: {
    artist: {
      avatar_uuid: String,
      name: String,
      spotify_id: String,
      apple_id: String,
    },
    subtitle: String,
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      stores: [
        {
          name: 'Spotify ID',
          icon: 'spotify',
          color: '#1DB954',
          field: 'spotify_id',
        },
        {
          name: 'Apple ID',
          icon: 'apple',
          color: 'black',
          field: 'apple_id',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['file']),

    avatarSrc () {
      return this.file(this.artist.avatar_uuid)?.base64 ?? '/default-user.png';
    },
  },
  methods: {
    ...mapActions([
      'fetchFile',
      'createNotification',
    ]),

    copyToClipboard,

    toggleSelection () {
      if (this.selected) {
        this.$emit('unselect');
      } else {
        this.$emit('select');
      }
    },

    fetchAvatar () {
      const fileUuid = this.artist.avatar_uuid;
      if (!fileUuid) return;

      const avatarFile = this.file(this.artist.avatar_uuid);
      if (avatarFile) return;

      this.fetchFile(this.artist.avatar_uuid);
    },

    copyStore ({ name, field }) {
      const storeData = this.artist[field];
      let notification;

      if (storeData) {
        this.copyToClipboard(storeData);

        notification = {
          id: new Date(),
          heading: `${name} saved to clipboard`,
          style: 'success',
          lifetime: 5000,
        };
      } else {
        notification = {
          id: new Date(),
          heading: `You cannot copy ${name}`,
          message: `You didn't set up ${name} for this artist yet.`,
          style: 'warning',
        };
      }

      this.createNotification(notification);
    },
  },
  mounted () {
    this.fetchAvatar();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.artist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .2s;
  border-radius: 16px;
  height: 68px;
  padding: 12px 32px 12px 10px;

  &:hover {
    background: #F3F3F3;
    transition: .2s;

    .artist__action {
      transition: .2s;
      opacity: 1;
    }
  }

  &--selected {
    border: 2px solid $red;
    .artist__action--select {
      fill: $red;
      opacity: 1;
    }

    &:hover {
      background: none;
    }
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__subtitle {
    color: $dark-gray;
    font-size: 14px;
  }

  &__avatar {
    margin-right: 16px;
  }

  &__name {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 18px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__stores {
    display: flex;
    gap: 8px;
  }

  &__store {
    width: 16px;
    height: 16px;
    fill: #DDDDDD;
    cursor: help;

    svg {
      display: block;
      max-width: 16px;
      max-height: 16px;
    }

    &--active {
      fill: var(--color);
      cursor: copy;
    }
  }

  &__actions {
    transition: .2s;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__action {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: none;
    border: none;
    fill: #D9D9D9;
    transition: .15s;
    opacity: 0;

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      transition: .2s;
      fill: #ccc;
    }

    &:active {
      transform: scale(.9);
    }

    &--select {
      fill: rgba($color: $red, $alpha: .3);

      &:hover {
        fill: $red;
        opacity: .6;
      }
    }
  }
}

.avatar {
  filter: drop-shadow(0px 0px 0.5px gray);

  &__wrapper {
    position: relative;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;
    overflow: visible;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (pointer: coarse) {
  .artist__action {
    opacity: 1;
  }
}
</style>
