const initialState = () => {
  return {
    notifications: [],
  };
};

const notifications = {
  state: () => initialState(),

  actions: {
    createNotification: function ({ commit }, data) {
      commit('pushNotification', data);
      return notifications;
    },
    deleteNotification: function ({ commit }, id) {
      commit('removeNotification', id);
      return notifications;
    },
  },

  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },
    pushNotification: function (state, notification) {
      state.notifications.unshift(notification);
    },
    setNotifications: function (state, notifications) {
      state.notifications = notifications;
    },
    removeNotification: function (state, id) {
      state.notifications = state.notifications.filter((noti) => noti.id !== id);
    },
  },

  getters: {
    notifications: state => state.notifications,
    notification: state => id => state.notifications.find((noti) => noti.id == id),
  },
};

export default notifications;
