<template>
  <Transition name="fade">
    <div class="modal" @click="setVisibility(false)" v-if="visibility">
      <div class="modal__wrapper" v-bind="$attrs" @click.stop>
        <div class="modal__header" v-if="true">
          <h1 class="modal__heading">
            {{ heading }}
          </h1>
          <button class="modal__close" @click="setVisibility(false)" v-show="closable">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#plus-translucent'}" />
            </svg>
          </button>
        </div>
        <div class="content">
          <div class="title">
            <h3>Релизы</h3>
            <div class="info">
              <span class="completed">{{transferingMetaData.releases.completed}} перенесён</span>
              <span class="pending">{{transferingMetaData.releases.waiting}} ожидают</span>
              <span class="canceled">{{transferingMetaData.releases.failed}} отменены</span>
            </div>
          </div>
          <div class="socketReleases-list" v-if="socketReleases">
            <!-- RELEASE ACTIVE TRANSFERING -->
            <div class="release release--active" v-for="release in activeReleases" :key="release.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{release.name}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
              </svg>
            </div>
            <!-- RELEASE FAILED TRANSFERING -->
            <div class="release release--failed" v-for="release in failedReleases" :key="release.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{release.name}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#failure'}" />
              </svg>
            </div>
            <!-- RELEASE SUCCESS TRANSFERING -->
            <div class="release release--completed" v-for="release in completedReleases" :key="release.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{release.name}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#success'}" />
              </svg>
            </div>
            <!-- RELEASE WAITING TRANSFERING -->
            <div class="release" v-for="release in waitingReleases" :key="release.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{release.name}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#pause'}" />
              </svg>
            </div>
          </div>
          <!-- SKELETON [socketReleases] -->
          <div class="socketReleases-list--skeleton" v-show="!socketReleases.length">
            <div class="release">
              <div class="cover"></div>
            </div>
            <div class="release">
              <div class="cover"></div>
            </div>
            <div class="release">
              <div class="cover"></div>
            </div>
          </div>
          <!-- END SKELETON [socketReleases] -->
          <div class="title">
            <h3>Мультилинки</h3>
            <div class="info">
              <span class="completed">{{transferingMetaData.multilinks.completed}} перенесён</span>
              <span class="pending">{{transferingMetaData.multilinks.waiting}} ожидают</span>
              <span class="canceled">{{transferingMetaData.multilinks.failed}} отменены</span>
            </div>
          </div>
          <div class="socketReleases-list" v-if="socketMultilinks.length">
            <!-- [MULTILINK] ACTIVE TRANSFERING -->
            <div class="release release--active" v-for="multilink in activeMultilinks" :key="multilink.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{multilink.name?multilink.name:'Unknown'}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
              </svg>
            </div>
            <!-- [MULTILINK] FAILED TRANSFERING -->
            <div class="release release--failed" v-for="multilink in failedReleases" :key="multilink.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{multilink.name?multilink.name:'Unknown'}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#failure'}" />
              </svg>
            </div>
            <!-- [MULTILINK] SUCCESS TRANSFERING -->
            <div class="release release--completed" v-for="multilink in completedMultilinks" :key="multilink.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{multilink.name?multilink.name:'Unknown'}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#sucsess'}" />
              </svg>
            </div>
            <!-- [MULTILINK] WAITING TRANSFERING -->
            <div class="release" v-for="multilink in waitingMultilinks" :key="multilink.uuid">
              <div class="release-info">
                <div class="cover">
                  <img :src="require('@/assets/placeholder.png')" alt="">
                </div>
                <p class="release-name">{{multilink.name?multilink.name:'Unknown'}}</p>
              </div>

              <svg class="icon">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#pause'}" />
              </svg>
            </div>
          </div>
          <!-- SKELETON [MULTILINK] -->
          <div class="socketReleases-list--skeleton" v-show="!socketMultilinks.length">
            <div class="release">
              <div class="cover"></div>
            </div>
            <div class="release">
              <div class="cover"></div>
            </div>
            <div class="release">
              <div class="cover"></div>
            </div>
          </div>
        </div>
        <div class="modal__actions">
          <RedButtonVue class="modal__action" @click="actionHandler" v-show="actionText" :disabled="loading" >
            {{actionText}}
            <svg v-show="loading">
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#loading'}" />
            </svg>
          </RedButtonVue>
          <GrayButtonVue class="modal__goBack" @click="goBackHandler" v-show="goBackText">{{goBackText}}</GrayButtonVue>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import GrayButtonVue from './GrayButton.vue';
import RedButtonVue from './RedButton.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  emits: ['switch'],
  inheritAttrs: false,
  components: {
    RedButtonVue,
    GrayButtonVue,
  },
  props: {
    visibility: Boolean,
    heading: String,
    closable: {
      type: Boolean,
      default: true,
    },
    actionText: String,
    goBackText: String,
  },
  data: function () {
    return {}
  },
  methods: {
    setVisibility (value) {
      if (this.closable) {
        this.$emit('switch', value);
      }
    },

    actionHandler () {
      this.$emit('actionClick');
    },

    goBackHandler () {
      this.setVisibility(false);
      this.$emit('goBackClick');
    },
  },
  computed: {

    socketReleases(){
      return this.$store.getters.socketReleases
    },

    socketMultilinks(){
      return this.$store.getters.socketMultilinks
    },

    activeReleases(){
      return this.socketReleases.filter(({status}) => status.toLowerCase() == 'active')
    },

    failedReleases(){
      return this.socketReleases.filter(({status}) => status.toLowerCase() == 'failed' || status.toLowerCase() == 'paused')
    },

    completedReleases(){
      return this.socketReleases.filter(({status}) => status.toLowerCase() == 'completed' || status.toLowerCase() == 'delayed')
    },

    waitingReleases(){
      return this.socketReleases.filter(({status}) => status.toLowerCase() == 'waiting')
    },

    activeMultilinks(){
      return this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'active')
    },

    failedMultilinks(){
      return this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'failed' || status.toLowerCase() == 'paused')
    },

    completedMultilinks(){
      return this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'completed' || status.toLowerCase() == 'delayed')
    },

    waitingMultilinks(){
      return this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'waiting')
    },

    transferingMetaData(){
      return {
        releases:{
          waiting: this.socketReleases.filter(({status}) => status.toLowerCase() == 'waiting').length,
          completed: this.socketReleases.filter(({status}) => status.toLowerCase() == 'completed' || status.toLowerCase() == 'delayed').length,
          failed: this.socketReleases.filter(({status}) => status.toLowerCase() == 'failed' || status.toLowerCase() == 'paused').length,
        },
        multilinks:{
          waiting: this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'waiting').length,
          completed: this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'completed' || status.toLowerCase() == 'delayed').length,
          failed: this.socketMultilinks.filter(({status}) => status.toLowerCase() == 'failed' || status.toLowerCase() == 'paused').length,
        },
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.title{
  margin-bottom: 10px;

  .info{
    display: flex;
    font-size: 14px;
    font-weight: 700;

    .pending{
      color: $dark-gray;
    }

    .completed{
      color: $common;
    }

    .canceled{
      color: $red;
    }

    span{
      margin-right: 7px;
    }
  }
}

.socketReleases-list{
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .release{
    margin-bottom: 10px;
    width: 100;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $light-gray;
    width: 49%;

    .release-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .cover{
        width: 30px;
        height: 30px;
        border-radius: 10px;
        border-radius: 4px;
        margin-right: 15px;
        overflow: hidden;
      }
    }


    .icon{
      width: 25px;
      height: 25px;
      display: block;
      fill: $dark-gray;
      justify-self: flex-end;
    }
  }

  .release--active{
    background: #fff5d0;

    .release-name{
      color: $orange;
    }

    .icon{
      fill: $orange;
      animation: rotateAnimation .6s linear infinite;
    }
  }

  .release--failed{
    background: #ffd0d0;

    .release-name{
      color: $red;
    }

    .icon{
      fill: $red;
    }
  }

  .release--completed{
    background: #eaffd0;

    .icon{
      fill: $green;
    }
  }
}

.socketReleases-list--skeleton{
  display: flex;
  flex-direction: column;

  .release{
    margin-bottom: 10px;
    width: 100;
    padding: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loadingAnimation 1.5s infinite;
    border-radius: 4px;

    .cover{
      width: 30px;
      height: 30px;
      border-radius: 10px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: loadingAnimation 1.5s infinite;
      border-radius: 4px;
    }
  }
}

.fade-enter-active{
  transition: all .1s ease-in-out;
  .modal__wrapper {
    transition: all .1s ease-in-out;
  }
}
.fade-leave-active{
  transition: all .3s ease-in-out;

  .modal__wrapper {
    transition: all .3s ease-in-out;
  }
}


.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  .modal__wrapper {
    transition: all .3s ease-in-out;
    transform: scale(.6);
  }
}

.modal {
  z-index: 100;
  position: fixed;
  background: rgba($color: black, $alpha: .3);
  backdrop-filter: blur(2px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__wrapper {
    cursor: initial;
    @include section;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__close {
    cursor: pointer;
    background: none;
    border: none;
    width: 18px;
    height: 18px;

    svg {
      fill: $red;
      width: inherit;
      height: inherit;
      transform: rotate(45deg);
    }
  }

  &__actions{
    margin-top: 10px;
    display: flex;

    button {
      font-size: 15px;
      display: flex;
      align-items: center;
    }
  }

  &__action{
    margin-right: 10px;

    svg{
      width: 13px;
      height: 13px;
      margin-left: 10px;
      fill: $dark-gray;
      animation: rotateAnimation .6s linear infinite;
    }
  }
}

@media (max-width: 768px) {
  .modal__wrapper {
    position: fixed;
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg); /* Начальное положение */
  }
  to {
    transform: rotate(360deg); /* Конечное положение (поворот на 360 градусов) */
  }
}

@keyframes loadingAnimation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>
