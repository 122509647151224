<template>
  <section class="not-found-page">
    <div class="not-found-plate">
      <h1 class="not-found__header">
        404
      </h1>
      <p class="not-found__text">
        Похоже на тупик.
      </p>
      <RedButton @click="back">
        Вернуться
      </RedButton>
      <LoadingLogo
        class="not-found__img"
        id="not-found__img-1"
      />
      <LoadingLogo
        class="not-found__img"
        id="not-found__img-2"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>

    @import '@/assets/_shared.scss';

    .not-found-page{
        width: 100%;
        height: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .not-found-plate{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .not-found__header{
        margin: 0;
        padding: 0;
        color:$red;
        font-size: 100px;
    }

    .not-found__img{
        position: absolute;
        top:calc(50% - 45vh);
        left:calc(50% - 45vh);
        width: 90vh;
        height: 90vh;
        z-index: -1;
    }

    #not-found__img-1{
        opacity: .05;
        left:calc(50% - 40vh);
    }

    #not-found__img-2{
        opacity: .5;
        left:calc(50% - 45vh);
    }
</style>

<script>
import LoadingLogo from '../components/LoadingLogo.vue';
import RedButton from '../components/RedButton.vue';
export default {
  components: {
    LoadingLogo,
    RedButton,
  },
  methods: {
    back: function () {
      this.$router.push('/');
    },
  },
};
</script>
