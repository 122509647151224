<template>
  <div :class="['chip', 'chip--' + color]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.chip {
  padding: 6px 12px;
  border-radius: 100px;
  width: min-content;

  &--gray {
    color: #959595;
    background: #D7D7D7;
  }

  &--red {
    color: #E85A5A;
    background: #FFC1C1;
  }

  &--orange {
    color: #C69448;
    background: #FFCF88;
  }

  &--yellow {
    color: #E4B846;
    background: #FFEAB2;
  }

  &--green {
    color: #71CD69;
    background: #D8FFDC;
  }
}
</style>
