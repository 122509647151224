import axios from 'axios';

const initialState = () => {
  return {
    artists: [],
    artist: {},
  };
};

const artists = {
  state: () => initialState(),
  actions: {
    fetchArtist: function ({ commit }, artist_uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/artist/${artist_uuid}`)
          .then(response => {
            commit('updateArtist', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
    fetchUserArtists: function ({ commit }) {
      const token = localStorage.getItem('access_token');
      if (!token) return;
      const userUuid = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).sub;

      return new Promise((resolve, reject) => {
        axios.get(`user/${userUuid}/artists`).then(response => {
          commit('updateArtists', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteArtist: function ({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`artist/${uuid}`, uuid)
          .then(response => {
            commit('removeArtist', uuid);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
    patchArtist: function ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`artist/${payload.artistUuid}`, payload.artistData)
          .then(response => {
            commit('updateArtist', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
    createArtist: function ({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios.post('artist', payload).then(response => {
          console.log(response.data);
          commit('addArtist', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    uploadArtistAvatar: function ({ commit }, { artistUuid, formdata }) {
      return new Promise((resolve, reject) => {
        axios.post(`/artist/${artistUuid}/upload-avatar`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          commit('updateArtist', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    removeArtistAvatar: function ({ commit }, artistUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/artist/${artistUuid}/remove-avatar`).then(response => {
          commit('updateArtist', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },

    updateArtists: function (state, artists) {
      if (!Array.isArray(artists)) return;
      state.artists = artists;
    },

    updateArtist: function (state, newArtist) {
      const artistIndex = state.artists.findIndex(artist => artist.uuid == newArtist.uuid);
      if (artistIndex > -1) {
        state.artists[artistIndex] = newArtist;
      } else {
        state.artists.push(newArtist);
      }
    },

    removeArtist: function (state, uuid) {
      state.artists = state.artists.filter(artist => artist.uuid != uuid);
    },

    addArtist: function (state, artist) {
      state.artists.push(artist);
    },

    removeArtistSource: function (state, payload) {
      const artistSocialNetworks = state.artists[payload.artistId].social_networks;
      const index = artistSocialNetworks.indexOf(artistSocialNetworks.find(source => source.pivot.id == payload.sourceId));
      artistSocialNetworks.splice(index, 1);
    },

    updateArtistSources: function (state, newSources) {
      console.log(state.artists, newSources);
      const structuredSources = [];
      newSources.response.forEach(source => {
        source.social_source.pivot = {
          id: source.id,
          social_data: source.social_data,
        };
        structuredSources.push(source.social_source);
      });
      state.artists[newSources.payload.artistId].social_networks = structuredSources;
    },

    addArtistSource: function (state, source) {
      const newSource = source.social_source;
      newSource.pivot = {
        id: source.id,
        artist_id: source.artist_id,
        social_data: source.social_data,
      };
      state.artists[source.artist_id].social_networks.push(newSource);
    },
  },
  getters: {
    artists: state => state.artists,

    artistByUuid: state => artistId => {
      return state.artists.find(artist => artist.uuid == artistId);
    },
  },
};

export default artists;
