import axios from 'axios';

const initialState = () => {
  return {
    ticket: null,
    messages: [],

    tickets: [],
    faqs: [],
  };
};

const tickets = {
  state: () => initialState(),
  actions: {
    createTicket: function ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/ticket', payload).then(response => {
          commit('addTicket', response.data.data);
          resolve(response.data.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    fetchMyTickets: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/my-tickets').then(response => {
          commit('setTickets', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    fetchFAQs: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/faqs').then(response => {
          commit('setFaqs', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    fetchTicket: function ({ commit }, ticketUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/ticket/${ticketUuid}`).then(response => {
          commit('setTicket', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createTicketMessage: function ({ commit }, { ticketUuid, messageData }) {
      return new Promise((resolve, reject) => {
        axios.post(`/ticket/${ticketUuid}/message`, messageData).then(response => {
          commit('addMessage', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    fetchTicketMessages: function ({ commit }, ticketUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/ticket/${ticketUuid}/messages`).then(response => {
          commit('setMessages', response.data.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },
    setTicket: function (state, ticket) {
      state.ticket = ticket;
    },
    addMessage: function (state, message) {
      state.messages.push(message);
    },
    setMessages: function (state, messages) {
      state.messages = messages;
    },
    setTickets: function (state, tickets) {
      state.tickets = tickets;
    },
    setFaqs: function (state, faqs) {
      state.faqs = faqs;
    },
    addTicket: function (state, ticket) {
      state.tickets.push(ticket);
    },
  },
  getters: {
    ticket: state => {
      return state.ticket;
    },
    messages: state => {
      return state.messages;
    },
    tickets: state => {
      return state.tickets;
    },
    faqs: state => {
      return state.faqs;
    },
  },
};

export default tickets;
