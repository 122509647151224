import { createI18n } from 'vue-i18n';
import ru from './locales/ru.json';
import en from './locales/en.json';

const messages = {
  en,
  ru,
};

const locale = localStorage.getItem('locale') || 'en';
const i18n = createI18n({
  locale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
