<template>
  <button class="music-label-offer">
    <div class="left-side">
      <div class="icon-wrapper">
        <div class="icon">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#whitelogo'}" />
          </svg>
        </div>
        <div class="icon-decor"></div>
      </div>
      <div class="title-wrapper">
        <p class="title">Стать лейблом</p>
        <p class="sub-title">оставить заявку</p>
      </div>
    </div>
    <button class="btn" @click="$emit('click')">
      <svg>
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow-right'}" />
      </svg>
    </button>
  </button>
</template>

<style lang="scss" scoped>
.music-label-offer {
  background: radial-gradient(325.92% 135.2% at 2.34% 4.12%, rgb(234, 234, 234) 0%, rgb(255, 255, 255) 100%);
  height: 85px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #E1E1E1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Добавляем relative для правильного позиционирования псевдоэлемента */
  cursor: pointer;
}

.left-side{
  display: flex;
  align-items: center;
}

.music-label-offer{
  position: relative;


  &:hover{
    .icon{
      background: radial-gradient(99.05% 99.05% at 5.86% 11.33%, rgba(233, 163, 120, 0.5) 0%, rgba(233, 163, 120, 0.3) 100%), 
                radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(233, 163, 120, 0.5) 0%, rgba(255, 255, 255, 0.3) 100%);
    }

    .btn{
      background: #E9A378;
    }
  }
}

.icon {
  border-radius: 6px;
  width: 40px;
  height: 40px;
  transition: .4s;
  background: radial-gradient(99.05% 99.05% at 5.86% 11.33%, rgba(255, 182, 182, 0.5) 0%, rgba(255, 105, 105, 0.3) 100%), 
              radial-gradient(186.42% 186.42% at 116.41% 127.73%, rgba(255, 192, 203, 0.5) 0%, rgba(255, 255, 255, 0.3) 100%);
  backdrop-filter: blur(5px);
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  border: 1px solid rgba($color: #fff, $alpha: .3);
  display: flex;
  justify-content: center;
  align-items: center;

  svg{
    width: 23px;
    height: 23px;
    fill: white;
  }
}

.icon-decor {
  content: '';
  position: absolute;
  border-radius: 5px;
  width: 31px;
  height: 31px;
  background: #F21818;
  opacity: .7;
  transform: rotate(30deg);
  transform: translate(-8px, -48px) rotate(30deg);
  animation: float 60s infinite linear;
  filter: blur(1px); /* Добавляем размытие */
}

@keyframes float {
  0% {
    transform: translate(-8px, -48px) rotate(30deg);
    background: red;
  }
  25% {
    transform: translate(-9px, -18px) rotate(60deg);
  }
  50% {
    transform: translate(18px, -20px) rotate(12deg);
  }
  75% {
    transform: translate(18px, -48px) rotate(120deg);
  }
  100% {
    transform: translate(-8px, -48px) rotate(30deg);
  }
}

.btn{
  background: #F21818;
  width: 40px;
  height: 40px;
  border:none;
  border-radius: 12px;
}

.title-wrapper{
  margin-left: 15px;
  p{
    margin: 0;
    padding: 0;
    color: #232323;
    font-weight: 600;
  }

  .title{
    font-size: 16px;
    font-weight: 700;
  }

  .sub-title{
    font-size: 13px;
  }
}

.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .4s;

  svg{
    width: 20px;
    height: 20px;
    fill: white;
  }

  &:hover{
    transform: translateY(-2px);
    background: #E9A378;
  }

  &:active{
    transform: translateY(1px);
  }
}
</style>
