<template>
  <div class="cropper">
    <div class="cropper__container">
      <img :src="src" ref="image" class="cropper__image">
    </div>
    <div class="cropper__sidebar">
      <h1 class="cropper__heading">
        Preview
      </h1>
      <img :src="destination" class="cropper__preview">
      <RedButton class="cropper__submit" @click="save">
        Save
      </RedButton>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import RedButton from './RedButton.vue';

export default {
  components: { RedButton },
  props: {
    src: String,
  },
  data () {
    return {
      cropper: {},
      destination: {},
      image: {},
    };
  },
  methods: {
    setPreview () {
      const canvas = this.cropper.getCroppedCanvas();
      this.destination = canvas.toDataURL('image/png');
    },

    dataURItoBlob (dataURI) {
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    save () {
      const base64 = this.destination;
      const binary = this.dataURItoBlob(base64);

      this.$emit('save', { base64, binary });
    },
  },
  mounted () {
    this.image = this.$refs.image;
    this.destination = this.src;
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      movable: false,
      aspectRatio: 1,
      autoCropArea: 1,
      cropend: this.setPreview,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_nullstyle.scss";
@import "@/assets/_shared.scss";

.cropper {
  display: flex;
  gap: 16px;

  &__container {
    width: 300px;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
  }

  &__preview {
    width: 150px;
    height: 150px;
    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;
    object-fit: cover;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
  }

  &__heading {
    font-size: 1.5rem;
    text-align: center;
  }
}
</style>
