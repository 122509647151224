<template>
  <LoadingLogo v-if="isDataLoading" class="loader" />
  <section class="section" v-else>
    <div class="section__general" v-if="!isChangingPassword">
      <div class="section__header">
        <h1 class="section__title">
          {{ $t('settings') }}
        </h1>
        <button class="section__save-btn" :disabled="v$.$silentErrors?.length" @click="updateUserRequest">
          {{ $t('save') }}
        </button>
      </div>
      <div class="section__preview">
        <div class="section__avatar avatar">
          <div class="avatar__main">
            <LoadingLogo v-if="isAvatarLoading" class="avatar__loading" />
            <img v-else-if="avatar?.src"
              class="avatar__image"
              :src="avatar?.src"
              @click="isCropperModal = true"
            >
            <img src="default-user.png" class="avatar__image avatar__image--placeholder" v-else>
          </div>
          <button class="avatar__edit">
            <FileInput
              class="avatar__input"
              :accepts="['image/jpeg', 'image/png']"
              @upload="loadAvatar"
            >
              <svg>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#edit'}" />
              </svg>
            </FileInput>
          </button>
        </div>
        <div class="section__name">
          <p>
            {{ user.first_name }}
          </p>
          <p>
            {{ user.last_name }}
          </p>
        </div>
      </div>
      <div class="section__main">
        <div class="section__row">
          <InputWithLabel
            class="section__input"
            :label="$t('first_name')"
            :placeholder="$t('first_name')"
            v-model="userData.first_name"
            :validations="validations.userData.first_name"
          />
          <InputWithLabel
            class="section__input"
            :label="$t('last_name')"
            :placeholder="$t('last_name')"
            v-model="userData.last_name"
            :validations="validations.userData.last_name"
          />
        </div>
        <div class="section__row">
          <div class="section__email email">
            <InputWithLabel
              class="section__input email__input"
              :label="$t('email')"
              :placeholder="$t('email')"
              v-model="parsedToken.username"
              disabled
            />
            <button
              class="email__confirm"
              @click="verifyEmailRequest"
              title="Verify"
              :disabled="parsedToken.verified_at"
            >
              <svg :class="['email__indicator', parsedToken.verified_at && 'email__indicator--active']">
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#checkmark'}" />
              </svg>
            </button>
          </div>
          <button class="section__password-button password" @click="isChangingPassword = true">
            <span class="password__text">
              {{ $t('change_password') }}
            </span>
            <svg class="password__icon">
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#lock'}" />
            </svg>
          </button>
        </div>

        <div class="app-version">
          {{ $t('version') }}: {{ getAppVersion }}
        </div>
      </div>
    </div>
    <div class="section__password" v-else>
      <div class="section__header">
        <div class="section__title-button" @click="isChangingPassword = false">
          <svg class="section__back-icon" v-if="isChangingPassword">
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
          </svg>
          {{ $t('back') }}
        </div>
      </div>
      <div class="section__row">
        <InputWithLabel
          type="password"
          class="section__input"
          :label="$t('current_password')"
          :placeholder="$t('current_password')"
          v-model="passwordData.old_password"
          :validations="validations.passwordData.old_password"
        />
      </div>
      <div class="section__row">
        <InputWithLabel
          type="password"
          class="section__input"
          :label="$t('new_password')"
          :placeholder="$t('new_password')"
          v-model="passwordData.new_password"
          :validations="validations.passwordData.new_password"
        />
      </div>
      <div class="section__row">
        <InputWithLabel
          type="password"
          class="section__input"
          :label="$t('confirm_new_password')"
          :placeholder="$t('confirm_new_password')"
          v-model="passwordData.repeat_password"
          :validations="validations.passwordData.repeat_password"
        />
        <RedButton :disabled="v$.$silentErrors?.length" @click="updatePasswordRequest">
          {{ $t('change_password') }}
        </RedButton>
      </div>
    </div>
    <Modal
      :visibility="isCropperModal"
      @switch="(value) => isCropperModal = value"
      :heading="'Edit avatar'"
    >
      <AvatarCropper :src="avatar.src" @save="avatarCropHandler" />
    </Modal>
    <Modal :visibility="modalText.length">
      <div class="modal">
        <svg class="modal__icon">
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#mylabel-symbol'}" />
        </svg>
        <p class="modal__message">
          {{ modalText }}
        </p>
        <RedButton class="modal__button" @click="modalText = ''">
          OK
        </RedButton>
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingLogo from '../components/LoadingLogo.vue';
import RedButton from '../components/RedButton.vue';
import InputWithLabel from '../components/InputWithLabel.vue';
import Modal from '../components/Modal.vue';
import FileInput from '../components/FileInput.vue';
import AvatarCropper from '../components/AvatarCropper.vue';
import useValidate from '@vuelidate/core';
import { required, sameAs, helpers } from '@vuelidate/validators';

export default {
  components: {
    LoadingLogo,
    InputWithLabel,
    Modal,
    RedButton,
    FileInput,
    AvatarCropper,
  },
  data () {
    return {
      v$: useValidate(),

      isDataLoading: true,
      isAvatarLoading: true,

      isChangingPassword: false,

      modalText: '',
      modalTexts: {
        resetSucceeded: 'An email with a link to change your password was sent to you.',
        verifySucceeded: 'An email with a link to confirm your email has been successfully sent.',
      },

      isCropperModal: false,

      userData: {},
      passwordData: {
        old_password: '',
        new_password: '',
        repeat_password: '',
      },
      avatar: {
        file: null,
        src: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'file',
      'user',
      'parsedToken',
    ]),

    validations () {
      return {
        userData: {
          first_name: {
            required,
            regex: helpers.withMessage('Filled incorrectly', helpers.regex(/^([а-яё]+|[a-z]+)$/i)),
          },
          last_name: {
            regex: helpers.withMessage('Filled incorrectly', helpers.regex(/^([а-яё]+|[a-z]+)$/i)),
          },
        },
        passwordData: {
          old_password: {
            required,
          },
          new_password: {
            required,
          },
          repeat_password: {
            required,
            equalsNew: helpers.withMessage('Passwords do not match', sameAs(this.passwordData.new_password)),
          },
        },
      };
    },

    getAppVersion () {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    ...mapActions([
      'fetchUserData',
      'sendVerificationEmail',
      'resetPassword',
      'updateUser',
      'updatePassword',
      'uploadFile',
      'fetchFile',
      'createNotification',
    ]),

    updatePasswordRequest () {
      const payload = this.passwordData;
      this.updatePassword(payload).then(() => {
        this.isChangingPassword = false;
        this.passwordData = {
          old_password: '',
          new_password: '',
          repeat_password: '',
        };

        const notification = {
          id: new Date(),
          heading: 'Password successfully changed',
          style: 'success',
        };

        this.createNotification(notification);
      }).catch((e) => {
        console.log(e);
        const notification = {
          id: new Date(),
          heading: 'Failed to change password',
          message: e.message,
          style: 'failure',
          actionCallback: () => {
            this.$router.push({ name: 'Support' });
          },
        };

        this.createNotification(notification);
      });
    },

    async updateUserRequest () {
      const file = this.avatar.file;

      if (file) {
        const avatar_url = await new Promise((resolve, reject) => {
          this.uploadFileRequest(file).then((response) => {
            resolve(response.data.uuid);
          }).catch((e) => reject(e));
        });

        this.userData.avatar_url = avatar_url;
      }

      const payload = {
        userUuid: this.user.uuid,
        userData: this.userData,
      };

      this.updateUser(payload).then(() => {
        const notification = {
          id: new Date(),
          heading: 'Successfully saved',
          style: 'success',
        };

        this.createNotification(notification);
      }).catch((e) => {
        console.log(e);
        const notification = {
          id: new Date(),
          heading: 'Failed to make changes',
          message: e.message,
          style: 'failure',
          actionCallback: () => {
            this.$router.push({ name: 'Support' });
          },
        };

        this.createNotification(notification);
      });
    },

    verifyEmailRequest () {
      const verificationCountdown = 60; // seconds
      const currentTime = new Date().getTime();
      const lastTryTime = localStorage.getItem('lastEmailVerificationTime');
      const secondsSinceLastTry = (currentTime - parseInt(lastTryTime)) / 1000;

      if (lastTryTime && (secondsSinceLastTry < 60)) {
        const secondsRemaining = parseInt(verificationCountdown - secondsSinceLastTry);
        this.modalText = `You have already requested mail confirmation recently, please try again in ${secondsRemaining} seconds.`;
        return;
      }

      localStorage.setItem('lastEmailVerificationTime', currentTime);
      const username = this.parsedToken.username;

      this.sendVerificationEmail(username).then(() => {
        this.modalText = this.modalTexts.verifySucceeded;
      }).catch((response) => {
        console.log(response);
        this.modalText = response.message;
      });
    },

    resetPasswordRequest () {
      const payload = {
        username: this.parsedToken.username,
      };

      this.resetPassword(payload).then(() => {
        this.modalText = this.modalTexts.resetSucceeded;
      }).catch((response) => {
        console.log(response);
        this.modalText = response.message;
      });
    },

    uploadFileRequest (file, loadProgress) {
      const formdata = new FormData();
      formdata.append('file', file);
      const uploadResult = this.uploadFile({ formdata, loadProgress });
      return uploadResult;
    },

    avatarCropHandler ({ base64, binary }) {
      this.isCropperModal = false;
      this.avatar.src = base64;
      this.avatar.file = binary;
    },

    loadAvatar: async function (file) {
      this.avatar.file = file;
      this.avatar.src = await this.loadFileSRC(file);
      this.isCropperModal = true;
    },

    loadFileSRC (file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = () => {
          reject(fr.error);
        };
        fr.readAsDataURL(file);
      });
    },

    fetchData () {
      this.fetchUserData().then(async (response) => {
        const avatarPromise = new Promise((resolve, reject) => {
          const file = this.file(response.data.avatar_url);
          if (file) {
            resolve(file);
          } else {
            this.fetchFile(response.data.avatar_url).then(() => {
              resolve(this.file(response.data.avatar_url));
            }).catch((e) => reject(e));
          }
        });

        avatarPromise.then((avatarFile) => {
          this.avatar.src = avatarFile.base64;
        }).finally(() => {
          this.isAvatarLoading = false;
        });
      }).finally(() => {
        this.isDataLoading = false;
      });
    },
  },
  mounted () {
    this.fetchData();
  },
  watch: {
    user: {
      deep: true,
      handler (user) {
        this.userData = { ...user };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.loader {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  width: 100%;
}

.section {
  width: 100%;
  height: min-content;
  padding: 27px 32px;

  border-radius: $sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 1.188rem;
    font-weight: 500;
  }

  &__title-button {
    font-size: 1.188rem;
    font-weight: 500;
    color: $red;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &__back-icon {
    max-width: 15px;
    max-height: 15px;
    transform: rotate(180deg);
  }

  &__save-btn {
    cursor: pointer;

    color: $red;
    border: none;
    background: none;

    font-size: 1.188rem;
    font-weight: 500;

    &:disabled {
      color: $dark-gray;
    }
  }

  &__preview {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }

  &__avatar {
    margin-right: 10px;
  }

  &__name {
    font-size: 1.375rem;
    font-weight: 700;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__row {
    display: flex;
    gap: 16px;

    button {
      white-space: nowrap;
    }
  }

  // &__input { }

  &__email {
    flex: 1;
  }

  &__password-button {
    margin-top: auto;
    flex: 1;
  }

  &__password {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.avatar {
  padding: 3px 3px 0 0;
  position: relative;

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 78px;
    width: 78px;
    height: 78px;
    -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-image: url('~@/assets/icons/img-form-mask.svg');
    mask-repeat: no-repeat;
    background: $light-gray;
  }

  &__loading {
    width: 75px;
    height: 75px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &--placeholder {
      cursor: inherit;
    }
  }

  &__edit {
    display: flex;
    position: absolute;
    top: -3px;
    right: -3px;
    width: 26px;
    height: 26px;
    background: rgba(0, 0, 0, .6);
    border-radius: 100%;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s;

    &:hover {
      transition: .2s;
      background: rgba(0, 0, 0, .8);
    }

    svg {
      width: 11px;
      height: 11px;
      fill: white;
    }
  }
}
.email {
  position: relative;

  // &__input { }

  &__confirm {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: calc(20px - (20px / 2));
    right: 16px;
    background: $gray;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: .2s;

    &:hover {
      transition: .2s;
      background: #efefef;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__indicator {
    width: 12px;
    height: 12px;
    fill: #cecece;

    &--active {
      fill: $red;
    }
  }
}
.password {
  height: 40px;
  min-height: 40px;
  border-radius: 11px;
  background: #F4F4F4;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: .2s;

  &:hover {
    transition: .2s;
    background: #ececec;
  }

  &__text {
    font-size: 1rem;
    font-weight: 500;
    color: $red;
  }

  &__icon {
    width: 10px;
    height: 10px;
    fill: $red;
    stroke: $red;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &__message {
  }

  &__icon {
    width: 32px;
    height: 32px;
    fill: $red;
  }

  &__button {
    margin-top: 8px;
    width: 200px;
  }
}

.app-version{
  padding: 5px;
  font-size: 14px;
  color: #cecece;
}

@media (max-width: 768px) {
  .section__row {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }
  .section__email {
    width: 100%;
  }
  .section__password-button {
    width: 100%;
  }
}
</style>
