import axios from 'axios';

const initialState = () => {
  return {
    user: {},
    dataOtherUsers: [],
  };
};

const user = {
  state: () => initialState(),
  actions: {
    fetchUserData: function (context) {
      return new Promise((resolve, reject) => {
        console.log('fetching user data');
        axios.get('/me').then(response => {
          context.commit('updateUserData', response.data);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
    fetchAnotherUser ({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/${uuid}`).then(response => {
          console.log(response);
          commit('addAnotherUser', response.data.data);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
    updateUser ({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios.patch(`/profile/${payload.userUuid}`, payload.userData).then(response => {
          console.log(response);
          commit('updateUserData', response.data);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
    updatePassword ({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios.patch('/update-password', payload).then(response => {
          console.log(response);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },
    updateUserData: function (state, userData) {
      console.log('commit user data', userData);
      state.user = userData;
    },
    addAnotherUser: function (state, anotherUser) {
      state.dataOtherUsers.push(anotherUser);
    },
  },
  getters: {
    user: state => {
      return state.user;
    },
    anotherUser: (state) => (userUuid) => {
      return state.dataOtherUsers.find(anotherUser => anotherUser.uuid == userUuid);
    },
  },
};

export default user;
