<template>
  <div class="page">
    <Notification
      v-if="releaseData.status == 'DENIED'"
      :notification="deniedNotification"
    />
    <section class="section">
      <LoadingLogo v-if="dataLoading.release || dataLoading.artists" class="section__loader" />
      <div class="section__main" v-else>
        <div class="preview__header">
          <div class="preview__title">
            {{ releaseData.title }} <span>— {{ releaseData.type }}</span>
          </div>
        </div>
        <div class="preview__data">
          <div class="preview__cover">
            <img :src="releaseData.cover.src" alt="Cover" v-if="releaseData.cover.src">
            <svg v-else>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#mylabel-symbol'}" />
            </svg>
          </div>
          <div class="preview__info">
            <div class="preview__column">
              <div class="preview__text">
                <p class="preview__info-title">
                  {{ $t('artist') }}
                </p>
                <p class="preview__info-subtitle">
                  {{ artistNames.length ? artistNames : 'Unknown artist' }}
                </p>
                <p class="preview__info-title">
                  {{ $t('release_date') }}
                </p>
                <p class="preview__info-subtitle">
                  {{ formattedReleaseDateString }}
                </p>
              </div>
              <button class="preview__play" @click="playButtonSwitch" :disabled="!tracksData.length">
                <svg>
                  <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + ($refs.player && $refs.player.paused ? '#play' : '#pause') }" />
                </svg>
                <span>
                  {{ $t('listen') }}
                </span>
              </button>
            </div>
            <div class="preview__column">
              <div class="preview__text">
                <p class="preview__info-title">
                  {{ $t('status') }}
                </p>
                <p class="preview__info-subtitle">
                  {{ releaseData.status }}
                </p>
              </div>
              <div class="preview__text">
                <p class="preview__info-title">
                  {{ $t('genre') }}
                </p>
                <p class="preview__info-subtitle">
                  {{ [primaryStyle(releaseData.genre)?.name, secondaryStyle(releaseData.subgenre)?.name].filter((e) => e).join(',') }}
                </p>
              </div>
              <div class="preview__text">
                <p class="preview__info-title">
                  {{ $t('language') }}
                </p>
                <p class="preview__info-subtitle">
                  {{ language(releaseData.language)?.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="preview__tracks">
          <div class="preview__track track"
            v-for="(track, index) in tracksData.filter((t) => t.file)"
            :key="track.src" @click="playTrack(index)"
            :style="playingTrackIndex == index ? `--loadingProgress: calc(${playingProgress.toFixed(3)}% - 24px);` : ''"
          >
            <div class="track__background" />
            <div class="track__info">
              <div class="track__general" :title="`${track.title} - ${track.artistName}`">
                <span class="track__index">
                  {{ index + 1 }}
                </span>
                <span class="track__title">
                  {{ track.title }}
                </span>
                <span class="track__artist" v-if="track.artistName">
                  {{ ` - ${track.artistName}` }}
                </span>
              </div>
              <span class="track__duration">
                {{ track.duration }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <audio
        ref="player"
        @timeupdate="updatePlayingProgress"
      >
        <source
          :src="tracksData[playingTrackIndex].fileSrc"
          v-if="playingTrackIndex !== null"
          type="audio/wav"
        >
      </audio>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingLogo from '../components/LoadingLogo.vue';
import Notification from '../components/Notification.vue';

export default {
  components: {
    LoadingLogo,
    Notification,
  },
  data () {
    return {
      dataLoading: {
        release: true,
        artists: true,
        tracks: true,
      },

      releaseData: {
        uuid: '',
        type: null,
        cover: {
          uuid: '',
          file: null,
          src: '',
        },
        title: '',
        titleVersion: '',
        artists: [],
        composers: [],
        copyright: '',
        genre: undefined,
        subgenre: undefined,
        language: undefined,
        releaseDate: '',
        status: '',
      },
      releaseDataChangedTime: 0,
      tracksData: [],

      playingProgress: 0,
      playingTrackIndex: null,
    };
  },
  mounted () {
    this.$refs.player.volume = 0.2;

    this.fetchLanguages();
    this.fetchMusicStyles();
    this.configureRelease();
  },
  methods: {
    ...mapActions([
      'fetchLanguages',
      'fetchMusicStyles',
      'fetchUserArtists',
      'fetchRelease',
      'fetchFile',
      'unmoderateRelease',
      'createNotification',
    ]),

    configureRelease () {
      const releaseUuid = this.$route.params.uuid;
      this.releaseData.uuid = releaseUuid;
      this.fetchRelease(releaseUuid).then((response) => {
        this.dataLoading.release = false;
        const release = response.data;

        this.releaseData = {
          uuid: release.uuid,
          artists: release.artists,
          composers: release.composers,
          language: release.language_id,
          title: release.title,
          titleVersion: release.title_version,
          genre: release.primary_music_style_id,
          subgenre: release.secondary_music_style_id,
          type: release.type,
          tracks: release.tracks,
          cover: {
            src: '',
            uuid: release.cover_uuid,
          },
          releaseDate: this.getDashFormattedDate(release.release_date),
          copyright: release.copyright, // TODO: ask backender
          status: release.status,
          denyReason: release.deny_reason,
        };

        this.fetchUserArtists().then(() => {
          this.dataLoading.artists = false;

          this.releaseData.artists.forEach((a, index) => {
            const foundArtist = this.artists.find((artist) => artist.uuid == a.artist_uuid);
            console.log(foundArtist);
            if (foundArtist) {
              this.releaseData.artists[index] = {
                uuid: foundArtist.uuid,
                name: foundArtist.name,
                spotifyID: foundArtist.spotify_id,
                appleID: foundArtist.apple_id,
              };
            }
          });

          this.releaseData.tracks.forEach((track, i) => {
            track.artists.forEach((a, j) => {
              const foundArtist = this.artists.find((artist) => artist.uuid == a.artist_uuid);
              console.log(foundArtist);
              if (foundArtist) {
                this.releaseData.tracks[i].artists[j] = {
                  ...this.releaseData.tracks[i].artists[j],
                  uuid: foundArtist.uuid,
                  name: foundArtist.name,
                  spotifyID: foundArtist.spotify_id,
                  appleID: foundArtist.apple_id,
                };
              }
            });
          });
        });

        this.fetchFile(release.cover_uuid).then((response) => {
          this.releaseData.cover.src = window.URL.createObjectURL(response.data);
        });

        for (let i = 0; i < release.tracks.length; i++) {
          const track = release.tracks[i];
          const trackData = {
            uuid: track.uuid,
            file: undefined,
            fileSrc: '',
            fileUuid: track.wav_uuid,
            artistName: '',
            title: track.title,
            genre: track.primary_music_style_id,
            subgenre: track.secondary_music_style_id,
            language: track.language_id,
            previewStartSecond: track.preview_start_seconds,
            lyrics: 'Lyrics',
            artists: track.artists,
            composers: track.composers,
          };

          this.fetchFile(track.wav_uuid).then((response) => {
            trackData.file = response.data;
            trackData.fileSrc = window.URL.createObjectURL(response.data);
            this.tracksData[i] = trackData;
          }).finally(() => {
            this.dataLoading.tracks = false;
          });
        }
      });
    },

    updatePlayingProgress () {
      const currentTime = this.$refs.player.currentTime;
      const progress = currentTime ? this.$refs.player.currentTime / this.$refs.player.duration * 100 : 0;
      if (progress >= 100) {
        this.nextTrack();
      }
      this.playingProgress = progress;
    },

    playButtonSwitch () {
      if (!this.playingTrackIndex) this.playingTrackIndex = 0;

      if (this.$refs.player.paused) {
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }
    },

    playTrack (index) {
      if (this.playingTrackIndex == index) {
        if (this.$refs.player.paused) {
          this.$refs.player.play();
        } else {
          this.$refs.player.pause();
        }
        return;
      }

      this.playingTrackIndex = index;
      try {
        this.$refs.player.load();
      } catch {
        this.$refs.player.pause();
      } finally {
        this.$refs.player.play();
      }
    },

    getDashFormattedDate (dateString) {
      const date = new Date(dateString);
      return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2);
    },

    isValidDate (d) {
      return d instanceof Date && !isNaN(d);
    },
  },
  computed: {
    ...mapGetters([
      'release',
      'artists',

      'artistRoles',
      'composerRoles',
      'languages',
      'primaryStyles',
      'secondaryStyles',
      'language',
      'primaryStyle',
      'secondaryStyle',
    ]),

    releaseUuid () {
      return this.$route.params.uuid;
    },

    artistNames () {
      return this.releaseData.artists
        .filter((artist) => artist.name && artist.name.length)
        .map((artist) => artist.name).join(', ');
    },

    formattedReleaseDateString () {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const date = new Date(this.releaseData.releaseDate);
      if (!this.isValidDate(date)) {
        return 'Data';
      }
      const dateString = date.toLocaleDateString('en-US', options);
      return dateString;
    },

    deniedNotification () {
      return {
        heading: this.$t('release_rejected_by_the_moderator'),
        message: `${this.$t('message_from_moderator')}: "${this.releaseData.denyReason}"`,
        actionButtonText: this.$t('back_to_draft'),
        style: 'warning',
        opened: true,
        actionCallback: () => {
          this.unmoderateRelease(this.releaseUuid).then(() => {
            this.$router.push(`/drafts/${this.releaseUuid}`);
          }).catch((e) => {
            const notification = {
              id: new Date(),
              heading: 'An error occurred during release unmoderating',
              message: e.message,
              style: 'failure',
              actionCallback: () => {
                this.$router.push({ name: 'Support' });
              },
            };

            this.createNotification(notification);
          });
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.section {
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 21px 0px #0000001A;
  padding: 32px 32px 24px 32px;
  height: min-content;

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__loader {
    width: 100%;
  }
}

.preview {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;
    padding-left: 16px;
  }

  &__title {
    text-align: center;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 18px;

    span {
      color: #828282;
    }
  }

  &__data {
    display: flex;
    align-items: center;

    margin-bottom: 16px;

    gap: 23px;
  }

  &__cover {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 175px;
    height: 175px;

    border-radius: 20px;
    background: #F3F3F3;

    img {
      width: 100%;
      height: 100%;

      border-radius: 20px;

      object-fit: cover;
    }

    svg {
      width: 64px;
      height: 64px;
    }
  }

  &__info {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__text {
    display: flex;
    flex-direction: column;

    gap: 8px;
  }

  &__info-title {
    color: #82868A;

    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
  }

  &__info-subtitle {
    margin-bottom: 8px;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 100%;
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 120px;
    height: 30px;
    margin-top: 8px;

    cursor: pointer;

    border: none;
    border-radius: 30px;
    background: $red;

    gap: 8px;

    svg {
      width: 8px;
      height: 10px;

      fill: white;
    }

    span {
      color: white;
    }

    &:disabled {
      background: $gray;
    }
  }

  &__tracks {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    gap: 18px;
  }

  &__track {
    height: 39px;

    cursor: pointer;

    border-radius: 11px;
    background: #F3F3F3;
  }

  &__submit {
    width: 100%;
    max-width: 170px;
    margin: 0 auto;

    font-weight: 600;
  }

  &__no-tracks {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 32px;

    gap: 32px;

    svg {
      width: 43px;
      height: 45px;
    }
    p {
      padding: 16px 24px;

      text-align: center;

      color: #82868A;
      border-radius: 100px;
      background: #F3F3F3;

      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
.track {
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 18px 0 16px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 11px;

    width: var(--loadingProgress);
    height: 4px;

    content: '';

    border-radius: 11px 11px 0px 0px;
    background: lightblue;
  }

  &__background {
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  &__general {
    overflow: hidden;

    max-width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__index {
    margin-right: 24px;

    color: #82868A;

    font-weight: 300;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 300;
  }

  &__artist {
    color: #82868A;

    font-size: 1.125rem;
    font-weight: 300;
  }

  &__duration {
    color: #82868A;

    font-weight: 300;
  }
}
</style>
