import axios from 'axios';

async function getFingerprint () {
  const userAgent = `${navigator.hardwareConcurrency} ${navigator.maxTouchPoints} ${navigator.userAgent}`;

  let fingerprint = userAgent;

  if (crypto.subtle) {
    const msgUint8 = new TextEncoder().encode(userAgent);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    fingerprint = await hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  return fingerprint;
}

const initialState = () => {
  return {
    loginStatus: false,
    pendingUpdate_RefreshTokens: null,
  };
};

const auth = {
  state: () => initialState(),
  actions: {
    login: async function (context, payload) {
      const fingerprint = await getFingerprint();
      const data = {
        fingerprint,
        username: payload.username,
        password: payload.password,
      };
      return new Promise((resolve, reject) => {
        axios.post('/login', data, { withCredentials: true })
          .then(response => {
            const { ban_reason } = response.data;
            if (ban_reason) {
              context.commit('resetState', context.state);
              const error = { response: { ban_reason } };
              reject(error);
            }

            localStorage.setItem('access_token', response.data.access_token);
            context.commit('loginStatus', true);
            context.dispatch('fetchUserData');
            context.dispatch('createSocket');
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },

    logout: function (context) {
      return new Promise((resolve, reject) => {
        axios.post('/logout', null, { withCredentials: true }).then(response => {
          context.commit('loginStatus', false);
          resolve(response);
        }).catch(error => reject(error))
          .finally(() => {
            localStorage.removeItem('access_token');
            context.commit('clearApplicationData');
            context.dispatch('resetAllModuleStates');
          });
      });
    },

    register: function (context, payload) {
      payload.service = 'mylabel';

      return new Promise((resolve, reject) => {
        axios.post('/register', payload).then(response => {
          context.commit('clearApplicationData');
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    /*
    Payload (verifyEmail)
    - token
    */
    verifyEmail: function (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/verify', payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    sendVerificationEmail: function (context, username) {
      const payload = {
        username,
        service: 'mylabel',
      };

      return new Promise((resolve, reject) => {
        axios.post('/verify-email', payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    refreshTokens: async function ({ commit, dispatch }) {
      console.log('2)вызывается  обновление токенов');
      const fingerprint = await getFingerprint();
      const refreshPromise = new Promise((resolve, reject) => {
        // TODO: remove the timeout when refresh will be fixed
        setTimeout(() => {
          axios.create().post('/refresh', { fingerprint }, { withCredentials: true }).then((response) => {
            console.log('3)новые токены');
            localStorage.setItem('access_token', response.data.access_token);
            resolve(response);
          }).catch(error => {
            console.log('3)ошибка новые токены');
            dispatch('removeSocket')
            commit('clearApplicationData');
            commit('loginStatus', false);
            reject(error);
          }).finally(() => {
            commit('unsubscribeRefreshToken');
          });
        }, 1000);
      });
      commit('subscribeRefreshToken', refreshPromise);
      return refreshPromise;
    },

    resetPassword: async function (context, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios.post('/reset-password-email', {
          username: payload.username,
          service: 'mylabel',
        }, { withCredentials: true })
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },

    changePassword: async function (context, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios.post('/reset-password', {
          token: payload.token,
          password: payload.password,
          service: 'mylabel',
        }, { withCredentials: true })
          .then(response => {
            console.log(response);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },

    loginStatus: function (state, status) {
      state.loginStatus = status;
    },

    clearApplicationData: function (state) {
      state.user = null;
    },

    // service utils mutations
    subscribeRefreshToken: function (state, promise) {
      state.pendingUpdate_RefreshTokens = promise;
    },

    unsubscribeRefreshToken: function (state) {
      state.pendingUpdate_RefreshTokens = null;
    },
  },
  getters: {
    // service utils getter
    pendingUpdate_RefreshTokens: state => {
      return state.pendingUpdate_RefreshTokens;
    },
    parsedToken: () => {
      const token = localStorage.getItem('access_token');
      const parsedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64'));

      return parsedToken;
    },
  },
};

export default auth;
