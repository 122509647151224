<template>
  <div class="product">
    <div class="product__left">
      <div class="product__image">
        <img :src="require('@/assets/placeholder.png')" alt="product image">
      </div>
      <div class="product__info">
        <p class="product__name">
          {{ product.displayName }}
        </p>
        <p class="product__description">
          {{ product.description }}
        </p>
      </div>
    </div>
    <div class="product__right" v-if="showPrice">
      <p class="product__price">
        ${{ product.amount }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    showPrice: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  &__left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 16px;

    img {
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__name {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
  }

  &__description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 16px;
    color: #CACCCF;
  }

  &__right {
  }

  &__price {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
  }
}
</style>
