const name = 'Payments';

const headers = [
  {
    field: 'icon',
    isIcon: true,
  },
  {
    field: 'name',
    subtitleField: 'uuid',
    copyField: 'uuid',
    title: 'Name',
    localizer: 'payment_name',
    itemCellsAlign: 'left',
  },
  {
    field: 'amount',
    title: 'Amount',
    localizer: 'amount',
    isSortable: true,
    itemCellsAlign: 'center',
  },
  {
    field: 'status',
    title: 'Status',
    localizer: 'status',
    itemCellsAlign: 'center',
    isSortable: true,
  },
  {
    field: 'createdAt',
    title: 'Date',
    localizer: 'date',
    itemCellsAlign: 'center',
    isSortable: true,
  },
];

export { name, headers };
