<template>
  <Modal
    :visibility="warningModal.isVisible"
    :heading="warningModal.heading"
    @switch="toggleWarningModal"
  >
    <div class="warning-modal">
      <p class="warning-modal__message">
        {{ warningModal.message }}
      </p>
      <div class="warning-modal__actions">
        <RedButton @click="warningModal.actionCallback" v-if="warningModal.actionCallback">
          {{ warningModal.actionText ?? $t('proceed') }}
        </RedButton>
        <GrayButton
          @click="warningModal.cancelCallback"
          v-if="warningModal.cancelCallback"
        >
          {{ warningModal.cancelText }}
        </GrayButton>
        <GrayButton @click="toggleWarningModal(false)" v-else>
          {{ $t('close') }}
        </GrayButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from './Modal.vue';
import GrayButton from './GrayButton.vue';
import RedButton from './RedButton.vue';

export default {
  components: {
    Modal,
    GrayButton,
    RedButton,
  },
  computed: {
    ...mapGetters(['modal']),

    warningModal () {
      return this.modal('warning');
    },
  },
  methods: {
    ...mapActions(['toggleModal']),

    toggleWarningModal (value) {
      this.toggleModal('warning', value);
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-modal {
  display: flex;
  flex-direction: column;

  min-width: 400px;

  gap: 24px;

  &__message {
    white-space: pre-wrap;
    line-height: 1.5;
  }

  &__actions {
    display: flex;
    justify-content: center;

    gap: 16px;

    & > * {
      flex: 1;
    }
  }
}
</style>