<template>
  <section class="section">
    <div class="section__header">
      <button class="section__back-button" @click="$router.push({ name: 'Support' })">
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
        </svg>
        <span>
          Back
        </span>
      </button>
    </div>
    <template v-if="messages.length">
      <h2 class="section__heading" v-if="ticket && ticket.subject">
        {{ ticket.subject }}
      </h2>
      <div class="section__messages">
        <div class="section__message message" v-for="message in [...messages].reverse()" :key="message.created_at">
          <div class="message__header">
            <div class="message__data">
              <span class="message__datum message__datum--red">
                You
              </span>
              <span class="message__datum">
                {{ getMessageTime(message.created_at) }}
              </span>
            </div>
            <div class="message__data">
              <span class="message__datum">
                {{ getMessageDate(message.created_at) }}
              </span>
            </div>
          </div>
          <div class="message__body">
            <p class="message__content">
              {{ message.content }}
            </p>
            <div class="message__images" v-if="message.images && message.images.length">
              <ViewableImg :src="image.src" alt="Attachment" class="message__image" v-for="image in message.images" :key="image.src" />
              <!-- <div class="message__image-wrapper">
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="createTicketMessageRequest" class="dialog__new-message new-message">
        <div class="new-message__data">
          <TextArea class="new-message__text-area" @keypress="onMessageTextareaKeyPress" placeholder="Write your message here" v-model="messageData.content" />
          <div class="new-message__attach-menu">
            <span>Attach: </span>
            <!-- <button type="button" class="new-message__attach-button" @click="openReleasesModal">
              Release
            </button>
            <button type="button" class="new-message__attach-button" @click="openDraftsModal">
              Draft
            </button> -->
            <FileInput class="new-message__attach-file">
              <span class="new-message__attach-button" @click.prevent>
                File
              </span>
            </FileInput>
          </div>
        </div>
        <button type="submit" class="new-message__publish-button">
          Publish
        </button>
      </form>
      <Modal
        class="section__modal modal"
        :visibility="isDraftsModalVisible" @switch="(value) => isDraftsModalVisible = value"
        :heading="'Select a draft'"
      >
        <LoadingLogo class="loader" v-if="isDraftsLoading" />
        <Table
          class="modal__table"
          :name="'Your Drafts'"
          :headers="tableHeaders"
          :items="draftReleases"
          v-else-if="draftReleases.length"
        />
        <DataPlaceholder v-else
          :title="$t('no_drafts_placeholder_title')"
          :description="$t('no_drafts_placeholder_description')"
          :button-text="$t('no_drafts_placeholder_action')"
          :button-redirect-url="'/upload'"
        />
      </Modal>
      <Modal
        class="section__modal modal"
        :visibility="isReleasesModalVisible" @switch="(value) => isReleasesModalVisible = value"
        :heading="'Select a release'"
      >
        <LoadingLogo class="loader" v-if="isReleasesLoading" />
        <Table
          class="modal__table"
          :name="'Your Releases'"
          :headers="tableHeaders"
          :items="notDraftReleases"
          v-else-if="notDraftReleases.length"
        />
        <DataPlaceholder v-else
          :title="$t('no_drafts_placeholder_title')"
          :description="$t('no_drafts_placeholder_description')"
          :button-text="$t('no_drafts_placeholder_action')"
          :button-redirect-url="'/upload'"
        />
      </Modal>
    </template>
    <LoadingLogo class="loader" v-else />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { headers as tableHeaders } from '@/utilities/tableStructures/simpleReleases.js';
import TextArea from '../components/TextArea';
import ViewableImg from '../components/ViewableImg';
import LoadingLogo from '@/components/LoadingLogo.vue';
import Modal from '../components/Modal.vue';
import FileInput from '../components/FileInput.vue';
import Table from '../components/Table.vue';
import DataPlaceholder from '../components/DataPlaceholder.vue';

export default {
  components: {
    TextArea,
    ViewableImg,
    LoadingLogo,
    Modal,
    FileInput,
    Table,
    DataPlaceholder,
  },
  data () {
    return {
      messageData: {
        content: '',
      },

      // modals
      isDraftsModalVisible: false,
      isDraftsLoading: true,
      isReleasesModalVisible: false,
      isReleasesLoading: true,
      tableHeaders,
    };
  },
  mounted () {
    this.fetchTicket(this.ticketUuid);
    this.fetchTicketMessages(this.ticketUuid);

    const handleKeydown = (e) => {
      if (e.key === 'Escape') {
        this.$router.back();
        document.removeEventListener('keydown', handleKeydown);
      }
    };
    document.addEventListener('keydown', handleKeydown);
  },
  methods: {
    ...mapActions([
      'fetchTicket',

      'createTicketMessage',
      'fetchTicketMessages',

      'fetchAllReleases',
    ]),

    createTicketMessageRequest () {
      const payload = {
        ticketUuid: this.ticketUuid,
        messageData: this.messageData,
      };
      this.createTicketMessage(payload).then(() => {
        this.messageData = {};
      });
    },

    onMessageTextareaKeyPress (e) {
      if (e.key == 'Enter' && e.shiftKey == false) {
        this.createTicketMessageRequest();
      }
    },

    getMessageTime (dateString) {
      const date = new Date(dateString);
      return `${date.getHours()}:${date.getMinutes()}`;
    },

    getMessageDate (dateString) {
      const date = new Date(dateString);
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    },

    openDraftsModal () {
      this.isDraftsModalVisible = true;
      this.fetchAllReleases().finally(() => {
        this.isDraftsLoading = false;
      });
    },

    openReleasesModal () {
      this.isReleasesModalVisible = true;
      this.fetchAllReleases().finally(() => {
        this.isReleasesLoading = false;
      });
    },
  },
  computed: {
    ...mapGetters([
      'ticket',
      'messages',
      'draftReleases',
      'notDraftReleases',
    ]),

    ticketUuid () {
      return this.$route.params.uuid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.modal {
  &__table {
    width: 500px;
  }
}

.loader {
  margin: auto;
}

.section {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  max-height: 80vh;
  padding: 34px 32px 16px 32px;

  border-radius: $sections_border_radius;
  background: $white;
  box-shadow: $sections_shadow;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 32px;
  }

  &__back-button {
    display: flex;
    align-items: center;

    cursor: pointer;

    border: none;
    background: none;

    gap: 8px;

    svg {
      width: 15px;
      height: 15px;

      transform: rotate(180deg);
    }
    span {
      color: $red;
    }
  }

  &__heading {
    margin-bottom: 16px;
    margin-left: 22px;

    font-size: 1.5rem;
    font-weight: 700;
  }

  &__messages {
    display: flex;
    overflow-y: auto;
    flex-direction: column-reverse;

    margin-bottom: 16px;
    padding-bottom: 16px;

    gap: 24px;
    max-height: 100%;
  }

  &__message {
    position: relative;
    width: 100%;
    z-index: inherit;

    &:not(:first-child)::after {
      content: '';
      position: absolute;
      height: 24px;
      width: 1px;
      top: 100%;
      left: 36px;
      background: #EDEDED;
    }
  }
}

.message {
  display: flex;
  flex-direction: column;

  padding: 16px;

  border: 1px solid #EDEDED;
  border-radius: 22px;

  &__header {
    display: flex;
    justify-content: space-between;

    margin-bottom: 8px;
    padding-left: 6px;
  }

  &__data {
    display: flex;
    align-items: center;

    gap: 8px;
  }

  &__datum {
    color: #C1C4C7;

    font-size: 14px;
  }

  &__datum--red {
    color: $red;

    font-weight: 700;
  }

  &__body {
    display: flex;
    flex-direction: column;

    gap: 16px;
  }

  &__content {
    font-size: 15px;
    white-space: pre-wrap;
  }

  &__images {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
  }
}

.new-message {
  width: 100%;

  &__data {
    border: 1px solid #DEDEDE;
    border-radius: 11px;
  }

  &__text-area {
    width: 100%;
    padding: 16px 16px 8px 16px;

    resize: none;

    border: none;
    border-bottom: 1px solid #DEDEDE;
    background: none;

    &:focus {
      outline: none;
    }
  }

  &__attach-menu {
    display: flex;
    align-items: center;

    height: 35px;
    padding: 16px;

    gap: 16px;
  }

  &__attach-file {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__attach-button {
    cursor: pointer;

    color: $red;
    border: none;
    background: none;

    font-weight: 600;
  }

  &__publish-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100px;
    height: 32px;
    margin-top: 16px;
    margin-right: 16px;
    margin-left: auto;

    cursor: pointer;

    color: white;
    border: none;
    border-radius: 16px;
    background: $red;

    font-weight: 600;
  }
}

</style>
