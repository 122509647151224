<template>
  <button class="button">
    <slot />
  </button>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.button {
  height: 52px;
  padding: 0 10px 0 10px;

  cursor: pointer;

  color: white;
  border: none;
  border-radius: 16px;
  background: $red;

  font-size: 1.188rem;
  font-weight: 500;
  transition: .3s;

  &:hover {
    transition: .3s;
    transform: scale(1.02);
  }

  &:disabled{
    background: $gray;
    color: #82868A;
    cursor: not-allowed;
  }
}
</style>
