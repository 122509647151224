<template>
  <div
    class="plan"
    :style="`background: radial-gradient(at top left, ${data.colors.background});`"
  >
    <div class="plan__container">
      <div class="plan__header">
        <img
          :src="data.iconSrc"
          class="plan__icon"
        >
        <p
          class="plan__promo"
          v-if="data.promo"
        >
          {{ data.promo }}
        </p>
      </div>
      <div class="plan__info">
        <p class="plan__name">
          План
          <span :style="`color: ${data.colors.name};`">{{ data.name }}</span>
        </p>
        <p
          class="plan__duration"
          :style="`background: ${data.colors.duration};`"
        >
          {{ getDuration(data.duration) }}
        </p>
      </div>
      <div class="plan__terms">
        <div
          class="plan__price"
          v-if="data.price > 0 && data.duration.period"
        >
          <sup>$</sup>
          <p>
            {{ data.price }}
            <span :style="`color: ${data.colors.subtitle}; font-size: 1.875rem;`">/</span>
            <span>{{ periods[data.duration.period][0] }}</span>
          </p>
        </div>
        <div
          class="plan__price"
          v-else
        >
          <p>
            Бесплатно
          </p>
        </div>
        <p
          class="plan__subtitle"
          :style="`color: ${data.colors.subtitle};`"
        >
          <span>{{ data.subtitle }}</span>
        </p>
      </div>
    </div>
    <div
      class="plan__hr"
      :style="`background: ${data.colors.hr};`"
    />
    <div class="plan__container">
      <div class="plan__details">
        <div
          class="plan__detail"
          v-for="detail in data.details"
          :key="detail.text"
        >
          <svg :style="`fill: ${ !detail.inactive ? data.colors.activeIcon + '; filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2))' : data.colors.inactive };`">
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + (!detail.inactive ? '#check' : '#inactive')}" />
          </svg>
          <p
            class="plan__detail-text"
            :style="`color: ${ detail.inactive ? data.colors.inactive : 'white'}`"
            v-html="detail.text"
          />
        </div>
      </div>
    </div>
    <div class="plan__action plan__container">
      <button
        class="plan__button"
        :style="`background: ${data.colors.button}`"
      >
        {{ data.buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      periods: {
        d: ['день', 'дня', 'дней'],
        m: ['месяц', 'месяца', 'месяцев'],
        y: ['год', 'года', 'лет'],
      },
    };
  },
  methods: {
    getPrice (price, duration) {
      return price === 0 ? 'Бесплатно' : `${price} / ${this.periods[duration.period][0]}`;
    },

    getDuration (duration) {
      let periodString;
      if (duration.number !== 0) {
        if (duration.number == 1) { periodString = this.periods[duration.period][0]; } else if ([2, 3, 4].includes(duration.number)) { periodString = this.periods[duration.period][1]; } else periodString = this.periods[duration.period][2];

        return `${duration.number} ${periodString}`;
      } else return 'Без лимита';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_nullstyle.scss';

.plan {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 800px;
    padding-bottom: 45px;
    border-radius: 48px;
    font-size: 1.188rem;
    color: white;

    &__container{
        max-width: 380px;
        width: 100%;
        margin: 0 auto;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -64px;
    }

    &__icon {
        width: 100px;
    }

    &__promo {
        background: rgba($color: #1e3658, $alpha: .3);
        backdrop-filter: blur(3px) contrast(130%);
        padding: 10px 20px;
        border-radius: 100px;
        box-shadow: 0 0 3px 0 gray;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
    }

    &__name {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-size: 1.188rem;
        span{
            margin-top: -4px;
            font-size: 2.063rem;
            font-weight: 600;
        }
    }

    &__duration {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border-radius: 100px;
    }

    &__terms {
        position: relative;
        margin-bottom: 26px;
    }

    &__price {
        position: relative;
        font-size: 2.5rem;

        sup{
            position: absolute;
            font-weight: 600;
            font-size: 1rem;
            top: 8px;
            left: -10px;
        }

        span{
            font-size: 1.625rem;
        }
    }

    &__subtitle {
        margin-bottom: 3px;
    }

    &__hr{
        width: 100%;
        height: 2px;
        margin-bottom: 33px;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 45px;
    }

    &__detail {
        display: flex;
        align-items: center;

        svg{
            width: 24px;
            height: 24px;
            margin-right: 20px;
        }
    }

    &__detail-text {
        span{
            font-weight: 700;
        }
    }

    &__action {
        margin-top: auto;
    }

    &__button{
        width: 100%;
        height: 60px;
        border-radius: 100px;
        color: white;
        border: none;
        font-size: 1.25rem;
        cursor: pointer;
        transition: .1s ease;

        &:active{
            transition: .2s ease;
            transform: scale(0.97);
        }

        &:hover{
            transition: .3s ease;
            filter: brightness(95%);
        }
    }
}
</style>
