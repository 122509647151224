<template>
  <div v-bind="{...$attrs}" :class="['dropdown', v$.selectedItem.value.$errors?.length && 'dropdown--error']">
    <div class="dropdown__main">
      <label :for="$.uid" class="dropdown__label" v-if="label">
        {{ label }}
      </label>
      <input :class="['dropdown__input', selectedItem?.value ? 'dropdown__input--option-selected' : '']"
        :id="$.uid"
        ref="input"
        type="text"
        autocomplete="off"
        v-bind="$attrs"
        :placeholder="selectedItem?.value ? selectedItem.value : $attrs.placeholder"
        v-model="inputValue"
        @input="textInputHandler"
        @focus="isDropdownOpened = true"
        @blur="inputBlurHandler"
      >
      <svg :class="['dropdown__icon', isDropdownOpened ? 'dropdown__icon--focus' : '']">
        <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
      </svg>
      <div class="dropdown__items" v-if="isDropdownOpened && items">
        <button :class="['dropdown__item', selectedItem.id == item.id ? 'dropdown__item--active' : '']" v-for="item in filteredItems" :key="item" @mousedown="selectItem(item)">
          {{ item.value }}
        </button>
      </div>
    </div>
    <div class="dropdown__error" v-if="v$.selectedItem.value.$errors?.length">
      {{ v$.selectedItem.value.$errors[0]?.$message }}
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';

export default {
  inheritAttrs: false,
  props: {
    items: Array,
    label: String,
    modelValue: {
      type: String || Number,
    },
    validations: Object,
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      v$: useValidate(),
      inputValue: '',
      selectedItem: {
        id: null,
        value: '',
      },
      isDropdownOpened: false,
    };
  },
  mounted () {
    this.configureInput();
  },
  validations () {
    return {
      selectedItem: {
        value: this.validations ?? {},
      },
    };
  },
  methods: {
    configureInput () {
      // set initial value
      this.selectModelValue();

      // add blur on clicking Enter
      this.$refs.input.addEventListener('keyup', (e) => {
        if (e.key == 'Enter') {
          this.isDropdownOpened = false;
          this.$refs.input.blur();
        }
      });
    },

    deselectItem () {
      this.selectedItem = {
        id: null,
        value: '',
      };
    },

    clearInput () {
      this.inputValue = '';
    },

    selectItem (item) {
      this.selectedItem = item;
      this.inputValue = '';
      this.$emit('select', this.selectedItem.id);
      this.$emit('update:modelValue', this.selectedItem.id);
    },

    textInputHandler (e) {
      const inputValue = e.target.value;
      this.$emit('input', inputValue);
    },

    inputBlurHandler () {
      this.v$.selectedItem.value.$touch();
      this.isDropdownOpened = false;
    },

    selectModelValue () {
      const foundItem = this.items.find(item => item.id == this.modelValue);
      if (foundItem) {
        this.selectItem(foundItem);
      } else {
        this.deselectItem();
      }
    },
  },
  computed: {
    filteredItems () {
      if (!this.searchable) return this.items;

      return this.items.filter((item) => item.value.toLowerCase().includes(this.inputValue.toLowerCase()));
    },
  },
  watch: {
    modelValue () {
      this.selectModelValue();
    },
    items () {
      this.clearInput();
      this.selectModelValue();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.dropdown {
  width: 100%;

  &--error {
    .dropdown__input {
      border-color: $red;
    }
  }

  &__main {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
  }

  &__label {
    margin-bottom: 5px;
    margin-left: 12px;

    font-size: 1.125rem;
  }

  &__input {
    position: relative;

    height: 40px;
    padding-top: 2px;
    padding-left: 16px;

    transition: .2s;

    border: 1px solid #D1D1D1;
    border-radius: 11px;

    font-size: 1rem;

    &::placeholder{
      color: #CACCCF;
    }

    &:focus {
      z-index: 3;

      transition: .3s;

      outline: none;
      background: #f9f9f9;
    }

    &--option-selected {
      &::placeholder {
        color: initial;
      }
    }
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    right: 20px;
    bottom: 14px;

    width: 12px;
    height: 12px;

    transform: rotate(90deg);

    &--focus {
      z-index: 4;
    }
  }

  &__items {
    position: absolute;
    z-index: 1;
    top: calc(100% - 11px);

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 100%;
    padding: 20px 10px 10px 10px;

    border: 1px solid #ececec;
    border-radius:  0 0 16px 16px;
    background: white;

    gap: 4px;

    max-height: 400px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    text-align: start;

    // height: 32px;
    padding: 8px 10px;

    cursor: pointer;

    border: none;
    border-radius: 8px;
    background: none;

    &--active {
      color: $red;
    }

    &:hover {
      background: #f7f7f7;
    }
  }

  &__error {
    padding-left: 12px;
    font-size: 14px;
    color: $red;
    left: 12px;
    bottom: -10px;
    padding-top: 4px;
  }
}
</style>
