<template>
  <div class="trackform" :style="styles">
    <div class="trackform__preview preview">
      <div class="preview__text">
        <span :title="trackFullname">
          {{ trackFullname }}
        </span>
        <span class="preview__loading" v-if="loadingProgress > 0 && loadingProgress < 100">
          <NumberAnimation
            :from="0"
            :to="loadingProgress"
            :duration="0.1"
            :format="value => Math.round(value)"
            autoplay
            easing="linear"
          />
          %
        </span>
        <span class="preview__error" v-if="trackData.error">
          {{ trackData.error }}
        </span>
      </div>
      <div class="preview__actions" v-if="trackData.file">
        <div class="preview__action preview__action--edit" @click="$emit('edit')">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#edit'}" />
          </svg>
        </div>
        <div class="preview__action preview__action--remove" @click="$emit('remove')">
          <svg>
            <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#cross'}" />
          </svg>
        </div>
      </div>
      <span class="preview__loading" v-else>
        {{ $t('loading') }}
      </span>
    </div>
    <Transition>
      <div class="trackform__form" v-if="editing">
        <div class="trackform__row">
          <InputWithLabel
            class="trackform__input"
            :label="$t('title')"
            :placeholder="$t('title')"
            v-model="trackData.title"
            :validations="validations.trackData.title"
          />
        </div>
        <div class="trackform__artists artists">
          <div class="artists__header">
            <InputWithLabel class="artists__search"
              v-model="artistsSearchQuery"
              :placeholder="`${$t('search')}...`"
            />
            <button class="artists__action" @click="editingArtist = {}">
              <svg>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#plus-translucent'}" />
              </svg>
              <span>{{ $t('new_artist') }}</span>
            </button>
          </div>
          <div class="artists__list">
            <ArtistCard
              v-for="artist in sortedAndFilteredArtists"
              :key="artist.uuid"
              :artist="artist"
              :selectable="true"
              :selected="!!getTrackArtist(artist.uuid)"
              :subtitle="getTrackArtist(artist.uuid)?.role"
              @select="selectingArtist = artist"
              @unselect="removeArtist(artist.uuid)"
              @edit="editingArtist = artist"
            />
          </div>
        </div>
        <div class="trackform__artists">
          <div class="trackform__authors">
            <p class="trackform__label">
              {{ $t('authors') }}
            </p>
            <button class="trackform__button" @click="() => addNewComposer()">
              + {{ $t('add_author') }}
            </button>
          </div>
          <div class="trackform__artist" v-for="composer in trackData.composers" :key="composer.id">
            <div class="trackform__row">
              <InputWithLabel
                class="trackform__input"
                :label="$t('firstname')"
                :placeholder="$t('firstname')"
                v-model="composer.name"
                :validations="validations.author.name"
              />
              <InputWithLabel
                class="trackform__input"
                :label="$t('lastname')"
                :placeholder="$t('lastname')"
                v-model="composer.surname"
                :validations="validations.author.surname"
              />
              <InputWithLabel
                class="trackform__input"
                :label="$t('patronymic')"
                :placeholder="$t('patronymic')"
                v-model="composer.patronymic"
                :validations="validations.author.patronymic"
              />
            </div>
            <div class="trackform__row trackform__row--with-button">
              <DropdownInput
                class="trackform__dropdown"
                :items="filteredComposerRoles"
                :label="$t('role')"
                :placeholder="$t('select_author_role')"
                v-model="composer.role"
                :searchable="false"
                :validations="validations.author.role"
              />
              <button class="trackform__button" @click="removeComposerById(composer.id)">
                - {{ $t('remove_author') }}
              </button>
            </div>
          </div>
        </div>
        <div class="trackform__row">
          <DropdownInput
            class="trackform__dropdown"
            :items="genres"
            :label="$t('genre')"
            :placeholder="$t('genre')"
            v-model="trackData.genre"
            :validations="validations.trackData.genre"
            />
        </div>
        <div class="trackform__row">
          <DropdownInput
            class="trackform__dropdown"
            :items="languages"
            :label="$t('language')"
            :placeholder="$t('language')"
            v-model="trackData.language"
            :validations="validations.trackData.language"
          />
          <InputWithLabel
            class="trackform__date"
            :label="$t('preview_start_time')"
            placeholder="0"
            type="number"
            min="0"
            :max="trackDuration"
            v-model="trackData.previewStartSecond"
            :validations="validations.trackData.previewStartSecond"
          />
        </div>
        <div class="trackform__row">
          <TextAreaWithLabel
            class="trackform__textarea"
            v-model="trackData.lyrics"
            :label="$t('lyrics')"
            :placeholder="$t('lyrics')"
            :validations="validations.trackData.lyrics"
          />
        </div>
        <div class="trackform__row">
          <div class="trackform__switch">
            <span>{{ $t('explicit') }}</span>
            <Switch v-model="trackData.explicit" />
          </div>
          <div class="trackform__switch">
            <span>{{ $t('instrumental') }}</span>
            <Switch v-model="trackData.instrumental" @switch="instrumentalSwitchHandler" />
          </div>
        </div>
        <RedButton
          class="trackform__save"
          @click="saveForm"
          :disabled="v$.$silentErrors?.length"
          v-if="trackData.wav_uuid"
        >
          {{ $t('save') }}
        </RedButton>
        <RedButton class="trackform__save" :disabled="true" v-else>
          {{ $t('wait_track_load') }}
        </RedButton>
      </div>
    </Transition>
    <audio :ref="audioRefName" :src="trackData.fileSrc" @loadeddata="adjustPreviewStartSecond" preload="metadata" />
    <Modal
      :visibility="editingArtist"
      :heading="editingArtist?.uuid ? $t('artist_form_edit_artist') : $t('artist_form_create_new_artist')"
      @switch="editingArtist = null"
    >
      <div class="artist-modal">
        <ArtistForm :artist="editingArtist" @submit="editingArtist = null" />
      </div>
    </Modal>
    <Modal
      :visibility="selectingArtist"
      :heading="selectingArtist && $t('add_on_track', { name: selectingArtist.name })"
      @switch="saveArtistSelection"
    >
      <div class="artist-modal">
        <DropdownInput
          :items="filteredArtistRoles"
          :label="$t('role_on_track')"
          :placeholder="$t('select_artists_role')"
          v-model="selectingArtistRole"
          :searchable="false"
        />
        <RedButton @click="saveArtistSelection">
          {{ $t('add') }}
        </RedButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputWithLabel from '@/components/InputWithLabel.vue';
import DropdownInput from '@/components/DropdownInput.vue';
import ArtistCard from '@/components/ArtistCard.vue';
import Modal from '@/components/Modal.vue';
import RedButton from '@/components/RedButton.vue';
import ArtistForm from '@/components/ArtistForm.vue';
import Switch from '@/components/Switch.vue';
import useValidate from '@vuelidate/core';
import { required, minLength, minValue } from '@vuelidate/validators';
import TextAreaWithLabel from './TextAreaWithLabel.vue';
import NumberAnimation from "vue-number-animation";

export default {
  components: {
    InputWithLabel,
    Switch,
    DropdownInput,
    ArtistCard,
    Modal,
    RedButton,
    ArtistForm,
    TextAreaWithLabel,
    NumberAnimation
  },
  props: {
    modelValue: Object,
    editing: {
      type: Boolean,
      default: false,
    },
    languages: Array,
    genres: Array,
    loadingProgress: Number,
    artistsList: Array,
    artistRoles: Array,
    composerRoles: Array,
  },
  data () {
    return {
      v$: useValidate(),

      trackData: {},
      trackDuration: null,

      editingArtist: null,
      selectingArtist: null,
      selectingArtistRole: null,
      artistsSearchQuery: '',
    };
  },
  mounted () {
    this.trackData = { ...this.modelValue };
  },
  methods: {
    ...mapActions([
      'removeArtistFromTrack',
      'removeComposerFromTrack',

      'updateModal',
      'toggleModal',
    ]),

    saveForm () {
      const isValid = this.validateArtistsAndComposers();
      if (!isValid) return;

      this.$emit('save');
    },

    validateArtistsAndComposers () {
      const validations = [
        {
          condition: this.trackData.artists.length >= 1,
          message: this.$t('track_must_have_artists'),
        },
        {
          condition: this.trackData.composers.some((composer) => 
            composer.role == 'COMPOSER'
          ),
          message: this.$t('track_must_have_one_author'),
        },
      ];

      const messages = validations
        .filter((validation) => !validation.condition)
        .map((validation) => validation.message);

      if (!messages.length) return true;

      const messagePrefix = (messages.length > 1) ? '•' : '';
      const message = `${messagePrefix} ${messages.join('\n• ')}`;

      const modalData = {
        isVisible: true,
        heading: this.$t('please_note'),
        message,
      };
      this.updateModal({ id: 'warning', data: modalData});

      return false;
    },

    validateArtistAndComposerRoles () {
      const invalidArtists = this.trackData.artists.filter((artist) =>
        !(this.allowedArtistRolesForInsrumental.includes(artist.role))
      );
      const invalidComposers = this.trackData.composers.filter((composer) =>
        !(this.allowedComposerRolesForInsrumental.includes(composer.role))
      );

      if (!invalidArtists.length && !invalidComposers.length) return false;

      const artistRoles = invalidArtists.map((artist) => 
        this.$t(`artist_roles.${artist.role}`)
      ).join(', ');
      const artistNames = invalidArtists.map((artist) => artist.name).join(', ');
      const composerRoles = invalidComposers.map((composer) =>
        this.$t(`composer_roles.${composer.role}`)
      ).join(', ');
      const composerNames = invalidComposers.map((composer) => 
        `${composer.surname} ${composer.name}`
      ).join(', ');

      let messageParts = [];

      if (invalidArtists.length) {
        const part = `${this.$t('artists_cant_have_roles', { roles: artistRoles })} (${artistNames})`;
        messageParts.push(part);
      }
      if (invalidComposers.length) {
        let part = `${this.$t('composers_cant_have_roles', { roles: composerRoles })} (${composerNames})`;

        if (messageParts.length) {
          part = part.charAt(0).toLowerCase() + part.slice(1);
        }
        messageParts.push(part);
      }

      const message = `${messageParts.join(', ')} ${this.$t('on_instrumental_track')}`

      const modalData = {
        isVisible: true,
        heading: this.$t('please_note'),
        actionCallback: () => {
          invalidArtists.forEach((artist) => this.removeArtist(artist.uuid));
          invalidComposers.forEach((composer) => composer.role = '');
          this.toggleModal('warning');
        },
        actionText: this.$t('change_roles'),
        message,
        cancelCallback: () => {
          this.$nextTick(() => {
            this.trackData.instrumental = false;
          });
          this.toggleModal('warning');
        },
        cancelText: this.$t('cancel'),
      };
      this.updateModal({ id: 'warning', data: modalData});

      return true;
    },

    instrumentalSwitchHandler (value) {
      if (!value) return;
      this.validateArtistAndComposerRoles();
    },

    getTrackArtist (artistUuid) {
      return this.trackData.artists?.find((a) => a.uuid == artistUuid);
    },

    adjustPreviewStartSecond () {
      this.trackDuration = this.$refs[this.audioRefName].duration;
    },

    removeComposerById (id) {
      const composerIndex = this.trackData.composers.findIndex((composer) => composer.id == id);
      const composer = this.trackData.composers[composerIndex];

      this.trackData.composers.splice(composerIndex, 1);

      if (!composer.uuid) return;
      this.removeComposerFromTrack(composer.uuid);
    },

    generateNewID () {
      return new Date().getTime();
    },

    removeArtist (uuid) {
      let artistIndex;
      const artist = this.trackData.artists.find((a, index) => {
        artistIndex = index;
        return a.uuid == uuid;
      });

      this.trackData.artists.splice(artistIndex, 1);

      const removePayload = {
        trackUuid: this.trackData.uuid,
        artistData: {
          artist_uuid: artist.uuid,
        },
      };

      this.removeArtistFromTrack(removePayload);
    },

    addNewComposer () {
      const id = this.generateNewID();

      const newComposer = {
        id,
        uuid: '',
        name: '',
        surname: '',
        patronymic: '',
        role: '',
      };

      this.trackData.composers.push(newComposer);
    },

    addNewArtist (artistUuid, selectingArtistRole) {
      const foundArtist = this.artists.find((artist) => artist.uuid == artistUuid);
      if (foundArtist) {
        const artist = {
          uuid: foundArtist.uuid,
          name: foundArtist.name,
          spotifyID: foundArtist.spotify_id,
          appleID: foundArtist.apple_id,
          role: selectingArtistRole ?? undefined,
        };

        this.trackData.artists.push(artist);
      }
    },

    saveArtistSelection () {
      if (this.selectingArtist && this.selectingArtistRole) {
        this.addNewArtist(this.selectingArtist.uuid, this.selectingArtistRole);
      }

      this.selectingArtist = null;
      this.selectingArtistRole = null;
    },
  },
  computed: {
    ...mapGetters([
      'artists',
      'secondaryStyles',
      'allowedArtistRolesForInsrumental',
      'allowedComposerRolesForInsrumental',
    ]),

    validations () {
      return {
        trackData: {
          title: {
            required,
            minLength: minLength(3),
          },
          previewStartSecond: {
            required,
            minValue: minValue(0)
          },
          lyrics: {
            minLength: minLength(12),
          },
        },
        author: {
          name: {
            required,
            minLength: minLength(2),
          },
          surname: {
            required,
            minLength: minLength(2),
          },
          patronymic: {
            minLength: minLength(2),
          },
          role: { required },
        },
      };
    },

    filteredArtistRoles () {
      if (this.trackData.instrumental) {
        const filteredArray = this.artistRoles.filter(role =>
          this.allowedArtistRolesForInsrumental.includes(role.id)
        ); 
        return filteredArray;
      }

      return this.artistRoles;
    },

    filteredComposerRoles () {
      if (this.trackData.instrumental) {
        const filteredArray = this.composerRoles.filter(role =>
          this.allowedComposerRolesForInsrumental.includes(role.id)
        );
        return filteredArray;
      }

      return this.composerRoles;
    },

    subgenres () {
      const subgenres = this.secondaryStyles(this.trackData.genre);
      if (!subgenres || !subgenres.length) {
        return [{
          value: 'No subgenres',
        }];
      }

      return subgenres.map((style) => (
        {
          id: style.musicStyleId,
          value: style.name,
        }
      ));
    },

    trackFullname () {
      const title = this.trackData?.title;
      const placeholder = this.$t('enter_track_name');

      return title?.length ? title : placeholder;
    },
    audioRefName () {
      return `track${Math.random()}`;
    },

    styles () {
      return {
        '--loadingProgress': `calc(${this.loadingProgress}% - 24px)`,
        '--barColor': this.modelValue.error ? 'red' : (this.loadingProgress <= 100 && '#77E382'),
      };
    },

    sortedAndFilteredArtists () {
      const query = this.artistsSearchQuery.toLowerCase().trim();

      if (!query) return this.sortedArtists;

      return this.sortedArtists.filter((artist) => {
        const selectedArtist = this.trackData.artists.find((a) => a.uuid == artist.uuid);
        if (selectedArtist) return true;

        const artistName = artist.name.toLowerCase().trim();
        return artistName.includes(query);
      });
    },

    sortedArtists () {
      const sortedArtists = [...this.artists].sort((a, b) =>
        a.updated_at?.localeCompare(b.updated_at)
      ).reverse().sort((a, b) => {
        const artist1 = this.trackData.artists?.find((artist) => artist.uuid == a.uuid);
        const artist2 = this.trackData.artists?.find((artist) => artist.uuid == b.uuid);

        if (artist1 && artist2) {
          return 0;
        } else if (artist1) {
          return -1;
        } else {
          return 1;
        }
      });
      return sortedArtists;
    },
  },
  watch: {
    trackData: {
      deep: true,
      handler () {
        this.$emit('update:modelValue', this.trackData);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.v-enter-active,
.v-leave-active {
  // transition: opacity 0.5s ease;
}

.v-enter-to,
.v-leave-from {
  transition: max-height 0.3s ease;
  max-height: 400px;
  padding-top: 12px;
}

.v-enter-from,
.v-leave-to {
  padding: 0;
  transition: max-height 0.3s ease;
  max-height: 0px;
  overflow: hidden;
}

.trackform {
  width: 100%;

  &__preview {
    height: 38px;
    display: flex;
    background: white;
    border-radius: 11px;
    box-shadow: 0px 0px 21px rgba($color: black, $alpha: 0.1);
    align-items: center;
    padding: 0 19px 0 12px;
    position: relative;

    &::after {
      content: '';
      width: var(--loadingProgress);
      height: 4px;
      border-radius: 11px 11px 0px 0px;
      background: var(--barColor);
      position: absolute;
      left: 11px;
      bottom: 0;

      transition: width 0.1s;
    }
  }

  &__form {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__textarea {
    width: 100%;
  }

  &__switch {
    margin-left: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-size: 1.125rem;
    }
  }

  &__artists {
    position: relative;

    display: flex;
    flex-direction: column;

    margin-bottom: 32px;

    &::after {
      position: absolute;
      bottom: -24px;

      width: 100%;
      height: 1px;

      content: '';

      background: $gray;
    }
  }

  &__artist {
    display: flex;
    flex-direction: column;

    gap: 20px;

    margin-top: 16px;

    border-left: 1px solid $gray;
    padding-left: 12px;
    transition: .2s;

    &:hover {
      transition: .2s;
      border-color: $red;
    }
  }

  &__authors {
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  &__label {
    font-size: 1.125rem;
    flex: 1;
    margin: 0;
  }

  &__row {
    display: flex;
    gap: 10px;

    &--with-button {
      align-items: flex-start;
      gap: 10px;

      button {
        padding-top: 36px;
      }
    }
  }

  &__button {
    height: 40px;
    padding: 0;

    cursor: pointer;
    transition: .2s;
    white-space: nowrap;

    color: $red;
    border: none;
    border-radius: 8px;
    outline-color: rgba($color: $red, $alpha: .3);
    outline-offset: 4px;
    background: none;

    &:hover {
      transition: .2s;
      transform: scale(1.04);
    }

    &:active {
      transform: scale(.95);
    }

    &:focus-visible {
      transform: scale(.95);
    }

    &:disabled {
      color: $dark-gray;
      cursor: not-allowed;
    }
  }

  &__save {
    max-width: 280px;
    width: 100%;
    font-weight: 600;
    text-align: center;
    border: none;
    cursor: pointer;
    margin: 0 auto;
  }
}

.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    max-width: calc(90% - 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__error {
    color: $red;
    margin-left: 16px;
  }

  &__loading {
    margin-left: 16px;
    color: $dark-gray;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__action {
    cursor: pointer;

    &--edit {
      svg {
        width: 13px;
        height: 13px;
        fill: #9F9F9F;
      }
    }

    &--remove {
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.artists {
  margin-top: 8px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;

    gap: 8px;
  }

  &__search { }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: .2s;
    white-space: nowrap;

    color: $red;
    border: none;
    background: none;

    font-size: 1rem;
    font-weight: 600;
    line-height: 100%;

    gap: 6px;

    svg {
      width: 12px;
      height: 12px;

      fill: $red;
    }

    &:hover {
      transition: .2s;
      transform: scale(1.04);
    }
  }

  &__list {
    display: grid;

    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 8px;
  }
}

.artist-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 400px;
}

@media (max-width: 768px) {
  .trackform__row {
    flex-direction: column;

    &--with-button {
      flex-direction: row;
    }
  }

  .artists {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
</style>
