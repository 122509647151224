<template>
  <div :class="['textarea', $attrs.class, v$.modelValue.$errors?.length && 'textarea--error']">
    <div class="textarea__header">
      <label :for="$.uid" class="textarea__label" v-if="label">
        {{ label }}
      </label>
      <div class="textarea__lock" title="Subscription required" v-if="locked">
        <svg>
          <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#lock'}" />
        </svg>
      </div>
    </div>
    <textarea
      class="textarea__input"
      v-bind="{...$attrs, 'v-model': null}"
      :id="$.uid"
      :placeholder="placeholder"
      ref="textarea"
      :value="modelValue"
      @input="(e) => inputHandler(e.target.value)"
      @blur="v$.modelValue.$touch"
    />
    <div class="textarea__error" v-if="showErrors && v$.modelValue.$errors?.length">
      {{ v$.modelValue.$errors[0]?.$message }}
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';

export default {
  inheritAttrs: false,
  props: {
    label: String,
    validations: Object,
    modelValue: {
      type: String || Number,
      default: '',
    },
    placeholder: String,
    locked: Boolean,
    showErrors: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      v$: useValidate(),
    };
  },
  validations () {
    return {
      modelValue: this.validations ?? {},
    };
  },
  methods: {
    inputHandler (value) {
      this.$emit('update:modelValue', value);
    },
  },
  watch: {
    modelValue () {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = event.target.scrollHeight + 2 + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.textarea {
  display: flex;
  flex-direction: column;

  width: 100%;

  &--error {
    .textarea__input {
      border-color: $red;
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 5px;
    margin-left: 12px;
  }

  &__label {
    font-size: 1.125rem;
  }

  &__lock {
    svg {
      width: 11px;
      height: 11px;
      fill: $red;
      stroke: $red;
    }
  }

  &__input {
    resize: none;
    box-sizing: border-box;
    min-height: 40px;
    padding: 16px;

    transition: .2s;

    border: 1px solid #D1D1D1;
    border-radius: 11px;

    font-size: 1rem;

    &::placeholder{
      color: #CACCCF;
    }

    &:disabled {
      background: $light-gray;
      cursor: not-allowed;
    }

    &:focus {
      transition: .3s;

      outline: none;
      background: #f9f9f9;
    }
  }

  &__error {
    padding-left: 12px;
    font-size: 14px;
    color: $red;
    left: 12px;
    bottom: -10px;
    padding-top: 4px;
  }
}
</style>
