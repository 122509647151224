<template>
  <section class="welcome">
    <div class="layout__notifications">
      <TransitionGroup
        name="squeeze-slide"
        mode="out-in"
      >
        <Notification v-for="noti in notifications"
          :key="noti.id"
          :notification="noti"
        />
      </TransitionGroup>
    </div>
    <div class="welcome__content">
      <div class="welcome__container">
        <div class="welcome__content-inner-wrapper">
          <div class="welcome__login-wrapper">
            <!--START welcome sign-up top section -->
            <div class="welcome__login-header welcome__section">
              <div class="welcome__login-header-item">
                <div class="welcome__login-header-text">
                  {{ $t('welcome_to') }}
                </div>
                <img
                  class="welcome__login-header-logo"
                  src="@/assets/Logo.svg"
                  alt=""
                >
              </div>
              <!-- <div class="welcome__login-header-item">
                <DropDown
                  class="welcome__login-header-switch"
                  :name="'English'"
                  :values="['English','Russian']"
                />
              </div> -->
            </div>
            <router-view />
          </div>
          <div class="layout__notifications">
          <TransitionGroup
            name="squeeze-slide"
            mode="out-in"
          >
            <Notification v-for="noti in notifications"
              :key="noti.id"
              :notification="noti"
            />
          </TransitionGroup>
        </div>
        </div>
      </div>
    </div>
    <div id="background" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Notification from '../components/Notification.vue';

export default {
  components: {
    Notification,
  },
  computed: {
    ...mapGetters(['notifications']),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/_shared.scss';

  .layout__notifications{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .welcome{
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    max-width: 100vw;
  }

  .welcome__content{
    height: 100vh;
    width: 100%;
    z-index: 1;
    position: absolute;
  }

  .welcome__container{
    display: flex;
    justify-content: center;
    max-width: 1304px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .welcome__section{
    padding: 25px 35px;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 388px;
    background: $white;
    box-shadow: $sections_shadow;
    border-radius: $sections_border_radius;
    height: min-content;

    &:last-child{
        margin: 0;
    }
  }

  .welcome__login-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome__login-header-item{
    display: flex;
    flex-direction: column;
  }

  .welcome__login-header-text{
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .welcome__content-inner-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;
  }

  #background{
    position: absolute;
    top: 0;
    left: 0;
    width: 200vw;
    height: 100vh;
    background: url('~@/assets/WelcomeBackground.webp');
    background-size: 100vw auto ;
    animation: background-move 120s infinite linear;
    background-color: black;
  }

  @keyframes background-move {
    0%{
      transform: translateX(0px);
    }

    0%{
      transform: translateX(-100vw);
    }
  }
</style>
