<template>
  <button class="logout-btn" @click="logoutRequest">
    <svg>
      <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#logout'}" />
    </svg>
    <span>
      {{ $t('logout') }}
    </span>
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logout']),

    logoutRequest: function () {
      this.logout().finally(() => {
        this.$router.push({ name: 'Sign In' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 125px;
  height: 40px;

  cursor: pointer;
  transition: .3s;

  border: none;
  border-radius: 12px;
  background: #F8F8F8;

  gap: 12px;

  svg{
    width: 18px;
    height: 15px;
  }

  span{
    color: #828282;

    font-size: 1.125rem;
  }

  &:hover {
    transition: .3s;
    transform: scale(1.03);

    background: $light-gray;
  }
}
</style>
