import 'normalize.css';

import { createApp } from 'vue';
import i18n from './i18n';

import App from './App.vue';
import directives from './directives';
import router from './router';
import axios from './axios';
import store from './store';
import VueAxios from 'vue-axios';
import VueTheMask from 'vue-the-mask';

const app = createApp(App);

directives.forEach(directive => {
  app.directive(directive.name, directive);
});

app
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueAxios, axios)
  .use(VueTheMask)
  .mount('#app');
