import { Products } from '@/utilities/Products';
import { Subscriptions } from '@/utilities/Subscriptions';

export const PurchaseType = {
  PRODUCT: 'PRODUCT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const Purchases = {
  ...Products,
  ...Subscriptions,
};
