const initialState = () => {
  return {
    modals: {
      warning: {
        isVisible: false,
        heading: '',
        message: '',
        actionCallback: null,
        actionText: null,
        cancelCallback: null,
        cancelText: null,
      },
    }
  };
};

const modals = {
  state: () => initialState(),

  actions: {
    updateModal: ({ commit }, { id, data }) => {
      commit('setModal', { id, data })
    },
    toggleModal: function ({ state, commit }, id, visibility) {
      const isVisible = (visibility !== undefined)
        ? visibility
        : (!state.modals[id].isVisible);

      const data = {
        ...state.modals[id],
        isVisible,
      }
      commit('setModal', { id, data })
    },
  },

  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },
    setModal: function (state, { id, data }) {
      state.modals[id] = data;
    },
  },

  getters: {
    modals: state => state.modals,
    modal: state => id => state.modals[id]
  },
};

export default modals;
