import axios from 'axios';

const stores = [
  {
    key: 'spotify',
    name: 'Spotify',
    color: '#64D561',
  },
  {
    key: 'apple_music',
    name: 'Apple',
    color: '#FB223C',
  },
  {
    key: 'youtube',
    name: 'YouTube',
    color: '#FF0000',
  },
  {
    key: 'vkontakte',
    name: 'VKontakte',
    color: '#0078FF',
  },
  {
    key: 'boom',
    name: 'BOOM',
    color: '#FE2958',
  },
  {
    key: 'deezer',
    name: 'Deezer',
    color: '#EF9F1A',
  },
  {
    key: 'itunes',
    name: 'iTunes',
    color: '#E049D4',
  },
  {
    key: 'saavn',
    name: 'Saavn',
    color: '#30B55C',
  },
];

const initialState = () => {
  return {
    statistics: [],
    commonStatistics: [],
    stores,
  };
};

const statistics = {
  state: () => initialState(),
  actions: {
    fetchReleaseStatistics: function ({ commit }, payload) {
      const releaseUuid = payload.release_uuid;

      return new Promise((resolve, reject) => {
        axios.post('/track-statistics', payload).then(response => {
          const release = {
            uuid: releaseUuid,
            streams: response.data,
          };

          commit('updateReleaseStatistics', release);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchCommonStatistics: function ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/release-statistics', { params }).then(response => {
          const commonStatistics = response.data.data.filter((r) => r.streams?.length);
          commit('setStatistics', commonStatistics);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchCommonStreams: function ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/common-statistics', { params }).then(response => {
          const commonStatistics = response.data;
          commit('setСommonStatistics', commonStatistics);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },
    updateReleaseStatistics: (state, release) => {
      const releaseIndex = state.statistics.findIndex((r) => r.uuid == release.uuid);
      if (releaseIndex >= 0) {
        state.statistics[releaseIndex] = release;
      } else {
        state.statistics.push(release);
      }
    },
    setStatistics: (state, statistics) => {
      state.statistics = statistics;
    },
    setСommonStatistics: (state, statistics) => {
      state.commonStatistics = statistics;
    },
  },
  getters: {
    statistics: (state) => {
      return state.statistics;
    },

    releaseStatistics: (state) => (uuid) => {
      return state.statistics.find((s) => s.uuid == uuid);
    },

    commonStatistics: (state) => {
      return state.commonStatistics;
    },

    streamsByDays: (state, getters) => {
      const streamsByDate = [];

      state.statistics.forEach((release) => {
        release.streams.forEach((day) => {
          const date = day.date;
          const dayStreams = getters.dayStreams(day);
          const dateIndex = streamsByDate.findIndex((streams) => streams.date == date);

          if (dateIndex == -1) {
            const streamsDate = {
              date,
              streams: dayStreams,
            };
            streamsByDate.push(streamsDate);
          } else {
            streamsByDate[dateIndex].streams += dayStreams;
          }
        });
      });

      streamsByDate.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      return streamsByDate;
    },

    releaseStreamsByDays: (state, getters) => (releaseUuid) => {
      const release = getters.releaseStatistics(releaseUuid);

      if (!release?.streams) {
        return [];
      }

      const streamsByDate = [];

      release.streams.forEach((day) => {
        const date = day.date;
        const dayStreams = getters.dayStreams(day);
        const dateIndex = streamsByDate.findIndex((streams) => streams.date == date);

        if (dateIndex == -1) {
          const streamsDate = {
            date,
            streams: dayStreams,
          };
          streamsByDate.push(streamsDate);
        } else {
          streamsByDate[dateIndex].streams += dayStreams;
        }
      });

      streamsByDate.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      return streamsByDate;
    },

    commonTotalStreams: (state, getters) => {
      const streams = state.commonStatistics.reduce((total, day) => total + getters.dayStreams(day), 0);

      return streams;
    },

    totalStreams: (state, getters) => {
      const streams = state.statistics.reduce((total, release) => total + getters.releaseTotalStreams(release.uuid), 0);
      return streams;
    },

    releaseTotalStreams: (state, getters) => (releaseUuid) => {
      const release = getters.releaseStatistics(releaseUuid);

      if (!release?.streams) {
        return 0;
      }

      const streams = release.streams.reduce((total, day) => total + getters.dayStreams(day), 0);
      return streams;
    },

    dayStreams: (state, getters) => (day) => {
      const streams = getters.storeKeys.reduce((total, key) => total + day[key], 0);
      return streams;
    },

    streamsByStores: (state, getters) => {
      const streams = getters.storeKeys.map(key => getters.storeStreams(key));
      return streams;
    },

    releaseStreamsByStores: (state, getters) => (releaseUuid) => {
      const streams = getters.storeKeys.map(key => getters.releaseStoreStreams(releaseUuid, key));
      return streams;
    },

    releaseStoreStreams: (state, getters) => (releaseUuid, key) => {
      const release = getters.releaseStatistics(releaseUuid);

      if (release?.streams) {
        const streams = release.streams.reduce((total, day) => total + day[key], 0);
        return streams;
      }

      return 0;
    },

    storeStreams: (state, getters) => (key) => {
      const streams = state.statistics.reduce((total, release) => {
        const releaseStoreStreams = getters.releaseStoreStreams(release.uuid, key);
        return total + releaseStoreStreams;
      }, 0);
      return streams;
    },

    storeStreamsPercentage: (state, getters) => (key) => {
      const storeStreams = getters.storeStreams(key);
      const totalStreams = getters.totalStreams;

      if (storeStreams == 0 || totalStreams == 0) return 0;

      const percentage = Math.round(storeStreams / totalStreams * 100);
      return percentage;
    },

    releaseStoreStreamsPercentage: (state, getters) => (releaseUuid, key) => {
      const storeStreams = getters.releaseStoreStreams(releaseUuid, key);
      const totalStreams = getters.releaseTotalStreams(releaseUuid);

      if (storeStreams == 0 || totalStreams == 0) return 0;

      const percentage = Math.round(storeStreams / totalStreams * 100);
      return percentage;
    },

    storeKeys: (state) => {
      return state.stores.map(store => store.key);
    },

    storeNames: (state) => {
      return state.stores.map(store => store.name);
    },

    storeColors: (state) => {
      return state.stores.map(store => store.color);
    },
  },
};

export default statistics;
