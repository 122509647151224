export const Subscriptions = {
  CLASSIC: {
    name: 'CLASSIC',
    displayName: 'Classic',
    description: 'Base subscription',
    amount: 10,
  },
  PREMIUM: {
    name: 'PREMIUM',
    displayName: 'Premium',
    description: 'Better subscription',
    amount: 15,
  },
};
