<template>
  <div class="order">
    <section class="order__section" v-if="userPurchase">
      <h1 class="order__heading">
        {{ currentStatus.heading }}
      </h1>
      <div class="order__main">
        <div class="order__product">
          <ProductCard
            :product="userPurchase.purchaseInfo"
            :show-price="false"
          />
        </div>
        <div class="order__status status">
          <div class="status__icon">
            <svg>
              <use v-bind="{'xlink:href' : `${require('@/assets/icons/all_icons.svg')}#${currentStatus.icon}`}" />
            </svg>
          </div>
          <p class="status__name">
            {{ currentStatus.label }}
          </p>
        </div>
      </div>
      <div class="order__details details">
        <div class="details__heading">
          Purchase details
        </div>
        <div class="details__main">
          <div class="details__list">
            <div class="details__item">
              Date and time: {{ userPurchase.formattedDate }}
            </div>
            <div class="details__item">
              Payment type: {{ userPurchase.paymentTypeName }}
            </div>
            <div class="details__item">
              Amount: ${{ userPurchase.purchase?.amount }}
            </div>
          </div>
          <!-- <SquareButton class="details__action" @click="navigateToPurchases">
            All payments
          </SquareButton> -->
        </div>
      </div>
    </section>
    <LoadingLogo v-else />
  </div>
</template>

<script>
import io from 'socket.io-client';
import { mapActions, mapGetters } from 'vuex';
import ProductCard from '../components/ProductCard.vue';
// import SquareButton from '../components/SquareButton.vue';
import { PurchaseType } from '../utilities/purchases';
import { PaymentMethods } from '../utilities/PaymentMethods';
import { Subscriptions } from '../utilities/Subscriptions';
import { Products } from '../utilities/Products';
import LoadingLogo from './LoadingLogo.vue';

export default {
  props: {
    uuid: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  components: {
    ProductCard,
    LoadingLogo,
  },
  data () {
    return {
      Subscriptions,
      Products,
      PaymentMethods,

      socket: null,
      socketPurchase: null,
      order: null,
      timerStart: null,
      reconnectTimeout: 15 * 60 * 1000, // 15 minutes (ms)

      statuses: {
        PAID: {
          heading: 'Congratulations on your purchase!',
          label: 'Order successfully paid.',
          icon: 'success_filled',
        },
        ISSUED: {
          heading: 'Congratulations on your purchase!',
          label: 'Order successfully issued.',
          icon: 'success_filled',
        },
        REJECTED: {
          heading: 'Unfortunately, the order is rejected.',
          label: 'Order rejected.',
          icon: 'failure_filled',
        },
        REFUNDED: {
          heading: 'The order was refunded.',
          label: 'Order was refounded',
          icon: 'failure_filled',
        },
        USED: {
          heading: 'The product has already been used',
          label: 'Product was used',
          icon: 'pending_filled',
        },
        PENDING: {
          heading: 'Wait a minute! Order is being processed.',
          label: 'Order still in processing',
          icon: 'pending_filled',
        },
      },
    };
  },
  mounted () {
    this.setupSocket();
  },
  beforeUnmount () {
    if (this.socket) {
      this.socket.off('data');
      this.socket.disconnect();
    }
  },
  methods: {
    ...mapActions(['']),

    navigateToPurchases () {
      this.$router.push({ name: 'Purchases' });
    },

    getFormattedDate (dateString) {
      const date = new Date(dateString);
      const day = date.toLocaleDateString('ru-RU');
      const time = date.toLocaleTimeString('ru-RU');
      return `${day} ${time}`;
    },

    getPaymentMethodName (paymentTypeKey) {
      const paymentType = Object.values(PaymentMethods).find((p) => p.paymentType == paymentTypeKey);
      return paymentType ? paymentType.name : paymentTypeKey;
    },

    startTimer () {
      this.timerStart = Date.now();
    },

    timeElapsed () {
      return Date.now() - this.timerStart;
    },

    handleDisconnect (reason) {
      console.log('Disconnected from server', reason);

      if (this.timeElapsed() < this.reconnectTimeout) {
        this.socket.connect();
      } else {
        console.log(`${this.reconnectTimeout / 1000} seconds already passed.`);
      }
    },

    setupSocket () {
      this.socket = io('https://gateway.thetester.site/', {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 5000,
      });

      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.subscribeToOrder();
      });

      this.socket.on('disconnect', this.handleDisconnect);

      this.socket.on('reconnect_attempt', () => {
        console.log('Attempting to reconnect');
      });

      this.socket.on('reconnect', (attemptNumber) => {
        console.log(`Reconnected after ${attemptNumber} attempts`);
      });
    },

    subscribeToOrder () {
      const message = `${this.type}:${this.uuid}`;

      this.socket.emit('check-payment', message);

      this.socket.on('data', (data) => {
        console.log('Received data:', data);
        this.socketPurchase = data;
      });
    },
  },
  computed: {
    ...mapGetters(['purchase']),

    userPurchase () {
      if (!this.socketPurchase) return null;

      let purchase, purchaseInfo;
      switch (this.type) {
      case PurchaseType.SUBSCRIPTION:
        purchase = this.socketPurchase.subscription;
        purchaseInfo = this.Subscriptions[purchase?.name];
        break;
      case PurchaseType.PRODUCT:
        purchase = this.socketPurchase.product;
        purchaseInfo = this.Products[purchase?.name];
        break;
      }

      const userPurchase = {
        ...this.socketPurchase,
        formattedDate: this.getFormattedDate(this.socketPurchase.createdAt),
        paymentTypeName: this.getPaymentMethodName(this.socketPurchase.paymentType),
        purchase,
        purchaseInfo,
      };

      return userPurchase;
    },

    currentStatus () {
      const { status } = this.userPurchase;
      const currentStatus = this.statuses[status];
      const defaultStatus = this.statuses.PENDING;
      return currentStatus ?? defaultStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.order {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__heading {
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 100%;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 24px;
    height: 24px;

    svg {
      width: inherit;
      height: inherit;
    }
  }

  &__name {
    // font-weight: 500;
  }
}
.details {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__heading {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 100%;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__item {
    color: #999;
    line-height: 100%;
  }

  &__action {
    padding: 16px 20px;
    font-size: 1rem;
    font-weight: 500;
  }
}
</style>
