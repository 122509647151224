<template>
  <div class="payment">
    <div class="payment__proccess" v-if="!isPaymentStarted">
      <div class="payment__main">
        <div class="payment__form">
          <div class="payment__block">
            <p class="payment__title">
              Choose method:
            </p>
            <div class="payment__methods">
              <div
                v-for="method in activePaymentMethods"
                :key="method.key"
                :class="['payment__method', 'method', isSelectedMethod(method) ? 'method--selected' : '']"
                @click="selectPaymentMethod(method)"
              >
                <div class="method__icon">
                  <div class="method__icon">
                    <img :src="require(`@/assets/payments/${method.iconFile}`)" alt="">
                  </div>
                </div>
                <div class="method__name">
                  {{ method.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="payment__block" v-if="isSelectedMethod(PaymentMethods.card)">
            <p class="payment__title">
              Card details
            </p>
            <div class="payment__card">
              <div class="payment__card-row">
                <InputWithLabel
                  class="payment__input"
                  v-model="payment.number"
                  v-mask="'#### #### #### #### ###'"
                  placeholder="0000 0000 0000 0000 000"
                  :validations="validations.payment.number"
                  :show-errors="false"
                />
                <InputWithLabel
                  class="payment__input payment__input--small"
                  v-model="payment.cvv"
                  v-mask="'####'"
                  placeholder="CVV"
                  :validations="validations.payment.cvv"
                  :show-errors="false"
                />
              </div>
              <div class="payment__card-row">
                <InputWithLabel
                  class="payment__input"
                  v-model="payment.cardholder"
                  placeholder="Cardholder name"
                  :validations="validations.payment.cardholder"
                  :show-errors="false"
                />
                <InputWithLabel
                  class="payment__input payment__input--small"
                  v-model="payment.expiry"
                  v-mask="'## / ##'"
                  placeholder="MM / YY"
                  :validations="validations.payment.expiry"
                  :show-errors="false"
                />
              </div>
            </div>
          </div>
          <div class="payment__block" v-if="isSelectedMethod(PaymentMethods.card)">
            <p class="payment__title">
              Email address
            </p>
            <InputWithLabel
              class="payment__input"
              type="email"
              v-model="payment.email"
              placeholder="Email address"
              :validations="validations.payment.email"
            />
          </div>
          <RedButton
            class="payment__submit"
            @click="paymentRequest"
            :disabled="v$.$silentErrors.length > 0"
          >
            Pay ${{ total }}
          </RedButton>
        </div>
        <div class="payment__order">
          <p class="payment__title">
            Your Order
          </p>
          <div class="payment__products">
            <ProductCard
              v-for="product in purchasesInfo"
              :key="product.name"
              :product="product"
              :type="product.type"
            />
          </div>
          <div class="payment__total total">
            <p class="total__title">
              Total
            </p>
            <p class="total__amount">
              ${{ total }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="payment__succeed" v-else>
      <Order :uuid="order.uuid" :type="order.type" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RedButton from './RedButton.vue';
import useValidate from '@vuelidate/core';
import { required, minLength, maxLength, email } from '@vuelidate/validators';
import InputWithLabel from './InputWithLabel.vue';
import ProductCard from './ProductCard.vue';
import { Products } from '@/utilities/Products';
import { Subscriptions } from '@/utilities/Subscriptions';
import { PurchaseType } from '@/utilities/purchases';
import { PaymentMethods } from '@/utilities/PaymentMethods';
import openUrlInNewTab from '@/utilities/openUrlInNewTab';
import Order from './Order.vue';

export default {
  components: {
    RedButton,
    InputWithLabel,
    ProductCard,
    Order,
  },
  data () {
    return {
      Products,
      Subscriptions,
      PaymentMethods,

      v$: useValidate(),

      payment: {
        number: '',
        cvv: '',
        cardholder: '',
        expiry: '',
        email: '',
      },
      selectedPaymentMethodKey: 'cryptocloud',
      isPaymentStarted: false,
    };
  },
  mounted () {
    this.fetchProducts();
    this.fetchSubscriptions();
  },
  methods: {
    ...mapActions([
      'checkoutUserProduct',
      'checkoutUserSubscription',
      'setIsPaymentModal',
      'fetchProducts',
      'fetchSubscriptions',
    ]),
    openUrlInNewTab,

    selectPaymentMethod (methodOrKey) {
      const key = methodOrKey.key ?? methodOrKey;
      this.selectedPaymentMethodKey = key;
    },

    isSelectedMethod (methodOrKey) {
      const key = methodOrKey.key ?? methodOrKey;
      return key == this.selectedPaymentMethodKey;
    },

    checkoutPurchaseRequest () {
      const { paymentType } = this.PaymentMethods[this.selectedPaymentMethodKey];
      const payload = { paymentType };
      const purchase = this.cartPurchases[0];
      console.log('🚀 - checkoutPurchaseRequest - purchase:', purchase);

      switch (purchase.type) {
      case PurchaseType.SUBSCRIPTION:
        payload.userSubscriptionUuid = purchase.uuid;
        return this.checkoutUserSubscription(payload);
      case PurchaseType.PRODUCT:
        payload.userProductUuid = purchase.uuid;
        return this.checkoutUserProduct(payload);
      }
    },

    paymentRequest () {
      this.checkoutPurchaseRequest().then((response) => {
        const { payUrl } = response.data;
        if (payUrl && payUrl.length) {
          this.openUrlInNewTab(payUrl);
          this.isPaymentStarted = true;
        }
      });
    },
  },
  computed: {
    ...mapGetters(['cartPurchases']),

    order () {
      const { type, uuid } = this.cartPurchases[0];
      return { type, uuid };
    },

    activePaymentMethods () {
      return Object.values(this.PaymentMethods).filter((m) => m.isActive);
    },

    purchasesInfo () {
      const purchases = this.cartPurchases.map((userPurchase) => {
        let info;
        switch (userPurchase.type) {
        case PurchaseType.SUBSCRIPTION:
          info = this.Subscriptions[userPurchase.subscription.name];
          info.amount = parseFloat(userPurchase.subscription.amount);
          break;
        case PurchaseType.PRODUCT:
          info = this.Products[userPurchase.product.name];
          info.amount = parseFloat(userPurchase.product.amount);
          break;
        }

        return info;
      });

      return purchases;
    },

    total () {
      const total = this.purchasesInfo.reduce((total, product) => (total + product.amount), 0);
      return total.toFixed(2);
    },

    validations () {
      return {
        payment: {
          number: {
            required,
            minLength: minLength(18), // #### #### #### ###
            maxLength: maxLength(23), // #### #### #### #### ###
          },
          cvv: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(4),
          },
          cardholder: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(40),
          },
          expiry: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(7),
          },
          email: {
            required,
            email,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.payment {
  padding: 16px;

  &__proccess {}

  &__succeed {
    width: 550px;
  }

  &__main {
    display: flex;
  }

  &__form {
    width: 384px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 24px;
    border-right: 1px solid #E6E6E6;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 6px;
    padding-left: 12px;
  }

  &__methods {
    display: flex;
    gap: 13px;
    margin-bottom: 8px;
  }

  &__method {

  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__card-row {
    display: flex;
    gap: 8px;
  }

  &__input {
    width: 100%;

    &--small {
      max-width: 112px;
    }
  }

  &__submit {
    // margin-top: auto;
    height: 44px;
    border-radius: 16px;
    font-size: 1rem;
    font-weight: 600;
  }

  &__order {
    padding-left: 24px;
    width: 330px;
    display: flex;
    flex-direction: column;
  }

  &__products {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 280px;
    height: 100%;
    padding-bottom: 16px;
    overflow-y: auto;
  }

  &__total {
    min-height: 44px;
  }
}
.method {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 59px;
  min-width: 80px;
  padding: 12px 12px 8px 12px;
  border: 1px solid #D1D1D1;
  border-radius: 12px;
  background: white;
  transition: .2s;
  cursor: pointer;

  &__icon {
    max-height: 13px;

    svg {
      max-height: 13px;
      max-width: 20px;
    }

    img {
      max-height: 13px;
      border-radius: 2px;
    }
  }

  &__name {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
  }

  &--selected {
    border-color: $red;
    color: $red;
  }

  &:hover {
    transition: .2s;
    background: #fafafa;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E6E6E6;
  margin-top: auto;

  &__title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 16px;
  }

  &__amount {
    font-size: 1rem;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
  }
}

@media (max-width: 768px) {
  .payment {
    padding: 0;
    margin-top: 16px;

    &__main {
      flex-direction: column;
    }

    &__form {
      width: 100%;
      padding: 0;
      border-right: none;
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 16px;
    }

    &__order {
      width: 100%;
      padding: 0;
      padding-top: 16px;
    }
  }
}

</style>
